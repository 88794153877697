import axios from "axios";
import { API } from './api'
import yaml from 'js-yaml';


/**
 * Creates the non functional requirement with the given payload and returns the created requirement
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @param {*} payload 
 * @returns 
 */
const createQuestion = async (projectId, applicationId, payload) => API().post(`/project/${projectId}/application/${applicationId}/question/`, payload);

/**
 * Updates the non functional requirement with the given id and returns the updated requirement
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @param {*} featureId 
 * @param {*} payload 
 * @returns 
 */
const updateQuestion = async (projectId, applicationId, featureId ,payload) => API().put(`/project/${projectId}/application/${applicationId}/question/${featureId}/`, payload);

/**
 * Deletes the non functional requirement  with the given id and returns the updated requirement
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @param {*} questionId 
 * @returns 
 */
const deleteQuestion = async (projectId, applicationId, questionId ) => API().delete(`/project/${projectId}/application/${applicationId}/question/${questionId}/`);

/**
 * Returns the list of non functional requirements defined for the given application
 * 
 * @param {*} projectId 
 * @param {*} id 
 * @returns 
 */
const getQuestions = async (projectId, applicationId) => API().get(`/project/${projectId}/application/${applicationId}/question/`);

/**
 * Returns the non functional requirement with the given id
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @param {*} id 
 * @returns 
 */
const getQuestion = async (projectId, applicationId, id) => API().get(`/project/${projectId}/application/${applicationId}/question/${id}/`);

/**
 * Deletes all the non functional requirements for the given application
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @returns 
 */
const deleteAllQuestions = async (projectId, applicationId) => API().delete(`/project/${projectId}/application/${applicationId}/question/delete_all/`);

/**
 * Genereates the non functional requirements for the given application
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @returns 
 */
const generateQuestions = async (projectId, applicationId, payload) => API().post(`/project/${projectId}/application/${applicationId}/question/generate/`, payload);

export {
    createQuestion,
    updateQuestion ,
    deleteQuestion ,
    getQuestions ,
    getQuestion ,
    generateQuestions ,
    deleteAllQuestions ,
};