import React, { useState, useEffect, useRef } from 'react';
import "./SendForReview.css";
import { getApplication } from '../../services/applicationService';
import useCurrentProject from '../App/useCurrentProject';
import useCurrentApplication from '../App/useCurrentApplication';
import toast, { Toaster } from "react-hot-toast";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Form } from 'react-bootstrap';
import * as formik from 'formik';
import * as yup from 'yup';

// initialize the react module receing an id parameter

function SendForReview({ modalShow, handleModalClose, handleModalSubmit }) {
  const {currentProject} = useCurrentProject();
  const {currentApplication} = useCurrentApplication();
  const [application, setApplication] = useState({});
  const [email, setEmail] = useState('');

  const [validated, setValidated] = useState(false);
  const formRef = useRef(null);

  const handleSubmit = (event) => {
    console.log("handleSubmit")
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    setValidated(true);

    handleModalSubmit();
  };

  const handleChangeEmail = (e) => {
    setEmail(e.target.value);
  }

  const init = async () => {
    console.log("modalShow", modalShow);

    if(!currentApplication) {
      return;
    }

    console.log("before getApplication", currentApplication);
    const app = await getApplication(currentProject, currentApplication);
    setApplication(app.data);
  };

  const sendForReview = async (event) => {
    if(validated) {
      return;
    }

    handleModalClose();
    toast.success("The section has been sent for review!");
  }


  useEffect(() => {
    init();

  }, []);

  const { Formik } = formik;

  const schema = yup.object({
    email: yup.string().email("Please enter a valid email address").required("Email field is required"),
  });

  return (
    <>
      <Modal show={modalShow} onHide={handleModalClose} backdrop="static" >
          <Modal.Header closeButton>
            <Modal.Title>Send for Review</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleSubmit}>
              <Form.Group className="mb-3" controlId="formImproveField">

              <Form.Label>Send to these email</Form.Label>
            <Form.Control type="email" placeholder="email" controlId="email" name="email" value={email} onChange={handleChangeEmail} />
     
             </Form.Group>

              <Button className="saveBtn" variant="primary" onClick={handleSubmit}>
              Send!
            </Button>
            </Form>
          </Modal.Body>
          <Modal.Footer>
          </Modal.Footer>
        </Modal>
    </>
);
}

export default SendForReview;
