import axios from "axios";
import { API } from './api'
import yaml from 'js-yaml';


/**
 * Sends the chat message with the given payload and returns the chatId
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @param {*} payload 
 * @returns {Promise<AxiosResponse<any>>}
 */
const sendChat = async (projectId, applicationId, payload) => API().post(`/project/${projectId}/application/${applicationId}/requirement/chat/`, payload);

/**
 * Returns the chat messages for the given application
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @returns 
 */
const getChats = async (projectId, applicationId) => API().get(`/project/${projectId}/application/${applicationId}/requirement/chat/list/`);

/**
 * Returns the history of a given chat id
 * 
 * @param {*} projectId 
 * @param {*} chatId 
 * @returns 
 */
const getChatbotHistory = async (projectId,chatId) => API().get(`project/${projectId}/chatbot_history/${chatId}`);

/**
 * Sends
 * 
 * @param {*} projectId
 * @param {*} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
const sendChatbotQuestion = async (projectId,payload) => API().post(`project/${projectId}/chatbot_question`, payload)


export {
    sendChat,
    getChats,
    getChatbotHistory,
    sendChatbotQuestion
};