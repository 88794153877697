import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import "./ModalConfirm.css";
import { getApplication } from '../../services/applicationService';
import useCurrentProject from '../App/useCurrentProject';
import useCurrentApplication from '../App/useCurrentApplication';
import toast, { Toaster } from "react-hot-toast";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Form } from 'react-bootstrap';
import * as formik from 'formik';
import * as yup from 'yup';


const BaseModal = forwardRef(({ title, content, buttons}, ref) => {
    const [modalShow, setModalShow] = useState(false);
    const handleModalClose = () => setModalShow(false);
    const handleModalShow = () => setModalShow(true);

    useImperativeHandle(ref, () => ({
        close() {
            handleModalClose();
        },
        show() {
            handleModalShow();
        },
      }));

    return (
        <>
        <Modal show={modalShow} onHide={() => handleModalClose()} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {content}
        </Modal.Body>
        <Modal.Footer>
          {buttons}
          
        </Modal.Footer>
      </Modal>
        </>
    )
});

export default BaseModal;
