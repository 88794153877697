import React, { useState } from 'react';
import './SelectedBar.css';

const SelectedBar = ({ title, options, selected, text }) => {
    const [showText, setShowText] = useState(false)
    return (
        <div className="selected-bar">
            <div className="title">{title}</div>
            <div className="options">
                {options.map((option, index) => (
                    <div
                        key={index}
                        className={`option ${selected === option ? 'selected' : ''}`}
                    >
                        {option}
                    </div>
                ))}
                <div
                    className={`option hide`}
                    onClick={()=>setShowText(!showText)}
                >
                    {showText ? "Hide" : "Show"}
                </div>
            </div>
            {text && showText ?
                <div>
                    {text}
                </div>
                : ""
            }
        </div>
    );
};

export default SelectedBar;
