import axios from "axios";
import { API } from './api'
import yaml from 'js-yaml';


/**
 * Creates the non functional requirement with the given payload and returns the created requirement
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @param {*} payload 
 * @returns 
 */
const createNfRequirement = async (projectId, applicationId, payload) => API().post(`/project/${projectId}/application/${applicationId}/nfrequirement/`, payload);

/**
 * Updates the non functional requirement with the given id and returns the updated requirement
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @param {*} featureId 
 * @param {*} payload 
 * @returns 
 */
const updateNfRequirement = async (projectId, applicationId, featureId ,payload) => API().put(`/project/${projectId}/application/${applicationId}/nfrequirement/${featureId}/`, payload);

/**
 * Deletes the non functional requirement  with the given id and returns the updated requirement
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @param {*} nfRequirementId 
 * @returns 
 */
const deleteNfRequirement = async (projectId, applicationId, nfRequirementId ) => API().delete(`/project/${projectId}/application/${applicationId}/nfrequirement/${nfRequirementId}/`);

/**
 * Returns the list of non functional requirements defined for the given application
 * 
 * @param {*} projectId 
 * @param {*} id 
 * @returns 
 */
const getNfRequirements = async (projectId, applicationId) => API().get(`/project/${projectId}/application/${applicationId}/nfrequirement/`);

/**
 * Returns the non functional requirement with the given id
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @param {*} id 
 * @returns 
 */
const getNfRequirement = async (projectId, applicationId, id) => API().get(`/project/${projectId}/application/${applicationId}/nfrequirement/${id}/`);

/**
 * Deletes all the non functional requirements for the given application
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @returns 
 */
const deleteAllNfRequirements = async (projectId, applicationId) => API().delete(`/project/${projectId}/application/${applicationId}/nfrequirement/delete_all/`);

/**
 * Genereates the non functional requirements for the given application
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @returns 
 */
const generateNfRequirements = async (projectId, applicationId, payload) => API().post(`/project/${projectId}/application/${applicationId}/nfrequirement/generate/`, payload);

/**
 * Genereates the non functional requirements for the given application
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @returns 
 */
const regenerateNfRequirement = async (projectId, applicationId, id) => API().post(`/project/${projectId}/application/${applicationId}/nfrequirement/${id}/regenerate/`);

export {
    createNfRequirement,
    updateNfRequirement,
    deleteNfRequirement,
    getNfRequirements,
    getNfRequirement,
    generateNfRequirements,
    deleteAllNfRequirements,
    regenerateNfRequirement,
};