// AssessmentList.js

import React from 'react';
import AssessmentListElement from './AssessmentListElement';
import './AssessmentList.css'; // Import the CSS file for styling

const AssessmentList = ({
    data,
    setData,
    deletable=false,
    generable=false,
    handleElementDelete=()=>{},
    placeholder,
    isUpload
}) => {
    const handleElementChange = (categoryIndex, elementIndex, newValue) => {
        const updatedData = [...data];
        updatedData[categoryIndex].elements[elementIndex].value = newValue;
        setData(updatedData);
    };

    // const handleElementDelete = (categoryIndex, elementIndex) =>{
    //     const updatedData = [...data];
    //     updatedData[categoryIndex].elements.splice(elementIndex,1)
    //     console.log(updatedData,categoryIndex, elementIndex)
    //     setData(updatedData);
    // }
    
    const handleElementDeleteF = (id)=>{
        handleElementDelete(id)
    }

    return (
        <div className="assessment-list-container">
            {data.map((category, categoryIndex) => (

                <div key={categoryIndex} className="category-container">
                    <h3 className="category-label">{isUpload ? 'Upload file' : category.category}</h3>
                    <div className="elements-container">
                        {category.elements.map((element, elementIndex) => (
                            <AssessmentListElement
                                key={elementIndex}
                                title={element.title}
                                value={element.value}
                                setValue={(newValue) =>
                                    handleElementChange(categoryIndex, elementIndex, newValue)
                                }
                                deletable={deletable}
                                deleteFunction={()=>handleElementDeleteF(element.id)}
                                generable={generable}
                                placeholder={placeholder}
                                isUpload={isUpload}
                            />
                        ))}
                    </div>
                </div>
            ))}
        </div>
    );
};

export default AssessmentList;
