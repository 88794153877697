import React, { FC, useEffect, useState, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
import { Table } from 'react-bootstrap';
import { getApplication, updateApplication } from '../../../services/applicationService';
import { regenerateFeatures, deleteAllFeatures, generateNewFeatures, getFeature, regenerateFeature } from '../../../services/featuresService';
import "./ApplicationFeatures.css";
import useCurrentProject from '../../../components/App/useCurrentProject';
import useCurrentApplication from '../../../components/App/useCurrentApplication';
import { getRequirements } from '../../../services/requirementsService';
import ApplicationBuildMenu from '../../../components/ApplicationBuildMenu/ApplicationBuildMenu';
import { getFeatures, deleteFeature } from '../../../services/featuresService';
import * as XLSX from 'xlsx';
import toast, { Toaster } from "react-hot-toast";
import Modal from 'react-bootstrap/Modal';
import ModalConfirm from '../../../components/Utils/ModalConfirm';
import ChatCanvas from '../../../components/Chat/ChatCanvas';
import BaseModal from '../../../components/Utils/BaseModal';
import { launchAndPollForTermination } from '../../../util/util';

const ApplicationFeatures = () => {
  const { currentProject } = useCurrentProject();
  const { currentApplication } = useCurrentApplication();

  const [application, setApplication] = useState({});
  const [functionalRequirements, setFunctionalRequirements] = useState([]);
  const [cards, setCards] = useState({ requirementsList: [] });

  /* Modal confirm variables */
  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [modalOnConfirm, setModalOnConfirm] = useState(() => { });

  const chatCanvasRef = useRef(null);
  const modalConfirmRef = useRef(null);
  const editFeatureRef = useRef(null);
  const regenerateFeatureModalRef = useRef(null);

  const [comment, setComment] = useState('');

  const init = async () => {
    try {
      console.log("currentApplication", currentApplication);

      console.log("before getApplication", currentApplication);
      const application = await getApplication(currentProject, currentApplication);
      console.log(application.data);
      console.log("after getApplication", currentApplication);

      console.log(application.data);
      setApplication(application.data);

      loadRequirements();

    } catch (error) {
      //if (error.response.status === 404) navigate(`/${getSiteLanguage()}/not-found`)
      //if (error.response.status === 500) navigate(`/${getSiteLanguage()}/error`)
    }
  }

  const loadRequirements = async () => {
    try {
      console.log("currentApplication", currentApplication);
      const featureList = await getFeatures(currentProject, currentApplication);
      console.log('requirementsList', featureList.data);

      for (const feature of featureList.data) {
        feature.feature_id = 'FR' + (feature.id).toString().padStart(2, '0');
        console.log(feature);
      }

      console.log('FEATURES', featureList.data);
      setFunctionalRequirements(featureList.data);
    } catch (error) {
      toast.error("An error occurred while loading the requirements.");
      console.log(error);
    }
  };

  const launchLoadRequirementsAndWait = async () => {

    const toastId = toast.loading('Loading...');

    try {
      let featureList = null;
      const oldFeatureListSize = functionalRequirements.length;

      launchAndPollForTermination(async () => {
        featureList = await getFeatures(currentProject, currentApplication);
        if (featureList && featureList.data && featureList.data.length > oldFeatureListSize) {
          setFunctionalRequirements(featureList.data);

          toast.dismiss(toastId);

          toast.success("Requirements are ready.");
        }
      }, () => featureList && featureList.data && featureList.data.length > oldFeatureListSize);
    } catch (error) {
      toast.dismiss(toastId);

      toast.error("An error occurred while loading the requirements.");

      console.log(error);
    }
  };

  const launchLoadRequirementsAndWaitForFeatureToChange = async (feature) => {

    const toastId = toast.loading('Loading...');

    try {
      let newFeature = null;

      launchAndPollForTermination(async () => {
        newFeature = await getFeature(currentProject, currentApplication, feature.id);

        newFeature = newFeature.data;
        if (newFeature && newFeature.description !== feature.description) {
          // reload the features
          console.log("feature description changed, reloading the features", feature, newFeature);
          
          const featureList = await getFeatures(currentProject, currentApplication);

          setFunctionalRequirements(featureList.data);

          toast.dismiss(toastId);

          toast.success("Feature has been regenerated.");
        }
      }, () => newFeature && feature.description !== newFeature.description);
    } catch (error) {
      toast.dismiss(toastId);

      toast.error("An error occurred while loading the requirements.");

      console.log(error);
    }
  };


  useEffect(() => {
    init();
  }, []);

  /*
  const downloadExcel = () => {
    const wsFunctional = XLSX.utils.json_to_sheet(functionalRequirements);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, wsFunctional, "Functional");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "DataSheet.xlsx");
  };

  */

  // Funzione per configurare e aprire la modal
  const openModalConfirm = (title, message, onConfirm) => {
    setModalTitle(title);
    setModalMessage(message);
    setModalOnConfirm(() => onConfirm);

    modalConfirmRef.current.show();
  }

  const handleChangeComment = (e) => {
    setComment(e.target.value);
  }

  const confirmGenerateFeatures = () => {
    openModalConfirm('Generate Features', 'Are you sure you want to generate the features?', handleGenerateFeatures);
  };

  const confirmDeleteFeature = (featureId) => {
    openModalConfirm('Delete Feature', 'Are you sure you want to delete the feature?', () => handleDeleteFeature(featureId));
  };

  const handleGenerateFeatures = async () => {
    try {
      console.log("generateRequirements", application);
      let resp = null;

      resp = await generateNewFeatures(currentProject, currentApplication);

      // launch launchLoadRequirementsAndWait without waiting
      launchLoadRequirementsAndWait();

      console.log(resp);
      toast.success("A request to generate the requirements has been sent to the system.");
      modalConfirmRef.current.close();

    } catch (error) {
      console.log(error);
    }
  };

  const confirmDeleteAllFeatures = () => {
    openModalConfirm('Delete All Features', 'Are you sure you want to delete all the features?', handleDeleteAllFeatures);
  };

  const handleDeleteAllFeatures = async () => {
    try {
      console.log("deleteAllFeatures", application);
      let resp = null;

      resp = await deleteAllFeatures(currentProject, currentApplication);

      console.log(resp);
      toast.success("All the functional requirements have been deleted.");
      modalConfirmRef.current.close();
      init();

    } catch (error) {
      toast.error("An error occurred while deleting the requirements.");
      console.log(error);
    }
  };

  
  const [currentRequirement, setCurrentRequirement] = useState({});

  const confirmRegenerate = (requirement) => {
    setCurrentRequirement( requirement );
    regenerateFeatureModalRef.current.show();
    //openModalConfirm('Delete Feature', 'Are you sure you want to regenerate the feature?', () => handleRegenerate(feature));
  };

  const handleRegenerateFeature = async () => {
    try {
      console.log("regenerateFeature", currentRequirement);

      let resp = null;
      resp = await regenerateFeature(currentProject, currentApplication, currentRequirement.id, {comment: comment});
      
      regenerateFeatureModalRef.current.close();

      console.log(resp);
      toast.success("A request to regenerate the requirement has been sent to the system.");

      launchLoadRequirementsAndWaitForFeatureToChange(currentRequirement);
    } catch (error) {
      console.log(error);
      toast.error("An error occurred while regenerating the feature.");
    }
  };


  const getFeatureClassName = (feature) => {
    if (feature.showDetail) {
      return "fas fa-minus";
    } else {
      return "fas fa-plus";
    }
  };

  const showRequirements = async (feature) => {
    console.log("showRequirements", feature);
    for (const f of functionalRequirements) {
      if (f.id === feature.id) {
        f.showDetail = !f.showDetail;

        const requirements = await getRequirements(currentProject, currentApplication, feature.id);
        f.requirements = requirements.data;

      } else {
        f.showDetail = false;
      }
    }
    setFunctionalRequirements([...functionalRequirements]);
  };

  const handleDeleteFeature = async (id) => {

    try {
      let resp = null;

      resp = await deleteFeature(currentProject, currentApplication, id);
      //IT DELETED ALL THE REQUIREMENTS

      modalConfirmRef.current.close();

      console.log(resp);
      toast.success("The requirement has been deleted.");

      await init();

    } catch (error) {
      console.log(error);
    }
  }

  return (
    <>
      <ApplicationBuildMenu stepCode="features" />

      <ChatCanvas ref={chatCanvasRef} />

      <ModalConfirm title={modalTitle}
        modalMessage={modalMessage}
        onConfirm={modalOnConfirm}
        ref={modalConfirmRef}
      />

      <BaseModal title="Edit Feature"
        content={<div>Content</div>}
        buttons={
          <>
            <Button className="cancelButton" variant="primary" onClick={() => alert('hello')}>
              Cancel
            </Button>
            <Button className="saveBtn" variant="primary" onClick={() => alert('hello')}>
              Generate!
            </Button>
          </>
        }
        ref={editFeatureRef}
      />


      <BaseModal title="Regenerate Feature"
        content={
          <Form>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Comment</Form.Label>
              <Form.Control type="text" as="textarea" placeholder="Enter your comment" rows={4} value={comment} onChange={(e) => handleChangeComment(e)} />
            </Form.Group>
          </Form>
        }
        buttons={
          <>
            <Button className="cancelButton" variant="primary" onClick={() => regenerateFeatureModalRef.current.close()}>
              Cancel
            </Button>
            <Button className="saveBtn" variant="primary" onClick={() => handleRegenerateFeature()}>
              Generate!
            </Button>
          </>
        }
        ref={regenerateFeatureModalRef}
      />

      <div className='container'>
        <div className="d-sm-flex align-items-center justify-content-between mb-4 ">
          <h1 className="h3 mb-8 text-gray-800"> Features</h1>
          <div>
            {/*
            <Button variant="primary" onClick={init} className="addReq-btn">
              <i className="fas fa-sync fa-sm text-white-50"></i> Refresh
            </Button> */}
            &nbsp;&nbsp;
            <Button variant="primary" onClick={confirmDeleteAllFeatures} className="addReq-btn">
              <i className="fas fa-plus fa-sm text-white-50"></i>Delete All
            </Button> &nbsp;
            <Button variant="primary" onClick={confirmGenerateFeatures} className="addReq-btn">
              <i className="fas fa-plus fa-sm text-white-50"></i> Generate
            </Button> &nbsp;
            <Link to={`/projects/${currentProject}/application-build/${currentApplication}/requirements/new`}>
              <Button variant="primary" className="addReq-btn">
                <i className="fas fa-plus fa-sm text-white-50"></i> Add Feature
              </Button>
            </Link>
            &nbsp;&nbsp;
            <Button className="saveBtn chatButton" variant="primary" onClick={() => chatCanvasRef.current.show()}>
              <i class="fas fa-comments"></i>
            </Button>

          </div>
        </div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th>Feature ID</th>
              <th>Name</th>
              <th>Description</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {functionalRequirements && functionalRequirements.map((feature, index) => {
              return (<><tr>
                <td><Button className="nav-link inline" onClick={async () => await showRequirements(feature)}>
                  <i className={getFeatureClassName(feature)}></i>
                </Button></td>
                <td>{'F' + (index + 1).toString().padStart(2, '0')}</td>
                <td>{feature.name}</td>
                <td>{feature.description}</td>
                <td><div className="d-flex">
                  {/* FIXME< the inline style */}
                  <a
                              href="#"
                              onClick={() => confirmRegenerate(feature)}
                              style={{ textDecoration: "none", color: "black" }}
                            >
                              <i className="fas fa-sync"></i>
                            </a>
                  &nbsp;&nbsp;
                  <Link className="nav-link inline collapsed col-md-5" style={{ display: 'block' }}
                    to={`/projects/${currentProject}/application-build/${currentApplication}/features/${feature.id}`}
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                  >
                    <i className="fas fa-edit"></i>
                  </Link>

                  
                    <a href="#" onClick={() => confirmDeleteFeature(feature.id)} style={{ textDecoration: 'none', color: 'black' }}>
                      <i className="fas fa-trash"></i>
                    </a>


                </div>

                </td>
              </tr>
                {(feature.showDetail) &&
                  <tr>
                    <td colSpan={7}>
                      <Table bordered hover variant="dark" bsPrefix="requirements-inner-table">
                        <thead>
                          <tr>
                            <th>Requirement ID</th>
                            <th>Name</th>
                            <th>Description</th>
                            <th>Priority</th>
                            <th>Input/Output</th>
                            <th>Additional Notes</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          {feature.requirements.map((requirement, index) => {
                            return (<><tr>
                              <td>{'FR' + (index + 1).toString().padStart(2, '0')}</td>
                              <td>{requirement.name}</td>
                              <td>{requirement.description}</td>
                              <td>{requirement.priority}</td>
                              <td>{requirement.input_output}</td>
                              <td>{requirement.additional_notes}</td>
                              <td><div className="d-flex">
                                <Link
                                  className="nav-link inline collapsed col-md-5"
                                  to={`/projects/${currentProject}/application-build/${currentApplication}/features/${feature.id}/requirements/${requirement.id}`}
                                  data-toggle="collapse"
                                  data-target="#collapseTwo"
                                  aria-expanded="true"
                                  aria-controls="collapseTwo"
                                >
                                  <i className="fas fa-edit"></i>
                                </Link>

                              </div></td>
                            </tr>
                            </>)
                          })}

                        </tbody>
                      </Table>
                    </td>
                  </tr>
                }
              </>
              )
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
};


export default ApplicationFeatures;
