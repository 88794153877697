import React from 'react';
import './DisplayInfo.css'
import OpenTextBox from '../OpenTextBox/OpenTextBox';


const DisplayInfo = ({
    title,
    content,
    tableHeader,
    tableData,
    message,
    setMessage,
    pillTitle,
    update,
    pillContent,
    graphUrl,
    component
}) => {
    return (
        <div className="fault-tolerance-analysis">
            <h1>{title}</h1>
            <p>{content}</p>
            {(pillTitle || pillContent) && (
                <div className="pill">
                    {pillTitle && <span className="pill-title">{pillTitle}</span>}
                    {pillContent && <span className="pill-content">{pillContent}</span>}
                </div>
            )}

            {!component ? <table>
                <div className='tablegraphContainer'>
                    <table>
                        <thead>
                            <tr>
                                {tableHeader.map((header, index) => (
                                    <th key={index}>{header}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {tableData.map((row, index) => (
                                <tr key={index}>
                                    {row.map((cell, cellIndex) => (
                                        <td key={cellIndex}>{cell}</td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {graphUrl && (
                        <div className="graph-container">
                            <img src={graphUrl} alt="Graph" className="graph-image" />
                        </div>
                    )}
                </div>

            </table> :
                <>{component}</>
            }
            <div style={{ backgroundColor: 'white', width: '100%', paddingBottom: '1.4rem' }}>
                {message &&
                    <OpenTextBox
                        title="Description"
                        value={message}
                        setValue={setMessage}
                        updateButton={update}
                    />
                }
            </div>

        </div>
    );
};

export default DisplayInfo;

