import axios from "axios";
import { API } from './api'
import yaml from 'js-yaml';

/**
 * Generates the test cases with the given payload and returns the created test cases
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @param {*} payload 
 * @returns 
 */
const createTestCases = async (projectId, applicationId, payload) => API().post(`/project/${projectId}/application/${applicationId}/testcasegenerate/`, payload);

/**
 * Returns the list of test cases defined for the given application
 * 
 * @param {*} projectId 
 * @param {*} id 
 * @returns 
 */
const getTestcases = async (projectId, id) => API().get(`/project/${projectId}/application/${id}/testcase/`);

/**
 * Returns the test case with the given id
 * 
 * @param {*} projectId
 * @param {*} applicationId
 * @param {*} id
 * @returns {Promise<AxiosResponse<any>>}
 */
const getTestcase = async (projectId, applicationId, id) => API().get(`/project/${projectId}/application/${applicationId}/testcase/${id}`);

export {
    createTestCases,
    getTestcases,
    getTestcase,
};