import React, { FC, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
import { Table } from 'react-bootstrap';
import { getApplications } from '../../services/applicationService';
import "./ApplicationTechnicalArchitecture.css";
import useCurrentProject from '../../components/App/useCurrentProject';
import ApplicationBuildMenu from '../../components/ApplicationBuildMenu/ApplicationBuildMenu';

const ApplicationTechnicalArchitecture = () => {
      const [applications, setApplications] = useState([]);
      const {currentProject} = useCurrentProject();

      const init = async () => {
        try {


        } catch (error) {
            //if (error.response.status === 404) navigate(`/${getSiteLanguage()}/not-found`)
            //if (error.response.status === 500) navigate(`/${getSiteLanguage()}/error`)
        }
    }

    useEffect(() => {
        init();
    }, []);

    return (
      <>
       <ApplicationBuildMenu stepCode="technical-architecture" />

        <h1>Technical Architecture</h1>
      </>  
    );
  };

export default ApplicationTechnicalArchitecture;
