import { useState } from 'react';
import { Link, useParams } from "react-router-dom";

export default function useCurrentProject() {
  const {featureId} = useParams();

  const getCurrentFeature = () => {

    //return projectId
    return featureId;
  };

  const [currentFeature, setCurrentFeature] = useState(getCurrentFeature());

  return {
    setCurrentFeature,
    currentFeature
  }
}

