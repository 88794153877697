import React, { useRef, useState } from "react";
import { BsMic, BsMicFill } from "react-icons/bs";
import { FaPause } from "react-icons/fa6";
import { Button } from "react-bootstrap";
import { MediaRecorder, register } from 'extendable-media-recorder';
import { connect } from 'extendable-media-recorder-wav-encoder';

await register(await connect());
const SpeechToText = ({ handleVoiceToText }) => {
    const [permission, setPermission] = useState(false);
    const [stream, setStream] = useState(null);
    // const mediaRecorder = useRef(null);
    const [recordingStatus, setRecordingStatus] = useState("inactive");
    const [audioChunks, setAudioChunks] = useState([]);
    const [mediaRecorder, setMediaRecorder]= useState();

    const getMicrophonePermission = async () => {
        if ("MediaRecorder" in window) {
            try {
                const streamData = await navigator.mediaDevices.getUserMedia({
                    audio: true,
                    video: false,
                });
                setPermission(true);
                setStream(streamData);
            } catch (err) {
                alert(err.message);
            }
        } else {
            alert("The MediaRecorder API is not supported in your browser.");
        }
    };

    const startRecording = async () => {
        setRecordingStatus("recording");
    
        const mediaConstraints = { audio: true };
        const stream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
    
        const mediaRecorderOptions = {
            mimeType:"audio/wav",
            audioBitsPerSecond: 16000, // Set the audio bitrate to 16-bit (16,000 bits per second)
        };
        const mediaRecorder = new MediaRecorder(stream, mediaRecorderOptions);
    
        const localAudioChunks = [];
        mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
                localAudioChunks.push(event.data);
            }
        };
    
        mediaRecorder.onstop = () => {
            const audioBlob = new Blob(localAudioChunks); // Adjust the type as per your requirement
            const reader = new FileReader();
            reader.readAsDataURL(audioBlob);
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1];
                handleVoiceToText(base64String);
            };
        };
    
        mediaRecorder.start();
        setMediaRecorder(mediaRecorder);
    };
    
    const stopRecording = () => {
        setRecordingStatus("inactive");
        mediaRecorder.stop();
        // mediaRecorder.current.onstop = () => {
        //     const audioBlob = new Blob(audioChunks);
        //     const reader = new FileReader();
        //     reader.readAsDataURL(audioBlob);
        //     reader.onloadend = () => {
        //         const base64String = reader.result.split(',')[1];
        //         console.log(base64String)
        //         handleVoiceToText(base64String);
        //     };
        //     setAudioChunks([]);
        // };
       
    };

    return (
        <div className="audio-controls">
            {!permission && (
                <Button onClick={getMicrophonePermission} className={"micInput"}>
                    <BsMic />
                </Button>
            )}
            {permission && recordingStatus === "inactive" && (
                <Button onClick={startRecording} className={"micInput"}>
                    <BsMicFill />
                </Button>
            )}
            {recordingStatus === "recording" && (
                <Button onClick={stopRecording} className={"micInputActive"}>
                    <FaPause />
                </Button>
            )}
        </div>
    );
};

export default SpeechToText;
