import OpenTextBox from "../../OpenTextBox/OpenTextBox"
import PointsInformation from "../../PointsInformation/PointsInformation"

const CompanyProfile = ({
    summary,
    setSummary,
    points,
    setPoints,
    update,
    deleteContextFinding,
    updateKeyFinding
}) => {

    return (
        <div className="information-gathering-wrapper">
            <OpenTextBox
                title="Summary of company profile"
                value={summary}
                setValue={setSummary}
                updateButton={update}
            />

            <PointsInformation
                title="Key Findings"
                points={points}
                setPoints={setPoints}
                deleteContextFinding={deleteContextFinding}
                updateKeyFinding={updateKeyFinding}
            />
        </div>
    )
}

export default CompanyProfile