import axios from "axios";
import { API } from './api'
import yaml from 'js-yaml';




const getOverview = async (projectId, applicationId) => API().get(`/project/${projectId}/application/${applicationId}/overview/`);
 
/**
 * Gets the overvieww with the given id and returns the updated requirement
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @returns 
 */
const updateOverview = async (projectId, applicationId, payload) => API().put(`/project/${projectId}/application/${applicationId}/overview/`,payload);
 
/**
 * Updates the overview with the given id and returns the updated requirement
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @returns 
 */

const generateOverview = async (projectId, applicationId) => API().post(`/project/${projectId}/application/${applicationId}/overview/generate/`);
 
/**
 * Generates the overview with the given id and returns the updated requirement
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @returns 
 */

const getScope = async (projectId, applicationId) => API().get(`/project/${projectId}/application/${applicationId}/scope/`);
 
/**
 * Gets the scope with the given id and returns the updated requirement
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @returns 
 */
const updateScope = async (projectId, applicationId,payload) => API().put(`/project/${projectId}/application/${applicationId}/scope/`,payload);
 
/**
 * Update the scope with the given id and returns the updated requirement
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @returns 
 */

const generateScope = async (projectId, applicationId) => API().post(`/project/${projectId}/application/${applicationId}/scope/generate/`);
 
/**
 * Generates Scope  with the given id and returns the updated requirement
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @returns 
 */






export {
    getOverview,
    updateOverview,
    generateOverview,
    getScope,
    updateScope,
    generateScope, 
};