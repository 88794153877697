import React from 'react'
import { Card } from 'react-bootstrap';
import { PiHandTapLight } from "react-icons/pi";
import { FaRegFileExcel } from "react-icons/fa";
import './principalComponent.css'

export default function PrincipalComponent({ title, selected, handleClick, csv }) {
  return (
    <Card className={`cardWrap ${selected ? 'selected' : ''}`} onClick={handleClick} >
        <span className='principalContent'>{title} </span>
      <div className="iconContainer">
        <PiHandTapLight size={40} style={{color: 'white'}} />
      </div>
      {csv && <div className='csvUploadIcon'><FaRegFileExcel size={35} style={{color: 'white'}}/> </div>}
    </Card>
  )
}
