import React, { createContext, useContext, useState } from 'react';

// Step 1: Create a context
const MyGlobalContext = createContext();

// Step 2: Create a provider component
const MyGlobalContextProvider = ({ children }) => {
  const [globalState, setGlobalState] = useState({
    projectId:null,
    assessmentId:null,
    chatId:null
  });

  const updateGlobalState = (newState) => {
    setGlobalState(newState);
  };

  // You can add other functions or values to the context

  return (
    <MyGlobalContext.Provider value={{ globalState, updateGlobalState }}>
      {children}
    </MyGlobalContext.Provider>
  );
};

// Step 3: Create a custom hook to use the context
const useMyGlobalContext = () => {
    return useContext(MyGlobalContext);
  };

export{
    MyGlobalContextProvider,
    useMyGlobalContext
}