import React, { FC, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
import { Table } from 'react-bootstrap';
import "./ApplicationRequirementDetail.css";
import useCurrentProject from '../../../components/App/useCurrentProject';
import useCurrentApplication from '../../../components/App/useCurrentApplication';
import { useNavigate } from 'react-router-dom';
import ApplicationBuildMenu from '../../../components/ApplicationBuildMenu/ApplicationBuildMenu';
import { useParams } from 'react-router-dom';
import { getRequirement, downloadRequirementCard ,saveRequirement,createRequirement,updateRequirement,getRequirements} from '../../../services/requirementsService';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Chat from '../../../components/Chat/Chat';
import toast, { Toaster } from "react-hot-toast";
import useCurrentFeature from '../../../components/App/useCurrentFeature';
import { launchAndPollForTermination } from '../../../util/util';

const ApplicationMicroservices = () => {
    const {currentProject} = useCurrentProject();
    const {currentApplication} = useCurrentApplication();
    const {currentFeature} = useCurrentFeature();

    const {id} = useParams();
    const navigate = useNavigate();
    const [applicationName, setApplicationName] = useState("");
    const [requirementCard, setRequirementCard] = useState({});
    const [requirement, setRequirement] = useState({});

    const [canvasChatShow, setCanvasChatShow] = useState(false);

    const handleCanvasChatClose = () => setCanvasChatShow(false);
    const handleCanvasChatShow = () => setCanvasChatShow(true);

    const handleChange = (name, e) => {
      console.log(name, e.target.value);
      let value = e.target.value;
  
      setRequirement({...requirement, [name]: value});

      console.log('handleChange', requirement);
    }
    
    
    const init = async () => {
        try {
          const path = window.location.pathname;

          if(path.endsWith("requirements/new")){
              console.log("Don't load the data")
          }
          else{
          const req = await getRequirement(currentProject, currentApplication, currentFeature, id);

          setRequirement(req.data);
          console.log(req.data);

          //const reqCard = await downloadRequirementCard(currentProject, currentApplication, currentFeature, id);
    
          //setRequirementCard(reqCard);
          }
        } catch (error) {
            //if (error.response.status === 404) navigate(`/${getSiteLanguage()}/not-found`)
            //if (error.response.status === 500) navigate(`/${getSiteLanguage()}/error`)
        }
    }

    const regenerateRequirement = async () => {
      try {
        const resp = await regenerateRequirement(currentProject, currentApplication, currentFeature, id, {regenerate:true});
        console.log(resp);
        toast.success("A request to regenerate the requirement has been sent to the system.");
        
        launchLoadRequirementAndWait();
      } catch (error) {
        console.log(error);
      }
    };

    const launchLoadRequirementAndWait = async () => {

      const toastId = toast.loading('Loading...');
  
      try {
        let newRequirement = null;
  
        launchAndPollForTermination(async () => {
          newRequirement = await getRequirement(currentProject, currentApplication, currentFeature, id);
          newRequirement = newRequirement.data;
          if (newRequirement && newRequirement.description !== requirement.description) {
  
            toast.dismiss(toastId);
  
            init();
            toast.success("The requirement has been updated.");
          }
        }, () => newRequirement && newRequirement.data && newRequirement.description !== requirement.description);
      } catch (error) {
        toast.dismiss(toastId);
  
        toast.error("An error occurred while loading the requirement.");
  
        console.log(error);
      }
    };

    useEffect(() => {
        init();
    }, []);



    const saveRequirement = async () => {
      try {
      console.log("saveProject", requirement);
      let resp = null;
  
      let payload = {...requirement};
      delete payload.list_of_generated_reports;
      /*
      if(!payload.list_of_generated_reports) {
        payload.list_of_generated_reports = [];
      }
      */  
//      const path = location.pathname;
//      path.endsWith("/requirements/new"
       if(requirement.id == null ) {

        console.log("createRequirement", currentProject, payload);
        payload = {...payload, application:currentApplication};
        resp = await createRequirement(currentProject, currentApplication,payload);
        navigate(`/projects/${currentProject}/application-build/${currentApplication}/requirements/${resp.data.id}`)

      } else {
        resp = await updateRequirement(currentProject, currentApplication,requirement.id, payload);
      }
  
      console.log(resp);
      toast.success("The requirement has been saved.");
  
      if(!currentApplication) {
        // usenavigate seems to not refresh the entire page (menu doesn't recognize the new application)
        window.location.href = `/projects/${currentProject}/application-build/${resp.data.id}/requirements`;
      }
  
      } catch (error) {
        console.log(error);
      }
  
    }
  
     

    
    const CanvasChat = () => { 
      return (
        <>    
          <Offcanvas show={canvasChatShow} onHide={handleCanvasChatClose} placement={'end'} backdrop={false} scroll={true}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Chat</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Chat />
            </Offcanvas.Body>
          </Offcanvas>
        </>
      )
  };

    return (
      <>
       <ApplicationBuildMenu stepCode="requirements" />
       <CanvasChat />
       <div className={ "container" }>



<div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-8 text-gray-800"> Requirement: {requirement.name}</h1>
              
              {/*}
              <Button variant="primary" onClick={downloadExcel} className="download-btn">
              <i className="fas fa-plus fa-sm text-white-50"></i> Export
              </Button>
    {*/}
             
             
             <div>
             <Button className="saveBtn" variant="primary" onClick={saveRequirement}>
          <i className="saveBtn"></i>{" "}<span>Save</span>
          </Button>&nbsp;
          <Button className="saveBtn" variant="primary" onClick={regenerateRequirement}>
          <i className="saveBtn"></i>{" "}<span>Regenerate</span>
          </Button>&nbsp;
        

              </div>
              </div>
<Button className="saveBtn chatButton" variant="primary" onClick={handleCanvasChatShow}>
              <i class="fas fa-comments"></i>
          </Button>
<Form>
    <Form.Group className="mb-3" controlId="formProjectEmail">
      <Form.Label>Name</Form.Label>
      <Form.Control type="text" placeholder="Enter a project name" value={requirement.name} onChange={(e)=>handleChange("name", e)} />
    </Form.Group>
    <Form.Group className="mb-3" controlId="formProjectEmail">
      <Form.Label>Description</Form.Label>
      <Form.Control type="text"  as="textarea" rows={3} placeholder="Enter a project name" value={requirement.description} onChange={(e)=>handleChange("description", e)}  />
    </Form.Group>
    <Form.Group className="mb-3" controlId="formProjectEmail">
      <Form.Label>Priority</Form.Label>
      <Form.Control type="text" placeholder="Enter a project name" value={requirement.priority} onChange={(e)=>handleChange("priority", e)} />
    </Form.Group>
    <Form.Group className="mb-3" controlId="formProjectEmail">
      <Form.Label>Input/Output</Form.Label>
      <Form.Control type="text" placeholder="Enter a project name" value={requirement.input_output} onChange={(e)=>handleChange("input_output", e)} />
    </Form.Group>
    <Form.Group className="mb-3" controlId="formProjectMainFlow">
      <Form.Label>Main Flow</Form.Label>

      <Table striped bordered hover>
            <thead>
              <tr>
                <th>Step #</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
                { requirement && requirement.steps && requirement.steps.map((step,index) => {
                  return (<><tr>
                  <td>{step.step_number}</td>
                  <td>{step.description}</td>
              </tr>
              </>)})}
              </tbody>
              </Table>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formProjectMainFlow">
      <Form.Label>Alternative Flows</Form.Label>

      <Table striped bordered hover>
            <thead>
              <tr>
                <th>Step #</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
                { requirement && requirement.alternative_flows && requirement.alternative_flows.map((step,index) => {
                  return (<><tr>
                  <td>{step.step_number}</td>
                  <td>{step.description}</td>
              </tr>
              </>)})}
              </tbody>
              </Table>
      </Form.Group>

              </Form>
  <br/>
  <br/>
</div>

      </>  
    );
  };

export default ApplicationMicroservices;
