import { API } from '../api'

const getProjects = async()=> API().get('/project/')
const postProject = async(body)=> API().post('/project/',body)


const getAsseessments = async(id) => API().get(`/project/${id}/assessment/`)
const postAssessment = async(id,body)=> API().post(`/project/${id}/assessment/`,body)


export{
    getProjects,
    postProject,
    postAssessment,
    getAsseessments
}