import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import "./ApplicationDocument.css";
import toast from "react-hot-toast";
import ApplicationBuildMenu from "../../../components/ApplicationBuildMenu/ApplicationBuildMenu";

const ApplicationDocument = () => {
  const [revision, setRevision] = useState("");
  const [comment, setComment] = useState("");
  const [date, setDate] = useState(new Date().toISOString().split("T")[0]);

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    try {
      toast.success("Generated Document");
    } catch (error) {
      toast.error("Error while creating document");
    }
  };

  return (
    <>
      <ApplicationBuildMenu stepCode="document" />
      <Form onSubmit={handleSubmit} className="documentForm">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h3 className="docTitle">Document Generation</h3>
          <Button variant="primary" type="submit" className="saveBtn">
            <span className="generateText">
            Generate
            </span>
            <i className="fas fa-cog fa-sm text-white-50"></i>
          </Button>
        </div>
        <Form.Group controlId="revision">
          <Form.Label>Revision</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter revision"
            value={revision}
            onChange={(e) => setRevision(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="comment">
          <Form.Label>Comment</Form.Label>
          <Form.Control
            as="textarea"
            rows={3}
            placeholder="Enter comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </Form.Group>

        <Form.Group controlId="date">
          <Form.Label>Date</Form.Label>
          <Form.Control type="date" value={date} onChange={handleDateChange} />
        </Form.Group>
      </Form>
    </>
  );
};

export default ApplicationDocument;
