import React, { FC, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useParams } from "react-router-dom";
import useCurrentApplication from "../../components/App/useCurrentApplication";
import useCurrentProject from "../../components/App/useCurrentProject";
import Popover from "react-bootstrap/Popover";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Offcanvas from "react-bootstrap/Offcanvas";
import Modal from "react-bootstrap/Modal";
import toast, { Toaster } from "react-hot-toast";
import Chat from "../../components/Chat/Chat";
import {
  ProjectNamePopover,
  ProjectDescPopover,
  BusinessDepPopover,
  IndustriesPopover,
  SectorPopover,
  TargetPopover,
  InternalUsersPopover,
  ExternalUsersPopover,
  DataPrivacyPopover,
  TargetAvailabilityPopover,
  GeographyPopover,
  IntegrationPopover,
} from "./Popover";
import ApplicationBuildMenu from "../../components/ApplicationBuildMenu/ApplicationBuildMenu";
import { useNavigate } from "react-router-dom";
import {
  getApplication,
  createApplication,
  updateApplication,
  fillImproveApplicationField,
} from "../../services/applicationService";
import { Nav } from "react-bootstrap";

import "./ApplicationInitiation.css";
import SendForReview from "../../components/SendForReview/SendForReview";
import ApplicationInitiationNav from "./Question/ApplicationInitiationNav";

function ApplicationInitiation() {
  const navigate = useNavigate();
  const { currentProject } = useCurrentProject();
  const { currentApplication } = useCurrentApplication();

  const [application, setApplication] = useState({});
  const [fieldToImprove, setFieldToImprove] = useState("general_description");
  const [fieldToImproveResponse, setFieldToImproveResponse] = useState("");

  const [modalShow, setModalShow] = useState(false);

  const [sendForReviewModalShow, setSendForReviewModalShow] = useState(false);

  const [canvasChatShow, setCanvasChatShow] = useState(false);
  const [canvasImproveShow, setCanvasImproveShow] = useState(false);

  const [sectors, setSectors] = useState([]);

  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);

  const handleCanvasChatClose = () => setCanvasChatShow(false);
  const handleCanvasChatShow = () => setCanvasChatShow(true);

  const handleCanvasImproveClose = () => setCanvasImproveShow(false);
  const handleCanvasImproveShow = () => setCanvasImproveShow(true);

  const handleSendForReviewModalClose = () => setSendForReviewModalShow(false);
  const handleSendForReviewModalShow = () => setSendForReviewModalShow(true);

  const fieldToImproveAllowedFields = [
    {
      name: "Application Name",
      value: "name",
    },
    {
      name: "General Description",
      value: "general_description",
    },
    {
      name: "Business Department",
      value: "business_department",
    },
    {
      name: "Sector",
      value: "sector",
    },
    {
      name: "Target Audience",
      value: "target_audience",
    },
    {
      name: "Data privacy needs",
      value: "data_privacy_needs",
    },
    {
      name: "Target availability",
      value: "target_availability",
    },
    {
      name: "Geography",
      value: "geography",
    },
    {
      name: "Integration",
      value: "integration",
    },
  ];

  const industries = [
    {
      id: 1,
      name: "Consumer",
      sectors: [
        "Automotive",
        "Consumer Products",
        "Retail, Wholesale & Distribution",
      ],
    },
    {
      id: 2,
      name: "Energy, Resources & Industrials",
      sectors: [
        "Industrial Products & Construction",
        "Mining & Metals",
        "Oil, Gas & Chemicals",
        "Power & Utilities",
      ],
    },
    {
      id: 3,
      name: "Financial Services",
      sectors: [
        "Banking & Capital Markets",
        "Insurance",
        "Investment Management",
        "Real Estate",
      ],
    },
    {
      id: 4,
      name: "Government & Public Services",
      sectors: [
        "Civil Government",
        "Defense, Security & Justice",
        "Federal Health",
        "International Donor Organizations",
        "State, Local & Education",
      ],
    },
    {
      id: 5,
      name: "Life Sciences & Health Care",
      sectors: ["Health Care", "Life Sciences"],
    },
    {
      id: 6,
      name: "Technology, Media & Telecommunications",
      sectors: ["Technology", "Telecommunications, Media & Entertainment"],
    },
  ];

  const init = async () => {
    try {
      console.log("currentApplication", currentApplication);

      if (!currentApplication) {
        return;
      }

      console.log("before getApplication", currentApplication);
      const application = await getApplication(
        currentProject,
        currentApplication
      );
      console.log(application.data);
      console.log("after getApplication", currentApplication);

      console.log(application.data);

      // find the industry using the sector from application.data
      const ind = industries.find((industry) =>
        industry.sectors.includes(application.data.sector)
      );
      console.log("ind", ind);
      setSectors(ind.sectors);

      application.data.industry = ind.id;

      setApplication(application.data);

      console.log("dopo getApplicationRequirements");
    } catch (error) {
      //if (error.response.status === 404) navigate(`/${getSiteLanguage()}/not-found`)
      //if (error.response.status === 500) navigate(`/${getSiteLanguage()}/error`)
    }
  };

  useEffect(() => {
    init();
  }, []);

  // handle react form value changes
  const handleChange = (name, e) => {
    console.log(name, e.target.value);
    let value = e.target.value;

    if (name === "industry") {
      const ind = industries.find(
        (industry) => industry.id === parseInt(value)
      );
      setSectors(ind.sectors);
      console.log("industry", ind);

      setApplication({ ...application, industry: ind.id, sector: "" });
    } else {
      setApplication({ ...application, [name]: value });
    }

    console.log("handleChange", application);
  };

  const handleChangeFieldToImprove = (e) => {
    console.log("handleChangeFieldToImprove", e.target.value);

    setFieldToImprove(e.target.value);
  };

  const handleSendForReviewModalSubmit = async () => {
    try {
      console.log("handleSendForReviewModalSubmit", application);
      let resp = null;

      toast.success("The application has been sent for review.");

      handleSendForReviewModalClose();
    } catch (error) {
      console.log(error);
      toast.error("Error while sending the application for review");
    }
  };

  const saveProject = async () => {
    try {
      console.log("saveProject", application);
      let resp = null;

      const payload = { ...application };
      delete payload.list_of_generated_reports;
      /*
    if(!payload.list_of_generated_reports) {
      payload.list_of_generated_reports = [];
    }
    */

      if (!currentApplication) {
        console.log("createApplication", currentProject, payload);
        resp = await createApplication(currentProject, payload);
      } else {
        resp = await updateApplication(
          currentProject,
          currentApplication,
          payload
        );
      }

      console.log(resp);
      toast.success("The application has been saved.");

      if (!currentApplication) {
        // usenavigate seems to not refresh the entire page (menu doesn't recognize the new application)
        window.location.href = `/projects/${currentProject}/application-build/${resp.data.id}/initiation`;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fillImprove = async () => {
    const toastId = toast.loading("Loading...");

    try {
      console.log(
        "fillImproveApplicationField",
        currentApplication,
        application,
        fieldToImprove
      );
      let resp = null;

      const payload = {
        field: fieldToImprove,
      };

      setFieldToImproveResponse("");
      resp = await fillImproveApplicationField(
        currentProject,
        currentApplication,
        payload
      );

      console.log(resp.data);

      if (resp) {
        setFieldToImproveResponse(
          resp.data['application description'][fieldToImprove]
        );
      }
    } catch (error) {
      console.log(error);
      toast.error("Error while getting the improved field");
    }

    toast.dismiss(toastId);
  };

  const UpdatedFieldPopover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Updated Field</Popover.Header>
      <Popover.Body>
        In the updated field you can see your improved text
      </Popover.Body>
    </Popover>
  );

  const OffCanvasImprove = () => {
    return (
      <>
        <Offcanvas
          show={canvasImproveShow}
          onHide={handleCanvasImproveClose}
          placement={"end"}
          backdrop={false}
          scroll={true}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Improve Field</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formImproveField">
                <Form.Select value={fieldToImprove}
                  onChange={(e) => handleChangeFieldToImprove(e)}
                >
                  <option>Choose field to improve</option>

                  {fieldToImproveAllowedFields.map((field) => {
                    return <option value={field.value}>{field.name}</option>;
                  }
                  )}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formProjectDescription">
                <Form.Label>Updated Field</Form.Label>
                <OverlayTrigger
                  delay={{ show: 250, hide: 400 }}
                  placement="right"
                  overlay={UpdatedFieldPopover}
                >
                  <span href="#" className="icon-link">
                    <i className="fas fa-info-circle fa-sm"></i>
                  </span>
                </OverlayTrigger>
                <Form.Control
                  type="text"
                  as="textarea"
                  rows={15}
                  placeholder=""
                  value={fieldToImproveResponse}
                />
              </Form.Group>
              <div className="improveButCont">
                <Button
                  className="saveBtn"
                  variant="primary"
                  onClick={fillImprove}
                >
                  Improve!
                </Button>
                <Button
                  className="saveBtn"
                  variant="primary"
                  onClick={() =>
                    navigator.clipboard.writeText(fieldToImproveResponse)
                  }
                >
                  Copy
                </Button>
              </div>
            </Form>
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  };

  const CanvasChat = () => {
    return (
      <>
        <Offcanvas
          show={canvasChatShow}
          onHide={handleCanvasChatClose}
          placement={"end"}
          backdrop={false}
          scroll={true}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Chat</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Chat />
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  };

  return (
    <div>
      <ApplicationBuildMenu stepCode="initiation" />

      <div className="container">
        <ApplicationInitiationNav currentTab="applicationData" />
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-8 text-gray-800">
            {" "}
            Application: {application.name}
          </h1>

          {/*}
              <Button variant="primary" onClick={downloadExcel} className="download-btn">
              <i className="fas fa-plus fa-sm text-white-50"></i> Export
              </Button>
    {*/}

          <div>
            <Button className="saveBtn" variant="primary" onClick={saveProject}>
              <i className="saveBtn"></i> <span>Save</span>
            </Button>
            &nbsp;
            <Button
              className="saveBtn"
              variant="primary"
              onClick={handleCanvasImproveShow}
            >
              <i className="saveBtn"></i> <span>Improve field</span>
            </Button>
            &nbsp;
            <Button
              className="saveBtn chatButton"
              variant="primary"
              onClick={handleCanvasChatShow}
            >
              <i class="fas fa-comments"></i>
            </Button>
            <Button
              className="saveBtn"
              variant="primary"
              onClick={handleSendForReviewModalShow}
            >
              <i className="saveBtn"></i> <span>Send For Review</span>
            </Button>
          </div>
        </div>
        <CanvasChat />
        <OffCanvasImprove />
        <SendForReview
          modalShow={sendForReviewModalShow}
          handleModalClose={handleSendForReviewModalClose}
          handleModalSubmit={handleSendForReviewModalSubmit}
        />

        <Form>
          <Form.Group className="mb-3" controlId="formProjectEmail">
            <Form.Label>Application Name</Form.Label>
            <OverlayTrigger
              delay={{ show: 250, hide: 400 }}
              placement="right"
              overlay={ProjectNamePopover}
            >
              <span className="icon-link">
                <i className="fas fa-info-circle fa-sm"></i>
              </span>
            </OverlayTrigger>
            <Form.Control
              type="text"
              placeholder="Enter an application name"
              value={application.name}
              onChange={(e) => handleChange("name", e)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formProjectDescription">
            <Form.Label>General Description</Form.Label>
            <OverlayTrigger
              delay={{ show: 250, hide: 400 }}
              placement="right"
              overlay={ProjectDescPopover}
            >
              <span href="#" className="icon-link">
                <i className="fas fa-info-circle fa-sm"></i>
              </span>
            </OverlayTrigger>
            <Form.Control
              type="text"
              as="textarea"
              rows={3}
              placeholder="General Description"
              value={application.general_description}
              onChange={(e) => handleChange("general_description", e)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formProjectDescription">
            <Form.Label>Business Department</Form.Label>
            <OverlayTrigger
              delay={{ show: 250, hide: 400 }}
              placement="right"
              overlay={BusinessDepPopover}
            >
              <span href="#" className="icon-link">
                <i className="fas fa-info-circle fa-sm"></i>
              </span>
            </OverlayTrigger>
            <Form.Control
              type="text"
              placeholder="Business Department"
              value={application.business_department}
              onChange={(e) => handleChange("business_department", e)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formSector">
            <Form.Label>Industry</Form.Label>
            <OverlayTrigger
              delay={{ show: 250, hide: 400 }}
              placement="right"
              overlay={IndustriesPopover}
            >
              <span href="#" className="icon-link">
                <i className="fas fa-info-circle fa-sm"></i>
              </span>
            </OverlayTrigger>
            <Form.Select
              value={application.industry}
              onChange={(e) => handleChange("industry", e)}
            >
              <option value="-1">Choose Industry</option>
              {industries &&
                industries.map((industry) => {
                  return <option value={industry.id}>{industry.name}</option>;
                })}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formSector">
            <Form.Label>Sector</Form.Label>
            <OverlayTrigger
              delay={{ show: 250, hide: 400 }}
              placement="right"
              overlay={SectorPopover}
            >
              <span href="#" className="icon-link">
                <i className="fas fa-info-circle fa-sm"></i>
              </span>
            </OverlayTrigger>
            <Form.Select
              value={application.sector}
              onChange={(e) => handleChange("sector", e)}
            >
              <option value="-1">Choose Sector</option>
              {sectors &&
                sectors.map((sector) => {
                  return <option value={sector}>{sector}</option>;
                })}
            </Form.Select>
          </Form.Group>

          <Form.Group className="mb-3" controlId="formTargetAudience">
            <Form.Label>Target Audience</Form.Label>
            <OverlayTrigger
              delay={{ show: 250, hide: 400 }}
              placement="right"
              overlay={TargetPopover}
            >
              <span href="#" className="icon-link">
                <i className="fas fa-info-circle fa-sm"></i>
              </span>
            </OverlayTrigger>
            <Form.Control
              type="text"
              placeholder="Target Audience"
              value={application.target_audience}
              onChange={(e) => handleChange("target_audience", e)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formNumberOfInternalUsers">
            <Form.Label>Estimated number of internal users</Form.Label>
            <OverlayTrigger
              delay={{ show: 250, hide: 400 }}
              placement="right"
              overlay={InternalUsersPopover}
            >
              <span href="#" className="icon-link">
                <i className="fas fa-info-circle fa-sm"></i>
              </span>
            </OverlayTrigger>
            <Form.Control
              type="number"
              placeholder="Number of internal users"
              value={application.number_of_internal_users}
              onChange={(e) => handleChange("number_of_internal_users", e)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formNumberOfExternalUsers">
            <Form.Label>Estimated number of external users</Form.Label>
            <OverlayTrigger
              delay={{ show: 250, hide: 400 }}
              placement="right"
              overlay={ExternalUsersPopover}
            >
              <span href="#" className="icon-link">
                <i className="fas fa-info-circle fa-sm"></i>
              </span>
            </OverlayTrigger>
            <Form.Control
              type="number"
              placeholder="Number of internal users"
              value={application.number_of_external_users}
              onChange={(e) => handleChange("number_of_external_users", e)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formDataPrivacyNeeds">
            <Form.Label>Data privacy needs</Form.Label>
            <OverlayTrigger
              delay={{ show: 250, hide: 400 }}
              placement="right"
              overlay={DataPrivacyPopover}
            >
              <span href="#" className="icon-link">
                <i className="fas fa-info-circle fa-sm"></i>
              </span>
            </OverlayTrigger>
            <Form.Control
              type="text"
              placeholder="Data privacy needs"
              value={application.data_privacy_needs}
              onChange={(e) => handleChange("data_privacy_needs", e)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formDataPrivacyNeeds">
            <Form.Label>Target Availability</Form.Label>
            <OverlayTrigger
              delay={{ show: 250, hide: 400 }}
              placement="right"
              overlay={TargetAvailabilityPopover}
            >
              <span href="#" className="icon-link">
                <i className="fas fa-info-circle fa-sm"></i>
              </span>
            </OverlayTrigger>
            <Form.Control
              type="text"
              placeholder="Target Availability"
              value={application.target_availability}
              onChange={(e) => handleChange("target_availability", e)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formDataPrivacyNeeds">
            <Form.Label>Geography</Form.Label>
            <OverlayTrigger
              delay={{ show: 250, hide: 400 }}
              placement="right"
              overlay={GeographyPopover}
            >
              <span href="#" className="icon-link">
                <i className="fas fa-info-circle fa-sm"></i>
              </span>
            </OverlayTrigger>
            <Form.Control
              type="text"
              placeholder="Geography"
              value={application.geography}
              onChange={(e) => handleChange("geography", e)}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="formIntegration">
            <Form.Label>Integration</Form.Label>
            <OverlayTrigger
              delay={{ show: 250, hide: 400 }}
              placement="right"
              overlay={IntegrationPopover}
            >
              <span href="#" className="icon-link">
                <i className="fas fa-info-circle fa-sm"></i>
              </span>
            </OverlayTrigger>
            <Form.Control
              type="text"
              as="textarea"
              rows={3}
              placeholder="Integration"
              value={application.integration}
              onChange={(e) => handleChange("integration", e)}
            />
          </Form.Group>

          <div className="row">
            <div className="col-xl-8 col-md-8 mb-8"></div>

            <div>
              <br />
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default ApplicationInitiation;
