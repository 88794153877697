import React, { FC, useEffect, useState, useRef } from 'react';
import useCurrentApplication from '../../../components/App/useCurrentApplication';
import useCurrentProject from '../../../components/App/useCurrentProject';
import Nav from 'react-bootstrap/Nav';

const ApplicationInitiationNav = ({currentTab}) => {
    const {currentProject} = useCurrentProject();
    const {currentApplication} = useCurrentApplication();

    return (<Nav variant="tabs" defaultActiveKey={currentTab}>
    <Nav.Item>
      <Nav.Link eventKey="applicationData"  href={ `/projects/${currentProject}/application-build/${currentApplication}/initiation` }>Application Data</Nav.Link>
    </Nav.Item>
    <Nav.Item>
      <Nav.Link eventKey="questions"  href={ `/projects/${currentProject}/application-build/${currentApplication}/question` }>Questions</Nav.Link>
    </Nav.Item>
  </Nav>
  )};

export default ApplicationInitiationNav;