import React from 'react';
import Popover from 'react-bootstrap/Popover';

const createPopover = (title, content) => (
  <Popover id="popover-basic">
    <Popover.Header as="h3">{title}</Popover.Header>
    <Popover.Body>{content}</Popover.Body>
  </Popover>
);

export const ProjectNamePopover = createPopover("Application Name", "Type the name for the application you want to start gathering requirements.");
export const ProjectDescPopover = createPopover("Project Description", "The description will help the system define the application requirements; more detailed the description, better the results.");
export const BusinessDepPopover = createPopover("Business Department", "In the business department, include essential teams and departments needed for your project's success.");
export const IndustriesPopover = createPopover("Industries", "In the industry context, assemble the necessary departments and teams crucial for your sector's operations.");
export const SectorPopover = createPopover("Sector", "Sector refers to the specific industry or field in which the project operates.");
export const TargetPopover = createPopover("Target Audience", "The 'target audience' refers to the group of people the app is meant for.");
export const InternalUsersPopover = createPopover("Internal Users", "Type the estimated number of internal users (individuals and groups within the organization itself, such as employees, management, board members, and shareholders).");
export const ExternalUsersPopover = createPopover("External Users", "Type the estimated number of external users (entities outside the organization, including customers, suppliers, investors, creditors, regulators, and the general public).");
export const DataPrivacyPopover = createPopover("Data Privacy needs", "Data privacy needs encompass both internal and external aspects, ensuring trust and legal adherence.");
export const TargetAvailabilityPopover = createPopover("Target Availability", "Target availability means ensuring that systems and resources are always ready for employees inside the organization and for customers outside.");
export const GeographyPopover = createPopover("Geography", "Balancing geography needs means making smart choices about where to set up offices and distribution centers within the organization.");
export const IntegrationPopover = createPopover("Integration", "For integration, bring together key components or systems by including teams responsible for development, testing, documentation, communication, and quality assurance.");
export const UpdatedFieldPopover = createPopover("Updated Field", "In the updated field, you can see your improved text.");
