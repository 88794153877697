import axios from "axios";
import { API } from './api'
import yaml from 'js-yaml';

/**
 * Executes the login call to the backend and returns the token
 * 
 * @param {*} payload 
 * @returns 
 */
const login = async (payload) => API().post(`/token/`, payload);

export {
    login,
};