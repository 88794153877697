import React, { FC, useState } from "react";
import Container from "react-bootstrap/Container";
import PropTypes from "prop-types";
import { login } from "../../services/authService";
import { useEffect } from "react";
import { RelationshipDiagram } from "react-erd";
import "react-erd/style.css";
import { getProjects } from "../../services/projectService";
import toast from "react-hot-toast";

const Login = ({ setToken }) => {
  const [username, setUserName] = useState(""); // useState("trialUser");
  const [password, setPassword] = useState(""); // useState("Deloitte");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = await login({
        username,
        password,
      });
      console.log(token);
      setToken(token.data);
      if (!localStorage.getItem("currentProject")) {
        const projectsList = await getProjects();
        if (projectsList.data.length > 0) {
          const firstProjectId = projectsList.data[0].id;
          localStorage.setItem("currentProject", firstProjectId);
          console.log(`current project is: ${firstProjectId}`);
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("error occured while attempting to loggin." + error.messsage);
    }
  };

  return (
    <div className="container-fluid">
      {/* <!-- Page Heading --> 
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
        </div>*/}

      <Container fluid>
        <form onSubmit={handleSubmit}>
          <label>
            <p>Username</p>
            <input
              type="text"
              value={username}
              onChange={(e) => setUserName(e.target.value)}
            />
          </label>
          <label>
            <p>Password</p>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </label>
          <div>
            <button type="submit">Submit</button>
          </div>
        </form>
      </Container>
    </div>
  );
};

Login.propTypes = {
  setToken: PropTypes.func.isRequired,
};

export default Login;
