import React, { FC, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
import { Table } from 'react-bootstrap';
import { getApplication } from '../../services/applicationService';
import "./ApplicationTestCases.css";
import useCurrentProject from '../../components/App/useCurrentProject';
import ApplicationBuildMenu from '../../components/ApplicationBuildMenu/ApplicationBuildMenu';
import { getTestcase, getTestcases, createTestCases } from '../../services/testCasesService';
import useCurrentApplication from '../../components/App/useCurrentApplication';
import { useParams } from 'react-router-dom';
import toast, { Toaster } from "react-hot-toast";
import Modal from 'react-bootstrap/Modal';

const ApplicationMicroservices = () => {
      const {currentProject} = useCurrentProject();
      const {currentApplication} = useCurrentApplication();

      const [application, setApplication] = useState({});
      const [functionalRequirements, setFunctionalRequirements] = useState([]);
      const [cards, setCards] = useState({requirementsList: []});
      const [numberOfRequirements, setNumberOfRequirements] = useState(3);

      const [modalShow, setModalShow] = useState(false);
      const [modalShowEdit, setModalShowEdit] = useState(false);
      
      const handleModalClose = () => setModalShow(false);
      const handleModalShow = () => setModalShow(true);

      const handleModalEditClose = () => setModalShowEdit(false);
      const handleModalEditShow = () => setModalShowEdit(true);

      const init = async () => {
        try {

          console.log("currentApplication", currentApplication);

          if(!currentApplication) {
            return;
          }
  
          console.log("before getApplication", currentApplication);
          const application = await getApplication(currentProject, currentApplication);
          console.log(application.data);
          console.log("after getApplication", currentApplication);
  
          const requirementsList = await getTestcases(currentProject, currentApplication);
          console.log('requirementsList', requirementsList.data);
  
          setFunctionalRequirements(requirementsList.data);
  
          console.log(application.data);
          setApplication(application.data);
          

        } catch (error) {
            //if (error.response.status === 404) navigate(`/${getSiteLanguage()}/not-found`)
            //if (error.response.status === 500) navigate(`/${getSiteLanguage()}/error`)
        }
    }

    const generateTestcases = async () => {
      try {
        console.log("generateTestcases", application);
        let resp = null;
    
        const payload = {...application, number_of_test_cases: numberOfRequirements};

        resp = await createTestCases(currentProject, currentApplication, payload);
    
        console.log(resp);
        toast.success("A request to generate the test cases has been sent to the system.");
        handleModalClose();

        } catch (error) {
          console.log(error);
        }
    };

    useEffect(() => {
        init();
    }, []);

    return (
      <>
       <ApplicationBuildMenu stepCode="test-cases" />

       <div className='container'>
       <div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-8 text-gray-800"> User Acceptance Test Cases</h1>
              
              <Modal show={modalShow} onHide={handleModalClose} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>Generate Testcases</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formImproveField">

              <Form.Label>Number of test cases</Form.Label>
            <Form.Control type="text" placeholder="Data Model Name" value={numberOfRequirements} onChange={(e)=>setNumberOfRequirements(e.target.value)} />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button className="saveBtn" variant="primary" onClick={generateTestcases}>
              Generate!
            </Button>
          </Modal.Footer>
        </Modal>
              {/*}
              <Button variant="primary" onClick={downloadExcel} className="download-btn">
              <i className="fas fa-plus fa-sm text-white-50"></i> Export
              </Button>
    {*/}
             
             
             <div>
             <Button variant="primary" onClick={init} className="addReq-btn">
              <i className="fas fa-sync fa-sm text-white-50"></i> Refresh
              </Button>
              &nbsp;&nbsp;
              <Button variant="primary" onClick={handleModalShow} className="addReq-btn">
              <i className="fas fa-plus fa-sm text-white-50"></i> Generate Testcases
              </Button>
              </div>
          </div>
        <Table striped bordered hover>
    <thead>
      <tr>
        <th>Test case ID</th>
        <th>Name</th>
        <th>Description</th>
        <th>Preconditions</th>
        <th>Steps</th>
        <th>Expected Result</th>
        
      </tr>
    </thead>
    <tbody>
      { functionalRequirements && functionalRequirements.map((requirement, index) => {
        // replace all occurences of ' with "
        
        const tmp = '{"steps": ' + requirement.steps + '}';
        console.log('tmp',tmp)
        const steps = JSON.parse(tmp);
        console.log(steps);
        return (<tr>
          <td>{'TC'+(index+1).toString().padStart(2,'0')}</td>
        <td>{requirement.name}</td>
        <td>{requirement.description}</td>
        <td>{requirement.preconditions}</td>
        <td>
          <ol>
          {steps && steps.steps.map((step) => {
            return (<li>{step}</li>)
          })}
          </ol>
        </td>
        <td>{requirement.expected_result}</td>
       
      </tr>
        )
      })}
    </tbody>
  </Table>
  </div>
      </>  
    );
  };

export default ApplicationMicroservices;
