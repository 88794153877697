import React, { FC, useEffect, useState } from "react";
import "./ApplicationCodeSkeleton.css";
import ApplicationBuildMenu from "../../components/ApplicationBuildMenu/ApplicationBuildMenu";

const ApplicationCodeSkeleton = () => {
  return (
    <>
      <ApplicationBuildMenu stepCode="code-skeleton" />

      <h1>Code Skeleton</h1>
    </>
  );
};

export default ApplicationCodeSkeleton;
