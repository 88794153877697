import React, { FC, useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import {
  getApplication,
  updateApplication,
} from "../../../services/applicationService";
import { regenerateFeatures } from "../../../services/featuresService";
import "./ApplicationQuestions.css";
import useCurrentProject from "../../../components/App/useCurrentProject";
import useCurrentApplication from "../../../components/App/useCurrentApplication";
import { getRequirements } from "../../../services/requirementsService";
import ApplicationBuildMenu from "../../../components/ApplicationBuildMenu/ApplicationBuildMenu";
import { getFeatures, deleteFeature } from "../../../services/featuresService";
import * as XLSX from "xlsx";
import toast, { Toaster } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import ModalConfirm from "../../../components/Utils/ModalConfirm";
import ChatCanvas from "../../../components/Chat/ChatCanvas";
import BaseModal from "../../../components/Utils/BaseModal";
import {
  createQuestion,
  deleteAllQuestions,
  deleteQuestion,
  generateQuestions,
  getQuestions,
  updateQuestion,
} from "../../../services/questionService";
import ApplicationInitiationNav from "./ApplicationInitiationNav";
import { launchAndPollForTermination } from "../../../util/util";

const ApplicationQuestions = () => {
  const { currentProject } = useCurrentProject();
  const { currentApplication } = useCurrentApplication();

  const [application, setApplication] = useState({});
  const [questions, setQuestions] = useState([]);
  const [cards, setCards] = useState({ requirementsList: [] });

  /* Modal confirm variables */
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [modalOnConfirm, setModalOnConfirm] = useState(() => { });

  const chatCanvasRef = useRef(null);
  const modalConfirmRef = useRef(null);
  const addQuestionRef = useRef(null);

  const [question, setFeature] = useState({});

  const handleChange = (name, e) => {
    console.log(name, e.target.value);
    let value = e.target.value;

    setFeature({ ...question, [name]: value });

    console.log("handleChange", question);
  };

  const handleChangeQuestion = (name, e) => {
    console.log(name, e.target.value);
    let value = e.target.value;

    const q = questions.find((q) => q.name === name);
    q.answer = value;

    setQuestions([...questions]);
  };

  const nonFuncRequirementTypes = [
    { value: "maintainability", label: "Maintainability" },
    {
      value: "performance and scalability",
      label: "Performance and Scalability",
    },
    { value: "reliability", label: "Reliability" },
    { value: "security", label: "Security" },
    { value: "technology", label: "Technology" },
    { value: "usability", label: "Usability" },
  ];

  const init = async () => {
    try {
      console.log("currentApplication", currentApplication);

      console.log("before getApplication", currentApplication);
      const application = await getApplication(
        currentProject,
        currentApplication
      );
      console.log(application.data);
      console.log("after getApplication", currentApplication);

      const questionList = await getQuestions(
        currentProject,
        currentApplication
      );
      console.log("requirementsList", questionList.data);

      console.log("FEATURES", questionList.data);
      setQuestions(questionList.data);

      console.log(application.data);
      setApplication(application.data);
    } catch (error) {
      //if (error.response.status === 404) navigate(`/${getSiteLanguage()}/not-found`)
      //if (error.response.status === 500) navigate(`/${getSiteLanguage()}/error`)
    }
  };

  useEffect(() => {
    init();
  }, []);

  /*
  const downloadExcel = () => {
    const wsFunctional = XLSX.utils.json_to_sheet(functionalRequirements);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, wsFunctional, "Functional");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "DataSheet.xlsx");
  };
 
  */

  // Funzione per configurare e aprire la modal
  const openModalConfirm = (title, message, onConfirm) => {
    setModalTitle(title);
    setModalMessage(message);
    setModalOnConfirm(() => onConfirm);

    modalConfirmRef.current.show();
  };

  const confirmGenerateQuestions = () => {
    openModalConfirm(
      "Generate Questions",
      "Are you sure you want to generate the questions?",
      () => handleGenerateQuestions()
    );
  };

  const confirmDeleteQuestion = (questionId, questionName) => {
    openModalConfirm(
      "Delete Question",
      "Are you sure you want to delete the question?",
      () => handleDeleteQuestion(questionId, questionName)
    );
  };

  const handleGenerateQuestions = async () => {
    try {
      console.log("generateQuestions", application);

      let resp = null;

      resp = await generateQuestions(currentProject, currentApplication);

      console.log(resp);

      const toastId = toast.loading("Loading...");

      try {
        let questionsList = null;
        const oldQuestionslistSize = questions.length;

        launchAndPollForTermination(
          async () => {
            questionsList = await getQuestions(
              currentProject,
              currentApplication
            );
            if (
              questionsList &&
              questionsList.data &&
              questionsList.data.length > oldQuestionslistSize
            ) {
              setQuestions(questionsList.data);
              toast.dismiss(toastId);

              toast.success("Requirements are ready.");
            }
          },
          () =>
            questionsList &&
            questionsList.data &&
            questionsList.data.length > oldQuestionslistSize
        );
      } catch (error) {
        toast.dismiss(toastId);

        toast.error("An error occurred while loading the requirements.");

        console.log(error);
      }

      toast.success(
        "A request to generate the questions has been sent to the system."
      );
      modalConfirmRef.current.close();
    } catch (error) {
      console.log(error);
      toast.error("An error occurred while generating the questions.");
    }
  };

  const handleDeleteAllQuestions = async () => {
    try {
      console.log("handleDeleteAllQuestions", application);

      let resp = null;

      resp = await deleteAllQuestions(currentProject, currentApplication);

      console.log(resp);
      toast.success("The questions have been deleted.");
      modalConfirmRef.current.close();

      init();
    } catch (error) {
      console.log(error);
      toast.error("An error occurred while deleting the questions.");
    }
  };

  const saveQuestions = async () => {
    try {
      console.log("saveQuestions", application);

      for (const q of questions) {
        if (q.id) {
          await updateQuestion(currentProject, currentApplication, q.id, q);
        } else {
          await createQuestion(currentProject, currentApplication, {
            ...q,
            application: currentApplication,
          });
        }
      }

      toast.success("The questions have been saved.");
    } catch (error) {
      console.log(error);
      toast.error("An error occurred while saving the questions.");
    }
  };

  const getFeatureClassName = (feature) => {
    if (feature.showDetail) {
      return "fas fa-minus";
    } else {
      return "fas fa-plus";
    }
  };

  const showRequirements = (feature) => {
    console.log("showRequirements", feature);
    for (const f of questions) {
      if (f.id === feature.id) {
        f.showDetail = !f.showDetail;
      } else {
        f.showDetail = false;
      }
    }
    setQuestions([...questions]);
  };

  const handleDeleteQuestion = async (id, name) => {
    try {
      let resp = null;

      if (!id) {
        // delete from array
        const index = questions.findIndex((q) => q.name === name);
        questions.splice(index, 1);
        setQuestions([...questions]);
      } else {
        resp = await deleteQuestion(currentProject, currentApplication, id);
        //IT DELETED ALL THE REQUIREMENTS

        console.log(resp);
      }

      modalConfirmRef.current.close();

      toast.success("The requirement has been deleted.");

      await init();
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenAddQuestion = (feature) => {
    setFeature({});
    addQuestionRef.current.show();
  };

  const handleAddQuestion = async () => {
    // add the current question to the questions array
    const newQuestions = [...questions, question];
    setQuestions(newQuestions);

    addQuestionRef.current.close();

    setTimeout(() => {
      window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
    }, 200);
  };

  const confirmDeleteAllQuestions = () => {
    openModalConfirm(
      "Delete All Questions",
      "Are you sure you want to delete all the questions?",
      () => handleDeleteAllQuestions()
    );
  };

  return (
    <>
      <ApplicationBuildMenu stepCode="initiation" />

      <ChatCanvas ref={chatCanvasRef} />

      <ModalConfirm
        title={modalTitle}
        modalMessage={modalMessage}
        onConfirm={modalOnConfirm}
        ref={modalConfirmRef}
      />

      <BaseModal
        title="Add Question"
        content={
          <Form>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter a question name"
                value={question.name}
                onChange={(e) => handleChange("name", e)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formDescription">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                rows={3}
                placeholder="Enter a description"
                value={question.question}
                onChange={(e) => handleChange("question", e)}
              />
            </Form.Group>
          </Form>
        }
        buttons={
          <>
            <Button
              className="cancelButton"
              variant="primary"
              onClick={() => addQuestionRef.current.close()}
            >
              Cancel
            </Button>
            <Button
              className="saveBtn"
              variant="primary"
              onClick={() => handleAddQuestion()}
            >
              Ok
            </Button>
          </>
        }
        ref={addQuestionRef}
      />

      <div className="container">
        <ApplicationInitiationNav currentTab="questions" />

        <div className="d-sm-flex align-items-center justify-content-between mb-4 ">
          <h1 className="h3 mb-8 text-gray-800"> Questions</h1>
          <div>
            {/* <Button variant="primary" onClick={init} className="addReq-btn">
<i className="fas fa-sync fa-sm text-white-50"></i> Refresh
</Button> */}
            &nbsp;&nbsp;
            <Button
              variant="primary"
              onClick={saveQuestions}
              className="addReq-btn"
            >
              <i className="fas fa-sync fa-sm text-white-50"></i> Save
            </Button>
            &nbsp;&nbsp;
            <Button
              variant="primary"
              onClick={confirmDeleteAllQuestions}
              className="addReq-btn"
            >
              <i className="fas fa-plus fa-sm text-white-50"></i> Delete all
            </Button>{" "}
            &nbsp;
            <Button
              variant="primary"
              onClick={confirmGenerateQuestions}
              className="addReq-btn"
            >
              <i className="fas fa-plus fa-sm text-white-50"></i> Generate
            </Button>{" "}
            &nbsp;
            <Button
              variant="primary"
              onClick={() => handleOpenAddQuestion(null)}
              className="addReq-btn"
            >
              <i className="fas fa-plus fa-sm text-white-50"></i> Add
            </Button>
            &nbsp;&nbsp;
            <Button
              className="saveBtn chatButton"
              variant="primary"
              onClick={() => chatCanvasRef.current.show()}
            >
              <i class="fas fa-comments"></i>
            </Button>
          </div>
        </div>

        <Container>
          <Form>
            {questions &&
              questions.map((q, index) => {
                return (
                  <>
                    <Row>
                      <Col xs={11}>
                        <Form.Group
                          className="mb-3"
                          controlId="formProjectDescription"
                        >
                          <Form.Label>{q.name}</Form.Label>
                          <p>{q.question}</p>
                          <Form.Control
                            type="text"
                            as="textarea"
                            rows={3}
                            placeholder=""
                            value={q.answer}
                            onChange={(e) => handleChangeQuestion(q.name, e)}
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <div className="">
                          <a
                            href="#"
                            onClick={() => confirmDeleteQuestion(q.id, q.name)}
                            style={{ textDecoration: "none", color: "black" }}
                          >
                            <i className="fas fa-trash"></i>
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </>
                );
              })}
          </Form>
        </Container>
      </div>
    </>
  );
};

export default ApplicationQuestions;