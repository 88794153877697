import React, { useState } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import ChatWithRobot from './ChatWithRobot';
import './ChatWithRobotButton.css'; // This should contain the CSS for the button positioning

const ChatWithRobotButton = ({ fetchChatHistory, sendChatbotQuestion }) => {
    const [showChat, setShowChat] = useState(false);

    const toggleChat = () => setShowChat(!showChat);

    return (
        <>
            <Button
                className="chatButton1 commentIcon"
                variant="primary"
                onClick={toggleChat}
            >
                <i className="fas fa-comments "></i>
            </Button>

            {showChat && (
                <div className="chat-window">
                    <div className='header-container'>
                        <div className='commentIcon'>
                            <i className="fas fa-comments fa-3x"></i> {/* fa-3x for 3 times the size of the icon */}
                        </div>
                        <div className='header-text'>
                            <h4>CMA</h4>
                            <p>Reply to message</p>
                        </div>
                        <button className='clockBtn' onClick={() => console.log(fetchChatHistory)}>
                            <img src="../../../Images/Clock.svg" alt="Clock" style={{ width: '3.3rem' }} />
                        </button>

                    </div>

                    <hr />

                    <div style={{height:"430px"}}>
                        <ChatWithRobot
                            fetchChatHistory={fetchChatHistory} // Pass fetch chat history function as prop
                            sendChatbotQuestion={sendChatbotQuestion} // Pass send chat question function as prop
                            redirectable={false}
                            regen={false}
                        />
                    </div>
                </div >

            )}
        </>
    );

};

export default ChatWithRobotButton;
