import axios from "axios";
import { API } from './api'
import yaml from 'js-yaml';

/**
 * Returns the list of applications defined for the given project
 * 
 * @param {number} projectId
 * @returns {Promise<AxiosResponse<any>>}
 */
const getApplications = async (projectId) => API().get(`/project/${projectId}/application/`);

/**
 * Returns the application with the given id
 * 
 * @param {number} projectId
 * @param {number} id
 * @returns {Promise<AxiosResponse<any>>}
 */
const getApplication = async (projectId, id) => API().get(`/project/${projectId}/application/${id}/`);

/**
 * Requests the backend to improve the field, specified in the payload, of the application with the given id
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @param {*} payload 
 * @returns 
 */
const fillImproveApplicationField = async (projectId, applicationId, payload) => API().post(`/project/${projectId}/application/${applicationId}/fill/`, payload);

/**
 * Creates the application with the given payload and returns the created application
 * 
 * @param {number} projectId
 * @param {*} payload
 * @returns {Promise<AxiosResponse<any>>}
 */
const createApplication = async (projectId, payload) => API().post(`/project/${projectId}/application/`, payload);

/**
 * Updates the application with the given id and returns the updated application
 * 
 * @param {*} projectId 
 * @param {*} id 
 * @param {*} payload 
 * @returns 
 */
const updateApplication = async (projectId, id, payload) => API().put(`/project/${projectId}/application/${id}/`, payload);

export {
    getApplications,
    getApplication,
    createApplication,
    updateApplication,
    fillImproveApplicationField,
};