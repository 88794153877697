import React, { useState, useEffect } from 'react';
import { Offcanvas, Form, Button } from 'react-bootstrap';
import './textOffCanvas.css';

// OffCanvasForm.js

// ... (import statements)

const OffCanvasForm = ({ show, setShow, header, initialValue, confirmFunction, showCancelButton }) => {
    const [value, setValue] = useState('');
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
        // Reset the input value when the offcanvas is closed
        if (!show) {
            setValue('');
            setIsFocused(false);
        }
    }, [show]);

    const handleConfirm = () => {
        confirmFunction(value);
        handleClose();
    };

    const handleClose = () => {
        setShow(false);
    };

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        if (value === '') {
            setIsFocused(false);
        }
    };

    return (
        <Offcanvas className='mainCanvas' style={{ width: '22rem' }} show={show} onHide={handleClose} placement="end">
            <Offcanvas.Header className='headerStyle text-center mt-5 '>
                <Offcanvas.Title>{header}</Offcanvas.Title>
            </Offcanvas.Header>

            <Offcanvas.Body className="d-flex flex-column align-items-center justify-content-end">
                <Form className="text-center align-items-start">
                    <Form.Group className="mb-3">
                        <Form.Control
                            type="text"
                            as="textarea"
                            rows={13}
                            value={isFocused ? value : ''}
                            onChange={(e) => setValue(e.target.value)}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                            placeholder={isFocused ? '' : initialValue}
                        />
                    </Form.Group>
                    {showCancelButton && (
                        <Button variant="secondary" onClick={handleClose} className="mb-2">
                            Close
                        </Button>
                    )}{" "}
                    <Button className='sumbitBtn' variant="primary" onClick={handleConfirm}>
                        Sumbit
                    </Button>
                </Form>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default OffCanvasForm;
