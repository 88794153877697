import React, { useState, useEffect } from "react";
import "./UploadPage.css";
import {
  Button,
  Container,
  Table,
  Modal,
  Form,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import { getFiles, getFile, uploadFile } from "../../services/uploadService";
import useCurrentProject from "../../components/App/useCurrentProject";
import UploadForm from "../../components/UploadForm/UploadForm";
import toast from "react-hot-toast";
import uploadData from "./uploadData.json";

export default function UploadPage() {
  const { currentProject } = useCurrentProject();
  const [files, setFiles] = useState([]);
  const [showTable, setShowTable] = useState(false);
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [architectureType, setArchitectureType] = useState("Architecture Type");
  const [principalTopic, setPrincipalTopic] = useState("Principal Topic");
  const [fileDetails, setFileDetails] = useState({
    document_name: "",
    document_desc: "",
    architecture_type: "",
    principal_topic: "",
  });
  const [file,setFile] = useState()
  const [disableUploadButton,setDisableUploadButton] = useState(false)

  const handleChange = (field, e) => {
    const updatedFileDetails = {
      ...fileDetails,
      [field]: e.target.value,
    };
    setFileDetails(updatedFileDetails);
  };

  useEffect(() => {
    fetchFiles();
  }, []);

  const fetchFiles = async () => {
    try {
      const response = await getFiles(currentProject);
      setFiles(response.data.doc_list);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  const handleClick = async () => {
    setShowTable(!showTable);
  };

  const handleUpload = async () => {
    if (
      !fileDetails.document_name ||
      !fileDetails.document_desc ||
      fileDetails.architecture_type === "Architecture Type" ||
      fileDetails.principal_topic === "Principal Topic"
    ) {
      toast.error("Please fill in all the fields.");
      return;
    }

    file.append("document_name", fileDetails.document_name);
    file.append("document_desc", fileDetails.document_desc);
    file.append("architecture_type", fileDetails.architecture_type);
    file.append("principal_topic", fileDetails.principal_topic);
    console.log(file)
    setDisableUploadButton(true)
    try {
      await uploadFile(currentProject, file)
      toast.success("file uploaded succesfully");
      fetchFiles();
      setShowUploadFileModal(false)
    } catch (error) {
      console.log(error);
      toast.error("an error occurred while uploading the file");
    }
    finally{
      setDisableUploadButton(false)
    }
  };

  const handleArchitectureSelect = (architecture) => {
    const updatedFileDetails = {
      ...fileDetails,
      architecture_type: architecture,
    };
    setFileDetails(updatedFileDetails);
    setArchitectureType(architecture);
  };

  const handleTopicSelect = (topic) => {
    const updatedFileDetails = {
      ...fileDetails,
      principal_topic: topic,
    };
    setFileDetails(updatedFileDetails);
    setPrincipalTopic(topic);
  };

  return (
    <div>
      <div className="uploadPageContent">
        <Button onClick={handleClick} className="showFilesButton">
          {showTable ? "Hide" : "Show"} Uploaded Files
        </Button>
        <Button
          onClick={() => {
            setShowUploadFileModal(true);
          }}
          className="showFilesButton"
        >
          Upload File{" "}
        </Button>
      </div>
      {showTable && (
        <Container>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th className="col-md-0">#</th>
                <th>Document name</th>
                <th>Document description</th>
                <th>Architecture type</th>
                <th>Principal topic</th>
              </tr>
            </thead>
            <tbody>
              {files.map((file, index) => {
                return (
                  <tr key={file.id || index} className="tableRows">
                    <td>{index + 1}</td>
                    <td>
                      <a
                        href={file.document_path}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {file.document_name}
                      </a>
                    </td>
                    <td>{file.document_desc}</td>
                    <td>{file.architecture_type}</td>
                    <td>{file.principal_topic}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Container>
      )}
      <Modal
        show={showUploadFileModal}
        onHide={() => setShowUploadFileModal(false)}
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#046a38" }}>FILE UPLOAD</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="documentName" className="formGroup">
            <Form.Label className="formLabel">Document Name: </Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Documents' name"
              className="formInput"
              value={fileDetails.document_name}
              onChange={(e) => handleChange("document_name", e)}
            />
          </Form.Group>
          <Form.Group controlId="documentName" className="formGroup">
            <Form.Label className="formLabel">
              Document Description:{" "}
            </Form.Label>
            <Form.Control
              as="textarea"
              rows={2}
              type="text"
              placeholder="Brief description of the document"
              className="formInput"
              value={fileDetails.document_desc}
              onChange={(e) => handleChange("document_desc", e)}
            />
          </Form.Group>
          <div
            style={{
              display: "flex",
              marginBottom: "30px",
              justifyContent: "space-evenly",
            }}
          >
            <DropdownButton
              id="architecture-dropdown"
              title={architectureType}
              className="dropdownButton"
            >
              {uploadData.architectureTypes.map((type, index) => (
                <Dropdown.Item
                  key={index}
                  as="button"
                  onClick={() => handleArchitectureSelect(type)}
                >
                  {type}
                </Dropdown.Item>
              ))}
            </DropdownButton>
            <DropdownButton id="topic-dropdown" title={principalTopic}>
              {uploadData.principalTopics.map((topic, index) => (
                <Dropdown.Item
                  key={index}
                  as="button"
                  onClick={() => handleTopicSelect(topic)}
                >
                  {topic}
                </Dropdown.Item>
              ))}
            </DropdownButton>
          </div>
          <UploadForm confirmUpload={false} handleUpload={(formData)=>setFile(formData)} />
        </Modal.Body>
        <Modal.Footer className="deleteConfirmFooter">
          <Button
            className="cancel-btn"
            variant="secondary"
            onClick={() => setShowUploadFileModal(false)}
          >
            Cancel
          </Button>
          <Button onClick={handleUpload} className="upload-file-button" disabled={disableUploadButton} >
            Upload
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
