import axios from "axios";
import { API } from './api'
import yaml from 'js-yaml';


/**
 * Returns the list of projects defined in the system
 * 
 * @returns {Promise<AxiosResponse<any>>}
 */
const getProjects = async () => API().get(`/project/`);

/**
 * Returns the project with the given id
 * 
 * @param id
 * @returns {Promise<AxiosResponse<any>>}
 */
const getProject = async (id) => API().get(`/project/${id}/`);

/**
 * Updates the project with the given id and returns the updated project
 * 
 * @param {number} id 
 * @param {string} payload 
 * @returns {Promise<AxiosResponse<any>>}
 */
const updateProject = async (id, payload) => API().put(`/project/${id}/`, payload);

/**
 * Creates the project with the given payload and returns the created project
 * 
 * @param {*} payload 
 * @returns {Promise<AxiosResponse<any>>}
 */
const createProject = async (payload) => API().post(`/project/`, payload);

const deleteProject = async (id) => API().delete(`/project/${id}/`);
 

export {
    getProjects,
    getProject,
    updateProject,
    deleteProject,
    createProject,
};