
// launches the "callback" function starting a loop and stopping when the "checkStatusCallback" returns true
// there is a timeout of "timeoutMilliseconds" milliseconds, if the timeout is reached, the loop is stopped
// if the timeout is zero, the loop is never stopped
const launchAndPollForTermination = async (callback, checkStatusCallback, pollingIntervalSeconds = 15, timeoutSeconds = 300) => {
    try {
      let startTime = Date.now();
      let taskComplete = false;

      const pollingIntervalMilliseconds = pollingIntervalSeconds * 1000;
        const timeoutMilliseconds = timeoutSeconds * 1000;

      while (!taskComplete && (!timeoutMilliseconds || (Date.now() - startTime) < timeoutMilliseconds)) {

        await callback();
        if (!checkStatusCallback()) {
          // The loop is not yet complete, continue polling
          await new Promise(resolve => setTimeout(resolve, pollingIntervalMilliseconds));
        } else {
          // Stop polling
          taskComplete = true;
        }
      }

      if (!taskComplete) {
        throw new Error(`Timeout of ${timeoutMilliseconds} milliseconds reached`);
      }
      
    } catch (error) {
      console.log(error);

      throw error;
    }
  };

export {
    launchAndPollForTermination,
};