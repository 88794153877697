import React, { FC, useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import {
  getApplication,
  updateApplication,
} from "../../../services/applicationService";
import { regenerateFeatures } from "../../../services/featuresService";
import "./ApplicationTransitionRequirements.css";
import useCurrentProject from "../../../components/App/useCurrentProject";
import useCurrentApplication from "../../../components/App/useCurrentApplication";
import { getRequirements } from "../../../services/requirementsService";
import ApplicationBuildMenu from "../../../components/ApplicationBuildMenu/ApplicationBuildMenu";
import { getFeatures, deleteFeature } from "../../../services/featuresService";
import * as XLSX from "xlsx";
import toast, { Toaster } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import ModalConfirm from "../../../components/Utils/ModalConfirm";
import ChatCanvas from "../../../components/Chat/ChatCanvas";
import BaseModal from "../../../components/Utils/BaseModal";
import {
  createTransitionRequirement,
  deleteAllTransitionRequirements,
  deleteTransitionRequirement,
  generateTransitionRequirements,
  getTransitionRequirements,
  regenerateTransitionRequirement,
  updateTransitionRequirement,
} from "../../../services/transitionService";
import { launchAndPollForTermination } from "../../../util/util";

const ApplicationTransitionRequirements = () => {
  const { currentProject } = useCurrentProject();
  const { currentApplication } = useCurrentApplication();

  const [application, setApplication] = useState({});
  const [functionalRequirements, setFunctionalRequirements] = useState([]);
  const [allFunctionalRequirements, setAllFunctionalRequirements] = useState(
    []
  );
  const [cards, setCards] = useState({ requirementsList: [] });

  /* Modal confirm variables */
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [modalOnConfirm, setModalOnConfirm] = useState(() => { });

  const chatCanvasRef = useRef(null);
  const modalConfirmRef = useRef(null);
  const editFeatureRef = useRef(null);

  const [feature, setFeature] = useState({});

  const handleChange = (name, e) => {
    console.log(name, e.target.value);
    let value = e.target.value;

    setFeature({ ...feature, [name]: value });

    console.log("handleChange", feature);
  };

  const nonFuncRequirementTypes = [
    { value: "maintainability", label: "Maintainability" },
    {
      value: "performance and scalability",
      label: "Performance and Scalability",
    },
    { value: "reliability", label: "Reliability" },
    { value: "security", label: "Security" },
    { value: "technology", label: "Technology" },
    { value: "usability", label: "Usability" },
  ];

  const init = async () => {
    try {
      console.log("currentApplication", currentApplication);

      console.log("before getApplication", currentApplication);
      const application = await getApplication(
        currentProject,
        currentApplication
      );
      console.log(application.data);
      console.log("after getApplication", currentApplication);

      const featureList = await getTransitionRequirements(
        currentProject,
        currentApplication
      );
      console.log("requirementsList", featureList.data);

      console.log("FEATURES", featureList.data);
      setFunctionalRequirements(featureList.data);
      setAllFunctionalRequirements(featureList.data);

      console.log(application.data);
      setApplication(application.data);
    } catch (error) {
      //if (error.response.status === 404) navigate(`/${getSiteLanguage()}/not-found`)
      //if (error.response.status === 500) navigate(`/${getSiteLanguage()}/error`)
    }
  };

  useEffect(() => {
    init();
  }, []);

  /*
  const downloadExcel = () => {
    const wsFunctional = XLSX.utils.json_to_sheet(functionalRequirements);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, wsFunctional, "Functional");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "DataSheet.xlsx");
  };
 
  */

  // Funzione per configurare e aprire la modal
  const openModalConfirm = (title, message, onConfirm) => {
    setModalTitle(title);
    setModalMessage(message);
    setModalOnConfirm(() => onConfirm);

    modalConfirmRef.current.show();
  };

  const confirmGenerateFeatures = () => {
    openModalConfirm(
      "Generate Non Functional Requirements",
      "Are you sure you want to generate the non functional requirements?",
      () => handleGenerateFeatures()
    );
  };

  const confirmDeleteFeature = (featureId) => {
    openModalConfirm(
      "Delete Feature",
      "Are you sure you want to delete the feature?",
      () => handleDeleteFeature(featureId)
    );
  };

  const confirmRegenerate = (feature) => {
    openModalConfirm(
      "Delete Feature",
      "Are you sure you want to regenerate the feature?",
      () => handleRegenerate(feature)
    );
  };

  const handleGenerateFeatures = async () => {
    try {
      console.log("generateRequirements", application);
      let resp = null;

      resp = await generateTransitionRequirements(
        currentProject,
        currentApplication,
        {
          non_functional_requirements_types: nonFuncRequirementTypes.map(
            (type) => type.value
          ),
        }
      );

      const toastId = toast.loading("Loading...");

      try {
        let transitionRequirementsList = null;
        const oldTransitionRequirementsListSize = functionalRequirements.length;

        launchAndPollForTermination(
          async () => {
            transitionRequirementsList = await getTransitionRequirements(
              currentProject,
              currentApplication
            );
            if (
              transitionRequirementsList &&
              transitionRequirementsList.data &&
              transitionRequirementsList.data.length >
              oldTransitionRequirementsListSize
            ) {
              setFunctionalRequirements(transitionRequirementsList.data);

              toast.dismiss(toastId);

              toast.success("Requirements are ready.");
            }
          },
          () =>
            transitionRequirementsList &&
            transitionRequirementsList.data &&
            transitionRequirementsList.data.length >
            oldTransitionRequirementsListSize
        );
      } catch (error) {
        toast.dismiss(toastId);

        toast.error("An error occurred while loading the requirements.");

        console.log(error);
      }

      console.log(resp);
      toast.success(
        "A request to generate the requirements has been sent to the system."
      );
      modalConfirmRef.current.close();
    } catch (error) {
      console.log(error);
    }
  };

  const getFeatureClassName = (feature) => {
    if (feature.showDetail) {
      return "fas fa-minus";
    } else {
      return "fas fa-plus";
    }
  };

  const showRequirements = (feature) => {
    console.log("showRequirements", feature);
    for (const f of functionalRequirements) {
      if (f.id === feature.id) {
        f.showDetail = !f.showDetail;
      } else {
        f.showDetail = false;
      }
    }
    setFunctionalRequirements([...functionalRequirements]);
  };

  const filterByType = (type) => {
    try {
      console.log("filterByType", type);

      console.log("allFunctionalRequirements", allFunctionalRequirements);
      setFunctionalRequirements(
        allFunctionalRequirements.filter(
          (feature) => type === "all" || feature.type === type
        )
      );
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteFeature = async (id) => {
    try {
      let resp = null;

      resp = await deleteTransitionRequirement(
        currentProject,
        currentApplication,
        id
      );
      //IT DELETED ALL THE REQUIREMENTS

      modalConfirmRef.current.close();

      console.log(resp);
      toast.success("The requirement has been deleted.");

      await init();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteAll = async () => {
    try {
      await deleteAllTransitionRequirements(currentProject, currentApplication);
      modalConfirmRef.current.close();
      init();
      toast.success("Deleted Successfully");
    } catch (error) {
      console.log(error);
      toast.error("Error occured while trying to delete alll");
    }
  };

  const confirmDeleteAll = () => {
    openModalConfirm(
      "DELETE ALL",
      "Are you sure you want to delete all requirements?",
      () => handleDeleteAll()
    );
  };

  const handleRegenerate = async (feature) => {
    try {
      console.log("regenerateFeature", feature);
      let resp = null;
      resp = await regenerateTransitionRequirement(
        currentProject,
        currentApplication,
        feature.id
      );
      console.log(resp);
      toast.success(
        "A request to regenerate the requirement has been sent to the system."
      );

      modalConfirmRef.current.close();
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenEditFeature = (feature) => {
    setFeature(feature ? feature : {});
    editFeatureRef.current.show();
  };

  const handleSaveFeature = async () => {
    try {
      console.log("saveFeature", feature);
      let resp = null;

      let payload = { ...feature };
      delete payload.list_of_generated_reports;

      if (feature.id == null) {
        console.log("createFeature", currentProject, payload);
        payload = { ...payload, application: currentApplication };
        resp = await createTransitionRequirement(
          currentProject,
          currentApplication,
          payload
        );

        toast.success("The feature has been saved.");
      } else {
        resp = await updateTransitionRequirement(
          currentProject,
          currentApplication,
          feature.id,
          payload
        );
        toast.success("The feature has been saved.");
      }

      editFeatureRef.current.close();

      await init();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <ApplicationBuildMenu stepCode="transition" />

      <ChatCanvas ref={chatCanvasRef} />

      <ModalConfirm
        title={modalTitle}
        modalMessage={modalMessage}
        onConfirm={modalOnConfirm}
        ref={modalConfirmRef}
      />

      <BaseModal
        title="Edit Requirement"
        content={
          <Form>
            <Form.Group className="mb-3" controlId="formProjectEmail">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter a project name"
                value={feature.name}
                onChange={(e) => handleChange("name", e)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formProjectEmail">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                as="textarea"
                rows={3}
                placeholder="Enter a project name"
                value={feature.description}
                onChange={(e) => handleChange("description", e)}
              />
            </Form.Group>
          </Form>
        }
        buttons={
          <>
            <Button
              className="cancelButton"
              variant="primary"
              onClick={() => editFeatureRef.current.close()}
            >
              Cancel
            </Button>
            <Button
              className="saveBtn"
              variant="primary"
              onClick={() => handleSaveFeature()}
            >
              Ok
            </Button>
          </>
        }
        ref={editFeatureRef}
      />

      <div className="container">
        <div className="d-sm-flex align-items-center justify-content-between mb-4 ">
          <h1 className="h3 mb-8 text-gray-800">Transition Requirements</h1>
          <div>
            {/* <Button variant="primary" onClick={init} className="addReq-btn">
<i className="fas fa-sync fa-sm text-white-50"></i> Refresh
</Button> */}
            <Button
              variant="primary"
              onClick={confirmDeleteAll}
              className="addReq-btn"
            >
              <i className="fas fa-minus fa-sm text-white-50"></i> Delete All
            </Button>
            &nbsp;&nbsp;
            <Button
              variant="primary"
              onClick={confirmGenerateFeatures}
              className="addReq-btn"
            >
              <i className="fas fa-plus fa-sm text-white-50"></i> Generate
            </Button>{" "}
            &nbsp;
            <Button onClick={() => handleOpenEditFeature(null)} className="saveBtn">
              <i className="fas fa-plus fa-sm text-white-50"></i> Add
            </Button>
            &nbsp;&nbsp;
            <Button
              className="saveBtn chatButton"
              variant="primary"
              onClick={() => chatCanvasRef.current.show()}
            >
              <i class="fas fa-comments"></i>
            </Button>
          </div>
        </div>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Description</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {functionalRequirements &&
              functionalRequirements.map((feature, index) => {
                return (
                  <>
                    <tr>
                      <td>{"TR" + (index + 1).toString().padStart(2, "0")}</td>
                      <td>{feature.name}</td>
                      <td>{feature.description}</td>
                      <td>
                        <div className="d-flex">
                          {/* FIXME< the inline style */}

                          <div>
                            <a
                              href="#"
                              onClick={() => confirmRegenerate(feature)}
                              style={{ textDecoration: "none", color: "black" }}
                            >
                              <i className="fas fa-sync"></i>
                            </a>
                            <a
                              href="#"
                              onClick={() => handleOpenEditFeature(feature)}
                              style={{ textDecoration: "none", color: "black" }}
                            >
                              <i className="fas fa-edit"></i>
                            </a>

                            <a
                              href="#"
                              onClick={() => confirmDeleteFeature(feature.id)}
                              style={{ textDecoration: "none", color: "black" }}
                            >
                              <i className="fas fa-trash"></i>
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </>
                );
              })}
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default ApplicationTransitionRequirements;