import { useState } from 'react'
import OpenTextBox from '../../OpenTextBox/OpenTextBox'
import SimpleTable from '../../Tables/SimpleTable'
import Breadcrumbs from '../../Breadcrumbs/Breadcrumbs'
import InformationWithColumns from '../../InformationWithColumns/InformationWithColumns'
import SelectedBar from '../../Bars/SelectedBar'
import GeneralInformationContent from '../../GeneralInformation/GeneralInformationContent'
import TechnologyStack from '../../TechnologyStack/TechnologyStack'
import PointsInformation from '../../PointsInformation/PointsInformation'
import "./ApplicationAssessment.css"

const ApplicationAssessment = ({
    summary,
    setSummary,
    keyFindings,
    setPoints,
    applicationDetails,
    applications,
    deleteContextFinding,
    updateKeyFinding,
    update
}) => {
    const [selectedApplication, setSelectedApplication] = useState()

    // const applicationDetails = [
    //     {
    //         title: "Application 1",
    //         description: "this is a description",
    //         applicationCriticality: "High",
    //         criticality: "Important",
    //         quality: "Well Developed",
    //         information: {
    //             architectureType: "Monolithic",
    //             availableEnviroments: "Production,System,testing",
    //             prodServers: "16",
    //             customizationDegree: "Custom",
    //             architectureType: "Financial Accounting",
    //         },
    //         stack: {
    //             operatingSystem: ["Windows Server 2019"],
    //             levelOfSupport: ["Fully Supported"],
    //             middleware: [
    //                 "Apache Tomcat 10.0.16",
    //                 "Nginx 0.7.69"
    //             ],
    //             levelOfSupport2: [
    //                 "Fully Supported",
    //                 "Out of Support"
    //             ],
    //             runtime: ["Java"],
    //             levelOfSupport3: ["Fully Supported"]
    //         },
    //         itegration: {
    //             internal: ["app1", "app2", "app3"],
    //             external: ["app1", "app2", "app3"],
    //             numberOfIntegration: 3
    //         }
    //     }, {
    //         title: "Application 2",
    //         description: "this is a description",
    //         applicationCriticality: "High",
    //         criticality: "Important",
    //         quality: "Well Developed",
    //         information: {
    //             architectureType: "Monolithic",
    //             availableEnviroments: "Production,System,testing",
    //             prodServers: "16",
    //             customizationDegree: "Custom",
    //             architectureType: "Financial Accounting",
    //         },
    //         stack: {
    //             operatingSystem: ["Windows Server 2019"],
    //             levelOfSupport: ["Fully Supported"],
    //             middleware: [
    //                 "Apache Tomcat 10.0.16",
    //                 "Nginx 0.7.69"
    //             ],
    //             levelOfSupport2: [
    //                 "Fully Supported",
    //                 "Out of Support"
    //             ],
    //             runtime: ["Java"],
    //             levelOfSupport3: ["Fully Supported"]
    //         },
    //         itegration: {
    //             internal: ["app1", "app2", "app3"],
    //             external: ["app1", "app2", "app3"],
    //             numberOfIntegration: 3
    //         }
    //     }, {
    //         title: "Application 3",
    //         description: "this is a description",
    //         applicationCriticality: "High",
    //         criticality: "Important",
    //         quality: "Well Developed",
    //         information: {
    //             architectureType: "Monolithic",
    //             availableEnviroments: "Production,System,testing",
    //             prodServers: "16",
    //             customizationDegree: "Custom",
    //             architectureType: "Financial Accounting",
    //         },
    //         stack: {
    //             operatingSystem: ["Windows Server 2019"],
    //             levelOfSupport: ["Fully Supported"],
    //             middleware: [
    //                 "Apache Tomcat 10.0.16",
    //                 "Nginx 0.7.69"
    //             ],
    //             levelOfSupport2: [
    //                 "Fully Supported",
    //                 "Out of Support"
    //             ],
    //             runtime: ["Java"],
    //             levelOfSupport3: ["Fully Supported"]
    //         },
    //         itegration: {
    //             internal: ["app1", "app2", "app3"],
    //             external: ["app1", "app2", "app3"],
    //             numberOfIntegration: 3
    //         }
    //     }, {
    //         title: "Application 4",
    //         description: "this is a description",
    //         applicationCriticality: "High",
    //         criticality: "Important",
    //         quality: "Well Developed",
    //         information: {
    //             architectureType: "Monolithic",
    //             availableEnviroments: "Production,System,testing",
    //             prodServers: "16",
    //             customizationDegree: "Custom",
    //             architectureType: "Financial Accounting",
    //         },
    //         stack: {
    //             operatingSystem: ["Windows Server 2019"],
    //             levelOfSupport: ["Fully Supported"],
    //             middleware: [
    //                 "Apache Tomcat 10.0.16",
    //                 "Nginx 0.7.69"
    //             ],
    //             levelOfSupport2: [
    //                 "Fully Supported",
    //                 "Out of Support"
    //             ],
    //             runtime: ["Java"],
    //             levelOfSupport3: ["Fully Supported"]
    //         },
    //         itegration: {
    //             internal: ["app1", "app2", "app3"],
    //             external: ["app1", "app2", "app3"],
    //             numberOfIntegration: 3
    //         }
    //     }
    // ]

    return (
        <div>
            <OpenTextBox
                title="Summary of Application Assessment"
                value={summary}
                setValue={setSummary}
                updateButton={update}
            />
            <PointsInformation
                title="Key Findings"
                points={keyFindings}
                setPoints={setPoints}
                deleteContextFinding={deleteContextFinding}
                updateKeyFinding={updateKeyFinding}

            />
            <div className='upload-form-wrapper' style={{ width: "95%" }}>

                <div className='upload-form-title-wrapper'>
                    {selectedApplication == null ?
                        <h1>Application Details</h1>
                        :
                        <div>
                            <Breadcrumbs
                                body={["Application Details"]}
                                leaf={applicationDetails[selectedApplication].title}
                                handleBodyClick={() => setSelectedApplication(null)}
                            />
                            {applicationDetails[selectedApplication].title}
                        </div>
                    }
                </div>
                <div className={`body-wrapper`}>
                    {selectedApplication == null ? <><p>Click on the chosen application to view the Application Summary Card and details about the identified migration strategy​</p>
                        <SimpleTable
                            headers={["APPLICATION", "DESCRIPTION", "APP OWNER", "NUMBER OF USERS"]}
                            data={applications}
                            selectable={true}
                            selectRowFunc={(data, index) => { console.log(index); setSelectedApplication(index) }}

                        /></> :
                        <div className='application-details-wrapper'>
                            {/* <div>
                                    <h2>Brief description</h2>
                                    <p>{applicationDetails[selectedApplication].description}</p>
                                </div>
                                <div>
                                    <h2>Integration</h2>
                                    <p>{applicationDetails[selectedApplication].integrationAnalysis}</p>
                                </div> */}
                            <GeneralInformationContent
                                title={"General Information"}
                                data={applicationDetails[selectedApplication].information}
                            />
                            {/* <InformationWithColumns
                                title="Integration"
                                pillTitle="Number of Integrations"
                                pillContent="3"
                                leftColumnTitle="Internal Integrations"
                                leftColumnContent={["Appliation 1", "Application 2", "Application 3"]}
                                rightColumnTitle="External Integrations"
                                rightColumnContent={["Appliation 1", "Application 2"]}
                            /> */}
                            <SelectedBar
                                title={"Criticality in managing data and traffic"}
                                options={["high", "medium", "low"]}
                                selected={applicationDetails[selectedApplication].criticality}
                                text={applicationDetails[selectedApplication].criticalityText}

                            />
                            <SelectedBar
                                title={"Stack"}
                                options={["high", "medium", "low"]}
                                selected={applicationDetails[selectedApplication].stack}
                                text={applicationDetails[selectedApplication].stackText}
                            />
                            <SelectedBar
                                title={"Application Criticality"}
                                options={["Mission Critical", "Business Critical"]}
                                selected={applicationDetails[selectedApplication].applicationCriticality}
                                text={applicationDetails[selectedApplication].applicationCriticalityText}

                            />
                            <SelectedBar
                                title={"Code and software architecture quality"}
                                options={["high", "medium", "low"]}
                                selected={applicationDetails[selectedApplication].quality}
                                text={applicationDetails[selectedApplication].qualityText}
                            />
                            <SelectedBar
                                title={"Migration Strategy"}
                                options={["reHost", "rePlatform", "repurchase", "retain", "retire", "reFactor"]}
                                selected={applicationDetails[selectedApplication].migrationStrategy}
                                text={applicationDetails[selectedApplication].migrationStrategyText}
                            />
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default ApplicationAssessment