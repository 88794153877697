import { useState } from 'react';

export default function useToken() {
  const getToken = () => {
    const tokenString = sessionStorage.getItem('token');
    if(!tokenString) return null;

    const userToken = JSON.parse(tokenString);

    return userToken?.access
  };

  const [token, setToken] = useState(getToken());

  const saveToken = (userToken) => {
    console.log('userToken', userToken);
    sessionStorage.setItem('token', JSON.stringify(userToken));
    setToken(userToken? userToken.access : null);
  };

  return {
    setToken: saveToken,
    token
  }
}
