import React, { FC, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link, useParams } from "react-router-dom";
import useCurrentApplication from '../../../components/App/useCurrentApplication';
import useCurrentProject from '../../../components/App/useCurrentProject';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Modal from 'react-bootstrap/Modal';
import toast, { Toaster } from "react-hot-toast";
import Chat from '../../../components/Chat/Chat';
import ApplicationBuildMenu from '../../../components/ApplicationBuildMenu/ApplicationBuildMenu';
import { useNavigate } from 'react-router-dom';
import { getApplication, createApplication, updateApplication, fillImproveApplicationField } from '../../../services/applicationService';
import { getOverview, updateOverview, generateOverview, getScope, updateScope, generateScope } from '../../../services/overviewScopeService';
import { Tab, Tabs } from 'react-bootstrap';
import { launchAndPollForTermination } from '../../../util/util';
import './ApplicationOverview.css';

function ApplicationOverview() {
  const navigate = useNavigate();
  const { currentProject } = useCurrentProject();
  const { currentApplication } = useCurrentApplication();

  const [application, setApplication] = useState({});
  const [fieldToImprove, setFieldToImprove] = useState("general_description");
  const [fieldToImproveResponse, setFieldToImproveResponse] = useState("");


  const [sectors, setSectors] = useState([]);

  const [overviews, setOverviews] = useState([]);


  const [scopes, setScopes] = useState([]);
  const [stakeHolders, setStakeHolders] = useState([]);



  const init = async () => {
    try {

      const application = await getApplication(currentProject, currentApplication);

      const overviewList = await getOverview(currentProject, currentApplication);

      const scopeList = await getScope(currentProject, currentApplication);

      setOverviews(overviewList.data.app_overview);
      //  setOverviews({...feature, [data]:value});
      console.log("this is the overview list", overviewList.data);


      setScopes(scopeList.data.scope_detailed_app_description)
      console.log("this is the overview list", overviewList.data);

      setStakeHolders(scopeList.data.scope_stakeholders_processes)


      setApplication(application.data);

      console.log('dopo getApplicationRequirements');

    } catch (error) {
      //if (error.response.status === 404) navigate(`/${getSiteLanguage()}/not-found`)
      //if (error.response.status === 500) navigate(`/${getSiteLanguage()}/error`)
    }
  }

  useEffect(() => {
    init();
  }, []);


  // handle react form value changes
  const handleChange = (field, e) => {
    // Assuming you have a state updating function like setOverviews, setScopes, setStakeHolders
    switch (field) {
      case "overview":
        setOverviews(e.target.value);
        break;
      case "scope":
        setScopes(e.target.value);
        break;
      case "stakeholder_processes":
        setStakeHolders(e.target.value);
        break;
      default:
        break;
    }
  };



  const launchLoadRequirementsAndWait = async () => {

    const toastId = toast.loading('Loading...');

    try {
      let featureList = null;
      const oldFeatureListSize = overviews.length;

      launchAndPollForTermination(async () => {
        featureList = await getOverview(currentProject, currentApplication);
        if (featureList && featureList.data && featureList.data.length > oldFeatureListSize) {
          setOverviews(featureList.data.app_overview);

          toast.dismiss(toastId);

          toast.success("Requirements are ready.");
        }
      }, () => featureList && featureList.data && featureList.data.length > oldFeatureListSize);
    } catch (error) {
      toast.dismiss(toastId);

      toast.error("An error occurred while loading the requirements.");

      console.log(error);
    }
  };




  const handleGenerateOverviewClick = async () => {
    try {
      const resp = await generateOverview(currentProject, currentApplication);
      console.log("this is the generated overview", resp);
      launchLoadRequirementsAndWait();
      toast.success("A request to generate the overview has been sent to the system.");

      // You can add additional logic or notifications if needed.
    }
    catch (error) {
      console.error(error);
      // You can handle errors or display notifications here.
    }
  };




  const handleUpdateAllClick = async () => {
    try {
      let payloadOverview = { app_overview: overviews };
      let payloadScopeSP = { scope_stakeholders_processes: stakeHolders, scope_detailed_app_description: scopes };
      const resp = await updateOverview(currentProject, currentApplication, payloadOverview);
      const resp2 = await updateScope(currentProject, currentApplication, payloadScopeSP);
      console.log("this is the saved overview", resp);
      toast.success("Saved Successfully.");

      // You can add additional logic or notifications if needed.
    } catch (error) {
      console.error(error);
      // You can handle errors or display notifications here.
    }
  };


  const handleGenerateScopeClick = async () => {
    try {
      if (overviews !== "") {
        toast.success("A request to generate the scope has been sent to the system.");

        const resp = await generateScope(currentProject, currentApplication);
        console.log("this is the generated overview", resp);

        // You can add additional logic or notifications if needed.
      } else {
        toast.error("Overview must be filled first");
      }
    } catch (error) {
      console.error(error);
      // You can handle errors or display notifications here.
    }
  };




  return (
    <div>
      <ApplicationBuildMenu stepCode="overview" />


      <div className='container'>

        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-8 text-gray-800"> Overview & Scope</h1>
          <div>
            <Button className="saveBtn" variant="primary" onClick={handleUpdateAllClick}>
              <i class="bi bi-save"></i>{" "} <span>Save all</span>
            </Button>&nbsp;
            <Button className="saveBtn" variant="primary" onClick={handleGenerateOverviewClick}>
              <i className="fas fa-plus fa-sm text-white-50"></i>{" "} <span>Generate overview</span>
            </Button>&nbsp;
            <Button className="saveBtn" variant="primary" onClick={handleGenerateScopeClick}>
              <i className="fas fa-plus fa-sm text-white-50"></i>{" "} <span>Generate scope</span>
            </Button>&nbsp;
            <Button className="saveBtn chatButton" variant="primary">
              <i className="fas fa-comments"></i>
            </Button>
          </div>
        </div>
        <Form>
          <Tabs
            defaultActiveKey="Overview" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="Overview" title="Overview" se>
              <Form.Group className="mb-3" controlId="formProjectOverview">
                <Form.Label>Overview</Form.Label>
                <Form.Control type="text" as="textarea" rows={20} placeholder="Overview" value={overviews} onChange={(e) => handleChange("overview", e)} />
              </Form.Group>

            </Tab>
            <Tab eventKey="Scope" title="Scope">

              <Form.Group className="mb-3" controlId="formProjectDescription">
                <Form.Label>Scope</Form.Label>
                <Form.Control type="text" as="textarea" rows={20} placeholder="Scope" value={scopes} onChange={(e) => handleChange("scope", e)} />
              </Form.Group>
            </Tab>
            <Tab eventKey="Stakeholder processes" title="Stakeholder processes" >
              <Form.Group className="mb-3" controlId="formProjectEmail">
                <Form.Label>Stakeholder processes</Form.Label>
                <Form.Control type="text" as="textarea" rows={20} placeholder="Stakeholder processes" value={stakeHolders} onChange={(e) => handleChange("stakeholder_processes", e)} />
              </Form.Group>

            </Tab>
          </Tabs>






          <div className="row">


            <div>

            </div>
          </div>


        </Form>







      </div>
    </div>

  );
}


export default ApplicationOverview;
