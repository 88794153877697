import React from 'react';
import { Routes, Route, Outlet, Link } from "react-router-dom";
import './App.css';
import ReverseEngineering from './pages/ReverseEngineering';  
import DeloitteKnowledgeNavigator from './pages/DeloitteKnowledgeNavigator';  
import ITAssessment from './pages/ITAssessment';  
import ApplicationInitiation from './pages/ApplicationBuild/ApplicationInitiation';
import ApplicationList from './pages/ApplicationBuild/ApplicationList';
import ApplicationRequirements from './pages/ApplicationBuild/ApplicationRequirements';
import ApplicationDataModels from './pages/ApplicationBuild/ApplicationDataModels';
import ApplicationTechnicalArchitecture from './pages/ApplicationBuild/ApplicationTechnicalArchitecture';
import ApplicationTestCases from './pages/ApplicationBuild/ApplicationTestCases';
import ApplicationCodeSkeleton from './pages/ApplicationBuild/ApplicationCodeSkeleton';
import ApplicationRequirementDetail from './pages/ApplicationBuild/Requirement/ApplicationRequirementDetail';
import ApplicationRequirementDetailNew from './pages/ApplicationBuild/ApplicationRequirementDetail';
import ApplicationFeatureDetail from './pages/ApplicationBuild/Feature/ApplicationFeatureDetail';
import ApplicationFeatures from './pages/ApplicationBuild/Feature/ApplicationFeatures';
import ApplicationUseCase from './pages/ApplicationBuild/ApplicationUseCase';
import ApplicationProject from './pages/ApplicationBuild/ApplicationProject';
import ApplicationCreateProject from './pages/ApplicationBuild/ApplicationCreateProject';
import Layout from './common/Layout';
import Home from './pages/Home';
import ApplicationNonFunctionalRequirements from './pages/ApplicationBuild/NonFunctional/ApplicationNonFunctionalRequirements';
import ApplicationNonFunctionalRequirementDetail from './pages/ApplicationBuild/NonFunctional/ApplicationNonFunctionalRequirementDetail';
import ApplicationIntegrationRequirements from './pages/ApplicationBuild/Integration/ApplicationIntegrationRequirements';
import ApplicationTransitionRequirements from './pages/ApplicationBuild/Transition/ApplicationTransitionRequirements';
import ApplicationEditProject from './pages/ApplicationBuild/ApplicationEditProject';
import ApplicationOverview from './pages/ApplicationBuild/Overview/ApplicationOverview';
import ApplicationQuestions from './pages/ApplicationBuild/Question/ApplicationQuestions';
import ApplicationDocument from './pages/ApplicationBuild/document/ApplicationDocument';
import AssessmentMenu from './components/AssessmentMenu/AssessmentMenu';
import AssessmentFeatureForm from './pages/CloudMigrationAdvisor/AssessmentFeatureForm/AssessmentFeatureForm';
import ContextResume from './pages/CloudMigrationAdvisor/ContextResume/ContextResume';
import AssessmentQuestions from './pages/CloudMigrationAdvisor/AssessmentQuestions/AssessmentQuestions'
import InfrAssessmentContext from './pages/CloudMigrationAdvisor/InfrAssessmentContext/InfrAssessmentContext';
import InfrAssessmentQuestions from './pages/CloudMigrationAdvisor/InfrAssessmentQuestions/InfrAssessmentQuestions';
import InfrAssessmentDataIngestion from './pages/CloudMigrationAdvisor/InfrAssessmentDataIngestion/InfrAssessmentDataIngestion'
import InfrAssessmentHostObsolescence from './pages/CloudMigrationAdvisor/InfrAssessmentHostObsolescence/InfrAssessmentHostObsolescence'
import InfrAssessmentClusterSaturation from './pages/CloudMigrationAdvisor/InfrAssessmentClusterSaturation/InfrAssessmentClusterSaturation';
import InfrAssessmentFaultTolerance from './pages/CloudMigrationAdvisor/InfrAssessmentFaultTolerance/InfrAssessmentFaultTolerance';
import InfrAssessmentSoftwareDataIngestion from './pages/CloudMigrationAdvisor/InfrAssessmentSoftwareDataIngestion/InfrAssessmentSoftwareDataIngestion';
import InfrAssessmentSoftwareAnalysis from './pages/CloudMigrationAdvisor/InfrAssessmentSoftwareAnalysis/InfrAssessmentSoftwareAnalysis';
import InfrAssessmentSoftwareObsolescence from './pages/CloudMigrationAdvisor/InfrAssessmentSoftwareObsolescence/InfrAssessmentSoftwareObsolescence';
import InfrAssessmentSummary from './pages/CloudMigrationAdvisor/InfrAssessmentSummary/InfrAssessmentSummary';
import ApplicationContext from './pages/CloudMigrationAdvisor/ApplicationContext/ApplicationContext';
import ApplicationDataIngestion from './pages/CloudMigrationAdvisor/ApplicationDataIngestion/ApplicationDataIngestion';
import ApplicationSummaryCards from './pages/CloudMigrationAdvisor/ApplicationSummaryCards/ApplicationSummaryCards';
import ChatPage from './pages/chatPage/ChatPage';
import AdvisorWrapper from './pages/CloudMigrationAdvisor/AdvisorWrapper/AdvisorWrapper';
import UploadPage from './pages/filesUpload/UploadPage';

function Router() {
  return (

    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />  
        <Route path="/projects/:projectId/application-build" element={<ApplicationList />} />
        <Route path="/projects/:projectId/application-build/:applicationId?/initiation" element={<ApplicationInitiation />} />
        <Route path="/projects/:projectId/application-build/:applicationId?/features" element={<ApplicationFeatures />} />
        <Route path="/projects/:projectId/application-build/:applicationId?/features/:featureId?" element={<ApplicationFeatureDetail />} />
        <Route path="/projects/:projectId/application-build/:applicationId?/overview" element={<ApplicationOverview />} />
        <Route path="/projects/:projectId/application-build/:applicationId?/document" element={<ApplicationDocument />} />
        <Route path="/projects/:projectId/application-build/:applicationId?/non-functional" element={<ApplicationNonFunctionalRequirements />} />
        <Route path="/projects/:projectId/application-build/:applicationId?/integration" element={<ApplicationIntegrationRequirements />} />
        <Route path="/projects/:projectId/application-build/:applicationId?/question" element={<ApplicationQuestions />} />
        <Route path="/projects/:projectId/application-build/:applicationId?/transition" element={<ApplicationTransitionRequirements />} />
        <Route path="/projects/:projectId/application-build/:applicationId?/requirements" element={<ApplicationRequirements />} />
        <Route path="/projects/:projectId/application-build/:applicationId?/features/:featureId/requirements/:id?" element={<ApplicationRequirementDetail />} />
        <Route path="/projects/:projectId/application-build/:applicationId?/features/:featureId/requirements/new" element={<ApplicationRequirementDetail />} />
        <Route path="/projects/:projectId/application-build/:applicationId?/data-model" element={<ApplicationDataModels />} />
        <Route path="/projects/:projectId/application-build/:applicationId?/technical-architecture" element={<ApplicationTechnicalArchitecture />} />
        <Route path="/projects/:projectId/application-build/:applicationId?/test-cases" element={<ApplicationTestCases />} />
        <Route path="/projects/:projectId/application-build/:applicationId?/code-skeleton" element={<ApplicationCodeSkeleton />} />
        <Route path="/projects/:projectId/application-build/:applicationId?/use-case/:id?" element={<ApplicationUseCase />} />
        <Route path="/projects/:projectId/application-build/create-project" element={<ApplicationCreateProject />} />
        <Route path="/projects/:projectId/application-build/edit-project" element={<ApplicationEditProject />} />
        <Route path="/projects/cloud-migration-advisor/:projectId/as-is-assesment" element={<AdvisorWrapper />} />
        {/* <Route path="/projects/cloud-migration-advisor/" element={<AssessmentMenu />}>
          <Route path="/projects/cloud-migration-advisor/:projectId/as-is-assesment/context-assessment-feature/context-form" element={<AssessmentFeatureForm />} />
          <Route path="/projects/cloud-migration-advisor/:projectId/as-is-assesment/context-assessment-feature/context-resume" element={<ContextResume />} />
          <Route path="/projects/cloud-migration-advisor/:projectId/as-is-assesment/context-assessment-feature/questions" element={<AssessmentQuestions />} />
          <Route path="/projects/cloud-migration-advisor/:projectId/as-is-assesment/infrastructure-assessment-feature/infrastructure-context" element={<InfrAssessmentContext />} />
          <Route path="/projects/cloud-migration-advisor/:projectId/as-is-assesment/infrastructure-assessment-feature/infrastructure-questions" element={<InfrAssessmentQuestions />} />
          <Route path="/projects/cloud-migration-advisor/:projectId/as-is-assesment/infrastructure-assessment-feature/infrastructure-data-ingestion" element={<InfrAssessmentDataIngestion />} />
          <Route path="/projects/cloud-migration-advisor/:projectId/as-is-assesment/infrastructure-assessment-feature/host-obsolescence" element={<InfrAssessmentHostObsolescence />} />
          <Route path="/projects/cloud-migration-advisor/:projectId/as-is-assesment/infrastructure-assessment-feature/cluster-saturation" element={<InfrAssessmentClusterSaturation />} />
          <Route path="/projects/cloud-migration-advisor/:projectId/as-is-assesment/infrastructure-assessment-feature/fault-tolerance" element={<InfrAssessmentFaultTolerance />} />
          <Route path="/projects/cloud-migration-advisor/:projectId/as-is-assesment/infrastructure-assessment-feature/software-data-ingestion" element={<InfrAssessmentSoftwareDataIngestion />} />
          <Route path="/projects/cloud-migration-advisor/:projectId/as-is-assesment/infrastructure-assessment-feature/software-analysis" element={<InfrAssessmentSoftwareAnalysis />} />
          <Route path="/projects/cloud-migration-advisor/:projectId/as-is-assesment/infrastructure-assessment-feature/software-obsolescence" element={<InfrAssessmentSoftwareObsolescence />} />
          <Route path="/projects/cloud-migration-advisor/:projectId/as-is-assesment/infrastructure-assessment-feature/infrastructure-summary" element={<InfrAssessmentSummary />} />
          <Route path="/projects/cloud-migration-advisor/:projectId/as-is-assesment/application-assessment-feature/application-context" element={<ApplicationContext />} />
          <Route path="/projects/cloud-migration-advisor/:projectId/as-is-assesment/application-assessment-feature/application-data-ingestion" element={<ApplicationDataIngestion />} />
          <Route path="/projects/cloud-migration-advisor/:projectId/as-is-assesment/application-assessment-feature/application-summary-cards" element={<ApplicationSummaryCards />} />
          <Route path="/projects/cloud-migration-advisor/*" element={<div>Not Found!</div>}/>
        </Route> */}
        {/* <Route path="/projects/cloud-migration-advisor" element={<AssessmentMenu />}/> */}
        <Route path="/projects/cloud-native-architecture-navigator/chat" element={<ChatPage />}/>
        <Route path="/reverse-engineering" element={<ReverseEngineering />} />
        <Route path="/deloitte-knowledge-navigator" element={<DeloitteKnowledgeNavigator />} />
        <Route path="/upload-page" element={<UploadPage />} />
        <Route path="/it-assessment" element={<ITAssessment />} />
      </Route>
  </Routes>
);
}

export default Router;
