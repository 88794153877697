import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import "./ModalConfirm.css";
import { getApplication } from '../../services/applicationService';
import useCurrentProject from '../App/useCurrentProject';
import useCurrentApplication from '../App/useCurrentApplication';
import toast, { Toaster } from "react-hot-toast";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { Form } from 'react-bootstrap';
import * as formik from 'formik';
import * as yup from 'yup';


const ModalConfirm = forwardRef(({ title, modalMessage, onConfirm}, ref) => {
    const [modalShow, setModalShow] = useState(false);
    const handleModalClose = () => setModalShow(false);
    const handleModalShow = () => setModalShow(true);

    useImperativeHandle(ref, () => ({
        close() {
            handleModalClose();
        },
        show() {
            handleModalShow();
        },
      }));

    return (
        <>
        <Modal show={modalShow} onHide={() => handleModalClose()} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalMessage}
        </Modal.Body>
        <Modal.Footer>
        <Button className="cancelButton" variant="primary" onClick={handleModalClose}>
            Cancel
          </Button>
          <Button className="saveBtn" variant="primary" onClick={onConfirm}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
        </>
    )
});

export default ModalConfirm;
