import axios from "axios";
import { API } from './api'
import yaml from 'js-yaml';


/**
 * Creates the non functional requirement with the given payload and returns the created requirement
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @param {*} payload 
 * @returns 
 */
const createTransitionRequirement = async (projectId, applicationId, payload) => API().post(`/project/${projectId}/application/${applicationId}/transrequirement/`, payload);

/**
 * Updates the non functional requirement with the given id and returns the updated requirement
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @param {*} featureId 
 * @param {*} payload 
 * @returns 
 */
const updateTransitionRequirement = async (projectId, applicationId, featureId ,payload) => API().put(`/project/${projectId}/application/${applicationId}/transrequirement/${featureId}/`, payload);

/**
 * Deletes the non functional requirement  with the given id and returns the updated requirement
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @param {*} transrequirementId 
 * @returns 
 */
const deleteTransitionRequirement = async (projectId, applicationId, transrequirementId ) => API().delete(`/project/${projectId}/application/${applicationId}/transrequirement/${transrequirementId}/`);

/**
 * Returns the list of non functional requirements defined for the given application
 * 
 * @param {*} projectId 
 * @param {*} id 
 * @returns 
 */
const getTransitionRequirements = async (projectId, applicationId) => API().get(`/project/${projectId}/application/${applicationId}/transrequirement/`);

/**
 * Returns the non functional requirement with the given id
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @param {*} id 
 * @returns 
 */
const getTransitionRequirement = async (projectId, applicationId, id) => API().get(`/project/${projectId}/application/${applicationId}/transrequirement/${id}/`);

/**
 * Deletes all the non functional requirements for the given application
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @returns 
 */
const deleteAllTransitionRequirements = async (projectId, applicationId) => API().delete(`/project/${projectId}/application/${applicationId}/transrequirement/delete_all/`);

/**
 * Genereates the non functional requirements for the given application
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @returns 
 */
const generateTransitionRequirements = async (projectId, applicationId, payload) => API().post(`/project/${projectId}/application/${applicationId}/transrequirement/generate/`, payload);

/**
 * Genereates the non functional requirements for the given application
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @returns 
 */
const regenerateTransitionRequirement = async (projectId, applicationId, id) => API().post(`/project/${projectId}/application/${applicationId}/transrequirement/${id}/regenerate/`);

export {
    createTransitionRequirement,
    updateTransitionRequirement ,
    deleteTransitionRequirement ,
    getTransitionRequirements ,
    getTransitionRequirement ,
    generateTransitionRequirements ,
    deleteAllTransitionRequirements ,
    regenerateTransitionRequirement ,
};