import { API } from '../api'

const getChats = async(projectId,assessmentId) => API().get(`/project/${projectId}/assessment/${assessmentId}/chatbot_list`)
const createChat = async(projectId,assessmentId,body) => API().post(`/project/${projectId}/assessment/${assessmentId}/chatbot`,body)
const getChatHistory = async(projectId,assessmentId,chatId) => API().get(`project/${projectId}/assessment/${assessmentId}/chatbot_history/${chatId}`)
const questionChat = async(projectId,assessmentId,body) => API().post(`project/${projectId}/assessment/${assessmentId}/chatbot_question`, body)

export {
    getChats,
    createChat,
    getChatHistory,
    questionChat
};