import React from 'react';

function Home() {
  return (
    <div>
    {/* Add the image below the main-bar  */}
    <div className="image-container">
    <img src="/Images/content-home.png" alt="Description of the image" className="centered-image" />
  </div> 

  <h1 className="text-content">Generative AI-DesignSuite
</h1>
  </div>
  );
}

export default Home;
