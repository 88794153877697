import React, { useState, useEffect, useRef } from 'react';
import "./Chat.css";
import { sendChat, getChats } from '../../services/chatService';
import useCurrentProject from '../App/useCurrentProject';
import useCurrentApplication from '../App/useCurrentApplication';

// initialize the react module receing an id parameter

function Chat({ id }) {
  const {currentProject} = useCurrentProject();
  const {currentApplication} = useCurrentApplication();

  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const messageListRef = useRef(null);
  const [chatId, setChatId] = useState(id);
  
  const [chats, setChats] = useState([]);
  
  const init = async () => {
    console.log("init", id);

    await loadChats();
  };

  const loadChats = async () => {
    console.log("loadChats", id);

    const chats = await getChats(currentProject, currentApplication);

    console.log("chats", chats);

    const chatsToDisplay = [];
    for(const chat of chats.data) {
      console.log("chat", chat);
      const messages = chat.messages.filter((msg) => msg.role=='user');
      if(messages.length > 0){
        chatsToDisplay.unshift({...chat, firstMessage: messages[0].content});
      }
    }

    setChats(chatsToDisplay);
  }

  useEffect(() => {
    init();

    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages]);

  const createNewChat = async () => {
    setChatId(null);
    setMessages([]);
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() !== '') {
      let updatedMessages = [...messages, { text: newMessage, user: 'user', className: 'message dark' }];
      setMessages(updatedMessages);

      console.log("currentProject", currentProject);
      console.log("currentApplication", currentApplication);

      const messageCopy = newMessage;
      setNewMessage('');

      let chatResult = await sendChat(currentProject, currentApplication, { "question": messageCopy, "chat_id": chatId ? chatId: '0' });

      console.log("chatResult", chatResult);

      if(chatResult.data.chat_id) {
        setChatId(chatResult.data.chat_id);

        loadChats();

        chatResult = await sendChat(currentProject, currentApplication, { "question": messageCopy, "chat_id": chatResult.data.chat_id});

        console.log("chatResult", chatResult);
      }

      updatedMessages = [...updatedMessages, { text: chatResult.data.Bot_response, user: 'assistant', className: 'message' }];

      setMessages(updatedMessages);
      

    }
  };

  const loadChatFromHistory = async (chat) => {
    console.log('loadChatFromHistory', chat);
    console.log(messages);
    
    setChatId(chat.id);

    setMessages(chat.messages.filter((message) => message.role != 'system').map((message) => ({ text: message.content, user: message.role, className: 'message' })));    
  }
  
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  return (
    <div className="chat-container">
      <div className="chat-window">
        <div className="message-list" ref={messageListRef}>
          {messages.map((message, index) => (
            <div key={index} className={message.className}>
              <span className="user">{message.user}:</span> {message.text}
            </div>
          ))}
        </div>
        <div className="input-area">
          <input
            type="text"
            placeholder="Type your message..."
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={handleKeyPress}
          />
          <button onClick={handleSendMessage} className="saveBtn" variant="primary">Send</button>
        </div>
      </div>
      <div className="chat-list">
        <h3>History</h3> <button onClick={createNewChat} className="saveBtn" variant="primary">New Chat</button>
        
        {chats.map((chat, index) => 
        {
          return (
            <div key={index} className="chat-item" onClick={(e) => loadChatFromHistory(chat)}>
              <div className='chat-item-date'>{chat.created_at}</div>
              <div className="chat-item-title">{chat.firstMessage}</div>
            </div>
          )
        })}
        </div>
    </div>
  );
}

export default Chat;
