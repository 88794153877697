import React, { useRef, useState, useEffect } from 'react'
import './UploadForm.css'
import { MdOutlineFileUpload } from "react-icons/md";
import { RiFileExcel2Fill } from "react-icons/ri";
import { IoCloseSharp } from "react-icons/io5";

const UploadForm = ({
    title,
    info,
    downloadable = false,
    confirmUpload = true,
    UploadTepmlate = false,
    showFiles = true,
    downloadUrl = "",
    handleUpload = async () => { },
    handleUploadTemplate = async () => { },
    handleRemoveFiles= async () =>{},
    isContext
}) => {
    const uploadRef = useRef()
    const uploadRef2 = useRef()

    const [fileNames, setFileNames] = useState([])
    const [filesToUpload, setFilesToUpload] = useState([])
    const [pendingConfirmation, setPendingConfirmation] = useState(false)
    const [csvUploaded, setCsvUploaded] = useState(false);

    const handleDownload = () => {
        if (downloadUrl == "") return
        // Create a temporary anchor element
        const anchorElement = document.createElement('a');
        anchorElement.href = downloadUrl;
        // anchorElement.download = 'template.'; // Specify the desired file name

        // Append the anchor element to the document
        document.body.appendChild(anchorElement);

        // Trigger a click event on the anchor element to start the download
        anchorElement.click();

        // Remove the anchor element from the document
        document.body.removeChild(anchorElement);
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) return

        if (isContext) {
            const formData = new FormData();
            formData.append('file', file);
            await handleUpload(formData);
            setFileNames([file.name]);
            setCsvUploaded(true);
            return;
        }

        if (confirmUpload) {
            setPendingConfirmation(true)
            setFileNames([...fileNames, file.name])
            setFilesToUpload([...filesToUpload, file])
        } else {
            //Upload file
            // const formData = new FormData()
            // formData.append(formDataFileName, file)
            await handleUpload(file)
            setPendingConfirmation(false)
            setFileNames([...fileNames, file.name])
            setFilesToUpload([])
        }
    }

    const handleFileTemplateChange = async (event) => {



        //NEEDS FIX , IT DOES THE SAME AS UPLOAD DOCUMENT



        const file = event.target.files[0];
        if (!file) return
        if (confirmUpload) {
            setPendingConfirmation(true)
            setFileNames([...fileNames, file.name])
            setFilesToUpload([...filesToUpload, file])
        } else {
            //Upload file
            await handleUploadTemplate(file)
            setPendingConfirmation(false)
            setFileNames([...fileNames, file.name])
            setFilesToUpload([])
        }
    }

    const handleUploadClick = () => {
        if (!uploadRef) return
        uploadRef.current.click()
    }

    const handleUploadTemplateClick = () => {
        if (!uploadRef2) return
        uploadRef2.current.click()
    }


    const handleConfirmClick = async () => {
        try {
            //Upload file
            const formData = new FormData()
            filesToUpload.forEach(file => {
                formData.append('file', file);
            });
            await handleUpload(formData)
            setPendingConfirmation(false)
            setFilesToUpload([])
        } catch (error) {
            console.log(error)
        }

    }

    const removeFile = (indexToRemove) => {
        if(isContext){
            const updatedFileNames = fileNames.filter((_, index) => index !== indexToRemove);
            const updatedFilesToUpload = filesToUpload.filter((_, index) => index !== indexToRemove);
        
            // Update the state with the new arrays
            setFileNames(updatedFileNames);
            setFilesToUpload(updatedFilesToUpload);
            if (updatedFileNames.length === 0) {
                setCsvUploaded(false);
            }
        }
        else{
            console.log("removing file")
            handleRemoveFiles()
            setFileNames([])
            // setFileNames([...fileNames.slice(0,indexToRemove),...fileNames.slice(indexToRemove+1)])
        }
    }
    

    return (
        <>
            <div className={` ${isContext ? 'contextWrapper upload-form-wrapper-context' : 'upload-form-wrapper'}`}> 
            <div className={`${isContext ? 'upload-form-title-wrapper-context' : 'upload-form-title-wrapper'}`}>
                    {title}
                    {
                    ((fileNames.length > 0) && !isContext) &&
                    <div className={`${isContext ? 'upload-form-summary-context' : 'upload-form-summary'}`}>
                        <div className='summary-files'>
                            {showFiles && fileNames.map((name, index) => (
                                <div key={index}>
                                    {name} <IoCloseSharp size={"1.5rem"} className='pointer' onClick={() => removeFile(index)} />
                                </div>))
                            }
                        </div>
                        <button className={`upload-form-button${!pendingConfirmation ? " disabled" : ""}`} onClick={() => handleConfirmClick()}>
                            {pendingConfirmation ? "Confirm documents" : "Documents has been uploaded"}
                        </button>
                    </div>
                }
                                 {(isContext && csvUploaded && fileNames[0]) && (<div className='uploadedCsv'><RiFileExcel2Fill size={60} style={{color:'green',marginBottom:'15px'}} /><span style={{color:'black',fontSize:'30px'}}>{fileNames[0]}</span>
                {fileNames[0] && <IoCloseSharp size={"1.5rem"} className='contextDel' onClick={() => removeFile(0)} />}
                </div>)}
                    <div className={`${isContext ? 'upload-form-button-wrapper-context' : 'upload-form-button-wrapper'}`}>
                        {downloadable &&
                            <button onClick={() => handleDownload()} className='download-form-button'>
                                Download template
                                <img src="../../../Images/DownloadTemplate.svg" alt="DownloadTemplate" style={{ marginBottom: '5px', marginLeft: '4px' }} />
                            </button>
                        }
                        {UploadTepmlate &&
                            <button onClick={() => handleUploadTemplateClick()} className='download-form-button'>
                                Upload Template
                                <img src="../../../Images/UploadTemplate.svg" alt="UploadTemplate" style={{ marginBottom: '3px', marginLeft: '2px' }} />
                            </button>
                        }
                        <button onClick={() => handleUploadClick()} className={` ${isContext ? 'contextButton upload-form-button-context' : 'upload-form-button'} ${(fileNames[0] && isContext) ? 'disabledBtn' : ''}`} disabled = {fileNames[0] ? true : false}>
                        {isContext && csvUploaded ?
                        <>CSV Correctly Uploaded <br /></>
                             : isContext ?
                                     <>Upload Your CSV <br /><MdOutlineFileUpload  size={40} /> </>
                                      : <>Upload Document<img src="../../../Images/UploadDocuments.svg" alt="UploadDocunent" style={{ marginBottom: '3px', marginLeft: '4px' }} />
                                      </>
                        }
                        </button>

                    </div>
                </div>
                {!isContext && <div className='upload-form-body-wrapper'>
                    {info}
                    {
                        fileNames.length > 0 &&
                        <div className='uploaded-files'>
                            {showFiles && fileNames.map((name, index) => {
                                return (
                                    <div key={index} className='uploaded-file-pill'>
                                        {name}
                                        <img src="/Images/Delete.svg" className='pill-button' onClick={() => removeFile(index)} />
                                    </div>)
                            })
                            }
                        </div>
                    }
                </div>
                }
            </div>
            <input onChange={(event) => handleFileChange(event)} ref={uploadRef} className='upload-input' type="file" id="file-upload" name="file-upload" />
            <input onChange={(event) => handleFileTemplateChange(event)} ref={uploadRef2} className='upload-input' type="file" id="file-upload" name="file-upload" />
        </>
    )
}

export default UploadForm