import React, { FC, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
import { Table } from 'react-bootstrap';
import { getApplications } from '../../services/applicationService';
import "./ApplicationRequirementDetail.css";
import useCurrentProject from '../../components/App/useCurrentProject';
import useCurrentApplication from '../../components/App/useCurrentApplication';
import ApplicationBuildMenu from '../../components/ApplicationBuildMenu/ApplicationBuildMenu';
import { useParams } from 'react-router-dom';
import { getRequirement, downloadRequirementCard } from '../../services/requirementsService';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Chat from '../../components/Chat/Chat';
import toast, { Toaster } from "react-hot-toast";

const ApplicationMicroservices = () => {
    const {currentProject} = useCurrentProject();
    const {currentApplication} = useCurrentApplication();
    const {id} = useParams();

    const [applicationName, setApplicationName] = useState("");
    const [requirementCard, setRequirementCard] = useState({});
    const [requirement, setRequirement] = useState({});

    const [canvasChatShow, setCanvasChatShow] = useState(false);

    const handleCanvasChatClose = () => setCanvasChatShow(false);
    const handleCanvasChatShow = () => setCanvasChatShow(true);

    const handleChange = (name, e) => {
      console.log(name, e.target.value);
      let value = e.target.value;
  
      setRequirementCard({...requirementCard, [name]: value});

      console.log('handleChange', requirementCard);
    }
    
    const saveRequirement = async () => {
      toast.success("The requirement has been saved!");
    };

    const init = async () => {
        try {
          const req = await getRequirement(currentProject, currentApplication, id);

          setRequirement(req.data);
    
          const reqCard = await downloadRequirementCard(currentProject, currentApplication, id);
    
          setRequirementCard(reqCard);

        } catch (error) {
            //if (error.response.status === 404) navigate(`/${getSiteLanguage()}/not-found`)
            //if (error.response.status === 500) navigate(`/${getSiteLanguage()}/error`)
        }
    }

    useEffect(() => {
        init();
    }, []);

    const CanvasChat = () => { 
      return (
        <>    
          <Offcanvas show={canvasChatShow} onHide={handleCanvasChatClose} placement={'end'} backdrop={false} scroll={true}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Chat</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Chat />
            </Offcanvas.Body>
          </Offcanvas>
        </>
      )
  };

    return (
      <>
       <ApplicationBuildMenu stepCode="requirements" />
       <CanvasChat />
       <div className={ "container" }>



<div className="d-sm-flex align-items-center justify-content-between mb-4">
              <h1 className="h3 mb-8 text-gray-800"> Use Case : {requirement.name}</h1>
              
              {/*}
              <Button variant="primary" onClick={downloadExcel} className="download-btn">
              <i className="fas fa-plus fa-sm text-white-50"></i> Export
              </Button>
    {*/}
             
             
             <div>
             <Button className="saveBtn" variant="primary" onClick={saveRequirement}>
          <i className="saveBtn"></i>{" "}<span>Save</span>
          </Button>&nbsp;
        

              </div>
              </div>
<Button className="saveBtn chatButton" variant="primary" onClick={handleCanvasChatShow}>
              <i class="fas fa-comments"></i>
          </Button>
<Form>
    <Form.Group className="mb-3" controlId="formProjectEmail">
      <Form.Label>Name</Form.Label>
      <Form.Control type="text" placeholder="Enter a project name" value={requirement.name} onChange={(e)=>handleChange("name", e)} />
    </Form.Group>
    <Form.Group className="mb-3" controlId="formProjectEmail">
      <Form.Label>Description</Form.Label>
      <Form.Control type="text"  as="textarea" rows={3} placeholder="Enter a project name" value={requirement.description} onChange={(e)=>handleChange("description", e)}  />
    </Form.Group>
    <Form.Group className="mb-3" controlId="formProjectEmail">
      <Form.Label>Priority</Form.Label>
      <Form.Control type="text" placeholder="Enter a project name" value={requirement.priority} onChange={(e)=>handleChange("priority", e)} />
    </Form.Group>
    <Form.Group className="mb-3" controlId="formProjectEmail">
      <Form.Label>Input/Output</Form.Label>
      <Form.Control type="text" placeholder="Enter a project name" value={requirement.input_output} onChange={(e)=>handleChange("input_output", e)} />
    </Form.Group>
    <Form.Group className="mb-3" controlId="formProjectEmail">
      <Form.Label>Additional Notes</Form.Label>
      <Form.Control type="text" placeholder="Enter a project name" value={requirement.additional_notes}  onChange={(e)=>handleChange("additional_notes", e)} />
    </Form.Group>
  </Form>
  <br/>
  <br/>
</div>

      </>  
    );
  };

export default ApplicationMicroservices;
