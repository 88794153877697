import React, { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import "./Chat.css";
import { sendChat, getChats } from '../../services/chatService';
import useCurrentProject from '../App/useCurrentProject';
import useCurrentApplication from '../App/useCurrentApplication';
import Chat from './Chat';
import Offcanvas from 'react-bootstrap/Offcanvas';

// initialize the react module receing an id parameter

const ChatCanvas = forwardRef(({ id }, ref) => {
  useImperativeHandle(ref, () => ({
    close() {
      handleCanvasChatClose();
    },
    show() {
      handleCanvasChatShow();
    },
  }));

  /* chat canvas variables */
  const [canvasChatShow, setCanvasChatShow] = useState(false);
  const handleCanvasChatClose = () => setCanvasChatShow(false);
  const handleCanvasChatShow = () => setCanvasChatShow(true);
  
  return (
    <>
        <Offcanvas show={canvasChatShow} onHide={handleCanvasChatClose} placement={'end'} backdrop={false} scroll={true}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Chat</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Chat />
          </Offcanvas.Body>
        </Offcanvas>
      </>
  );
});

export default ChatCanvas;
