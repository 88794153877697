import React from 'react';
import { useState, useEffect } from 'react'
import { getProjects, postProject, postAssessment, getAsseessments } from '../services/CloudMigrationAdvisor/projects';
import { Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useMyGlobalContext } from '../global/globalContext';
import AdvisorWrapper from './CloudMigrationAdvisor/AdvisorWrapper/AdvisorWrapper';

function ITAssessment() {
  const [projects, setProjects] = useState([])
  const [selectedProject, setSelectedProject] = useState()
  const [selectedAssessment, setSelectedAssessment] = useState()
  const [assessments, setAssessments] = useState([])
  const navigate = useNavigate()
  const { globalState, updateGlobalState } = useMyGlobalContext();
  const [navigateTrigger, setNavigateTrigger] = useState(false)
  const [newProject, setNewProject] = useState("")

  useEffect(() => {
    console.log(globalState, navigateTrigger)
    if (!globalState.projectId || !globalState.assessmentId) return
    if (!navigateTrigger) return
    navigate(`/projects/cloud-migration-advisor/${selectedProject.id}/as-is-assesment`)
  }, [globalState, navigateTrigger])

  const initProjects = async () => {
    try {
      const response = await getProjects()
      const data = response.data
      setProjects(data)
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    initProjects()
  }, [])

  const initAssessments = async () => {
    try {
      const response = await getAsseessments(selectedProject.id)
      setAssessments(response.data)
    } catch (error) {

    }
  }

  useEffect(() => {
    if (!selectedProject) return
    initAssessments()
  }, [selectedProject])

  const addEmptyAssessment = async () => {
    try {
      const body = { Company_Name: "-" }
      await postAssessment(selectedProject.id, body)
      initAssessments()
    } catch (error) {
      console.log(error)
    }
  }

  const createNewProject = async () => {
    try {
      const body = {
        name: newProject,
        description: newProject
      }
      await postProject(body)
      initProjects()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <div>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th className="col-md-1">#</th>
              <th className="col-md-5">Project Name</th>
              <th className="col-md-5">Project Description</th>
            </tr>
          </thead>
          <tbody>
            {projects.map((project, index) => {
              return (
                <tr
                  onClick={() => setSelectedProject({ id: project.id, label: project.name })}
                  key={index}
                  style={{ height: "50px" }}
                >
                  <td style={{ backgroundColor: selectedProject ? selectedProject.id == project.id ? "green" : "" : "" }}>{project.id}</td>
                  <td style={{ backgroundColor: selectedProject ? selectedProject.id == project.id ? "green" : "" : "" }}>{project.name}</td>
                  <td style={{ backgroundColor: selectedProject ? selectedProject.id == project.id ? "green" : "" : "" }}>{project.description}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
        {
          selectedProject &&
          <button className='assessment-button' onClick={() => addEmptyAssessment()}>
            Add new assessment
          </button>
        }
        {selectedProject && assessments.length > 0 &&
          <Table striped bordered hover>
            <thead>
              <tr>
                <th className="col-md-1">#</th>
                <th className="col-md-5">Company Name</th>
                <th className="col-md-5">Company Industry</th>
              </tr>
            </thead>
            <tbody>
              {assessments.map((assessment, index) => {
                return (
                  <tr
                    onClick={() => setSelectedAssessment({ id: assessment.id, label: assessment.Company_Name })}
                    key={index}
                    style={{ height: "50px" }}
                  >
                    <td style={{ backgroundColor: selectedAssessment ? selectedAssessment.id == assessment.id ? "green" : "" : "" }}>{assessment.id}</td>
                    <td style={{ backgroundColor: selectedAssessment ? selectedAssessment.id == assessment.id ? "green" : "" : "" }}>{assessment.Company_Name}</td>
                    <td style={{ backgroundColor: selectedAssessment ? selectedAssessment.id == assessment.id ? "green" : "" : "" }}>{assessment.Industry}</td>
                  </tr>
                )
              })}
            </tbody>
          </Table>
        }
        {selectedProject && selectedAssessment &&
          <button className='assessment-button' onClick={() => { updateGlobalState({ ...globalState, projectId: selectedProject.id, assessmentId: selectedAssessment.id }); setNavigateTrigger(true); }}>
            Go to Assessment
          </button>
        }
      </div >
    </>
    // <AdvisorWrapper/>
  );
}

export default ITAssessment;
