import "./ApplicationBuildMenu.css";

import React from "react";
import { Link } from "react-router-dom";
import useCurrentApplication from "../App/useCurrentApplication";
import useCurrentProject from "../App/useCurrentProject";

function ApplicationBuildMenu({stepCode}) {
    const {currentProject} = useCurrentProject();
    const {currentApplication} = useCurrentApplication();

    const stepList = [
      {stepCode: "initiation", path: "initiation", stepName:"Initiation", iconClassName: "far fa-handshake"},
      {stepCode: "features", path: "features", stepName:"Functional", iconClassName: "far fa-newspaper"},
      {stepCode: "non-functional", path: "non-functional", stepName:"Non Functional", iconClassName: "far fa-newspaper"},
      {stepCode: "integration", path: "integration", stepName:"Integration", iconClassName: "far fa-newspaper"},
      {stepCode: "transition", path: "transition", stepName:"Transition", iconClassName: "far fa-newspaper"},
      {stepCode: "overview", path: "overview", stepName:"Overview&Scope", iconClassName: "fa fa-binoculars"},
      {stepCode: "document", path: "document", stepName:"Document", iconClassName: "far fa-file"},

/*      {stepCode: "test-cases", stepName:"Test Cases", iconClassName: "fas fa-home"},
      {stepCode: "data-model", stepName: "Data Model", iconClassName: "fas fa-database"},
      {stepCode: "technical-architecture", stepName: "Technical Architecture", stepName:"Initiation",iconClassName: "fas fa-building"},
      {stepCode: "code-skeleton", stepName: "Code Skeleton", iconClassName: "fas fa-code"}*/
    ];

    const isLast = (stepCode) => {
      return stepList.findIndex(x => x.stepCode === stepCode) + 1 == stepList.length;
    }

    const getStepId = (stepCode) => {
      const stepId = stepList.findIndex(x => x.stepCode === stepCode) + 1;

      return stepId;
    }

    const getStatusIcon = (stepCode, currentStepCode) => {
      const stepId = getStepId(stepCode);
      const currentStepId = getStepId(currentStepCode);

      console.log(stepCode, currentStepCode, stepId, currentStepId)
      if (stepId < currentStepId) {
        return "icon completed";
      } else if (stepId === currentStepId) {
        return "icon in-progress";
      } else {
        return "icon";
      }
    }

    const getLabelStatusClass = (stepCode, currentStepCode) => {
      const stepId = getStepId(stepCode);
      const currentStepId = getStepId(currentStepCode);

      if (stepId < currentStepId) {
        return "label completed";
      } else if (stepId === currentStepId) {
        return "label loading";
      } else {
        return "label";
      }
    }

    const getStepStatusClass = (stepCode, currentStepCode) => {
      const stepId = getStepId(stepCode);
      const currentStepId = getStepId(currentStepCode);

      if (stepId < currentStepId) {
        return "step completed";
      } else if (stepId === currentStepId) {
        return "step in-progress";
      } else {
        return "step";
      }
    }

    const getLineStatusClass = (prevStepCode, currentStepCode) => {
      const prevStepId = getStepId(prevStepCode); // 3
      const currentStepId = getStepId(currentStepCode); //4

      if(currentStepId-1 > prevStepId) {
        return "line completed";
      } else if(currentStepId > prevStepId) {
        return "line next-step-in-progress";
      } else if(currentStepId == prevStepId) {
        return "line prev-step-in-progress";
      } else {
        return "line";
      }
    }
        
    const WizardStep = ({step, currentStepCode}) => {
      return (
        <>
        <div className={getStepStatusClass(step.stepCode, currentStepCode)}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z" />
        </svg>
          <Link to={`/projects/${currentProject}/application-build/${currentApplication}/${step.path}`} className={getLabelStatusClass(step.stepCode, currentStepCode)}>{step.stepName}</Link>
          <div className={getStatusIcon(step.stepCode, currentStepCode)}>
          <i className={step.iconClassName}></i>
          </div>
        </div>

        {isLast(step.stepCode) ? null : <div className={getLineStatusClass(step.stepCode, currentStepCode)}></div>}
        </>
      )
    };


    return (
<>
      <link rel='stylesheet' href='https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.8.2/css/all.min.css'></link>

      <div className="wrapper">
      <div className="main-container">
          <div className="steps-container">
      
          {stepList && stepList.map((step, index) => {  
            return <WizardStep key={index} 
                               step={step} 
                               currentStepCode={stepCode} 
                               />
          })}

          </div>

      </div>
      </div>
    </>

    );
}

export default ApplicationBuildMenu;
