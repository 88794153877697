import React, { FC, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
import { Table } from 'react-bootstrap';
import { getApplication,updateApplication } from '../../services/applicationService';
import "./ApplicationRequirements.css";
import useCurrentProject from '../../components/App/useCurrentProject';
import useCurrentApplication from '../../components/App/useCurrentApplication';
import ApplicationBuildMenu from '../../components/ApplicationBuildMenu/ApplicationBuildMenu';
import {getRequirements, deleteRequirement} from '../../services/requirementsService';
import * as XLSX from 'xlsx';
import toast, { Toaster } from "react-hot-toast";
import Modal from 'react-bootstrap/Modal';
import Chat from '../../components/Chat/Chat';
import Offcanvas from 'react-bootstrap/Offcanvas';

const ApplicationRequirements = () => {
      const {currentProject} = useCurrentProject();
      const {currentApplication} = useCurrentApplication();

      const [application, setApplication] = useState({});
      const [functionalRequirements, setFunctionalRequirements] = useState([]);
      const [cards, setCards] = useState({requirementsList: []});
      const [numberOfRequirements, setNumberOfRequirements] = useState(20);

      const [modalShow, setModalShow] = useState(false);
      const handleModalClose = () => setModalShow(false);
      const handleModalShow = () => setModalShow(true);

      const [modalShow1, setModalShow1] = useState(false);
      const handleModalClose1 = () => setModalShow1(false);
      const handleModalShow1 = () => setModalShow1(true);

      const [modalChatShow, setModalChatShow] = useState(false);

      const handleModalChatClose = () => setModalChatShow(false);
      const handleModalChatShow = () => setModalChatShow(true);
    
      const [canvasChatShow, setCanvasChatShow] = useState(false);

      const handleCanvasChatClose = () => setCanvasChatShow(false);
      const handleCanvasChatShow = () => setCanvasChatShow(true);
    
      const init = async () => {
        try {
          console.log("currentApplication", currentApplication);

          
          console.log("before getApplication", currentApplication);
          const application = await getApplication(currentProject, currentApplication);
          console.log(application.data);
          console.log("after getApplication", currentApplication);
  
          const requirementsList = await getRequirements(currentProject, currentApplication);
          console.log('requirementsList', requirementsList.data);
  
          setFunctionalRequirements(requirementsList.data);
  
          console.log(application.data);
          setApplication(application.data);
          

        } catch (error) {
            //if (error.response.status === 404) navigate(`/${getSiteLanguage()}/not-found`)
            //if (error.response.status === 500) navigate(`/${getSiteLanguage()}/error`)
        }
    }

    const downloadExcel = () => {
      const wsFunctional = XLSX.utils.json_to_sheet(functionalRequirements);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, wsFunctional, "Functional");
      //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
      //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
      XLSX.writeFile(workbook, "DataSheet.xlsx");
    };

    const openChat = (requirement) => {
      console.log("openChat", requirement);
    };

    const generateRequirements = async () => {
      try {
        console.log("generateRequirements", application);
        let resp = null;
    
        const payload = {...application, generate_requirements: 1, number_of_requirements: numberOfRequirements};
        delete payload.list_of_generated_reports;

        resp = await updateApplication(currentProject, currentApplication, payload);
    
        console.log(resp);
        toast.success("A request to generate the requirements has been sent to the system.");
        handleModalClose();

        } catch (error) {
          console.log(error);
        }
    };

    useEffect(() => {
        init();
    }, []);



   

    const CanvasChat = () => { 
      return (
        <>    
          <Offcanvas show={canvasChatShow} onHide={handleCanvasChatClose} placement={'end'} backdrop={false} scroll={true}>
            <Offcanvas.Header closeButton>
              <Offcanvas.Title>Chat</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
              <Chat />
            </Offcanvas.Body>
          </Offcanvas>
        </>
      )
  };

    const ModalChat = () => {
      return (
        <Modal show={modalChatShow} onHide={handleModalChatClose} backdrop="static" dialogClassName="modal-lg">
          <Modal.Header closeButton>
            <Modal.Title>Chat</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Chat />
          </Modal.Body>
  
        </Modal>
      )
    };

    

    const deleteRequirement = async (id) => {
    
      try {
         let resp = null;
    
            resp = await deleteRequirement(currentProject, currentApplication,id);
              //IT DELETED ALL THE REQUIREMENTS
              
              handleModalClose1();
    
        console.log(resp);
        toast.success("The requirement has been deleted.");
    
            await init();   
    
        } catch (error) {
          console.log(error);
        }  
     }
  
    


    return (
      <>
       <ApplicationBuildMenu stepCode="requirements" />

       <ModalChat/>
       <CanvasChat/>

       <Modal show={modalShow} onHide={handleModalClose} backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>Generate Requirements</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formImproveField">

              <Form.Label>Number of requirements</Form.Label>
            <Form.Control type="text" placeholder="Data Model Name" value={numberOfRequirements} onChange={(e)=>setNumberOfRequirements(e.target.value)} />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button className="saveBtn" variant="primary" onClick={generateRequirements}>
              Generate!
            </Button>
          </Modal.Footer>
        </Modal>

       <div className='container'>
       <div className="d-sm-flex align-items-center justify-content-between mb-4 ">
              <h1 className="h3 mb-8 text-gray-800"> Requirements</h1>
              
              {/*}
              <Button variant="primary" onClick={downloadExcel} className="download-btn">
              <i className="fas fa-plus fa-sm text-white-50"></i> Export
              </Button>
    {*/}
             
             
             <div>
             <Button variant="primary" onClick={init} className="addReq-btn">
              <i className="fas fa-sync fa-sm text-white-50"></i> Refresh
              </Button>
              &nbsp;&nbsp;
              <Button variant="primary" onClick={handleModalShow} className="addReq-btn">
              <i className="fas fa-plus fa-sm text-white-50"></i> Generate Requirements
              </Button> &nbsp;
              <Link to={`/projects/${currentProject}/application-build/${currentApplication}/requirements/new`}>
              <Button variant="primary"   className="addReq-btn">
              <i className="fas fa-plus fa-sm text-white-50"></i> Add Requirement
              </Button>
              </Link>
              &nbsp;&nbsp;
              <Button className="saveBtn chatButton" variant="primary" onClick={handleCanvasChatShow}>
              <i class="fas fa-comments"></i>
          </Button>

              </div>
          </div>
        <Table striped bordered hover>
    <thead>
      <tr>
        <th>Requirement ID</th>
        <th>Name</th>
        <th>Description</th>
        <th>Priority</th>
        <th>Input/Output</th>
        <th>Additional Notes</th>
        <th></th>
      </tr>
    </thead>
      <tbody>
        { functionalRequirements && functionalRequirements.map((requirement,index) => {
          return (<tr>
            <td>{'FR'+(index+1).toString().padStart(2,'0')}</td>
          <td>{requirement.name}</td>
          <td>{requirement.description}</td>
          <td>{requirement.priority}</td>
          <td>{requirement.input_output}</td>
          <td>{requirement.additional_notes}</td>
          <td><div className="d-flex">
      <Link
          className="nav-link inline collapsed col-md-5"
          to={`/projects/${currentProject}/application-build/${currentApplication}/requirements/${requirement.id}`}
          data-toggle="collapse"
          data-target="#collapseTwo"
          aria-expanded="true"
          aria-controls="collapseTwo"
      >
          <i className="fas fa-edit"></i>
      </Link>
      
      <Link
          className="nav-link inline collapsed col-md-5"
          to={`/projects/${currentProject}/application-build/${currentApplication}/use-case/${requirement.id}`}
          data-toggle="collapse"
          data-target="#collapseTwo"
          aria-expanded="true"
          aria-controls="collapseTwo"
      >
          <i className="fas fa-eye"></i>  
      </Link>
      
     
      <div>
  <a href="#" onClick={() => setModalShow1(true)} style={{ textDecoration: 'none', color: 'black' }}>
    <i className="fas fa-trash"></i>
  </a>

  <Modal show={modalShow1} onHide={handleModalClose1} backdrop="static">
    <Modal.Header closeButton>
      <Modal.Title>Delete Requirement</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>Are you sure you want to delete this requirement?</p>
    </Modal.Body>
    <Modal.Footer>
      <Button className="saveBtn" variant="primary" onClick={handleModalClose1}>
        Cancel
      </Button>
      <Button className="saveBtn" variant="danger" onClick={ () => deleteRequirement(requirement.id)}>
        Delete
      </Button>
    </Modal.Footer>
  </Modal>
</div>



          





  </div>

                    </td>
        </tr>
          )
        })}
    </tbody>
  </Table>
  </div>
      </>  
    );
  };


export default ApplicationRequirements;
