import { useState, useEffect } from "react";
import toast from "react-hot-toast";

export default function useCurrentProject() {
  const getCurrentProject = () => {
    try {
      const storedProjectId = localStorage.getItem("currentProject");
      return storedProjectId;
    } catch (error) {
      console.error("Error fetching or setting current project:", error);
      toast.error("Error fetching current project");
    }
  };
  const [currentProject, setCurrentProject] = useState(getCurrentProject());

  return {
    currentProject,
    setCurrentProject,
  };
}
