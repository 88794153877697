import axios from "axios";
import { API } from './api'
import { getApplication } from "./applicationService";
import yaml from 'js-yaml';


/**
 * Creates the requirement with the given payload and returns the created requirement
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @param {*} featureId
 * @param {*} payload 
 * @returns 
 */
const createRequirement = async (projectId, applicationId, featureId, payload) => API().post(`/project/${projectId}/application/${applicationId}/feature/${featureId}/requirement/`, payload);

/**
 * Updates the requirement with the given id and returns the updated requirement
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @param {*} featureId
 * @param {*} requirementId 
 * @param {*} payload 
 * @returns 
 */
const updateRequirement = async (projectId, applicationId, featureId, requirementId,payload) => API().put(`/project/${projectId}/application/${applicationId}/feature/${featureId}/requirement/${requirementId}/`, payload);

/**
 * Deletes the requirement with the given id and returns the updated requirement
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @param {*} featureId
 * @param {*} requirementId 
 * @returns 
 */
const deleteRequirement = async (projectId, applicationId, featureId, requirementId ) => API().delete(`/project/${projectId}/application/${applicationId}/feature/${featureId}/requirement/${requirementId}/`);

/**
 * Returns the list of requirements defined for the given application
 * 
 * @param {*} projectId 
 * @param {*} applicationId
 * @param {*} featureId
 * @returns 
 */
const getRequirements = async (projectId, applicationId, featureId) => API().get(`/project/${projectId}/application/${applicationId}/feature/${featureId}/requirement/`);

/**
 * Returns the requirement with the given id
 * 
 * @param {*} projectId 
 * @param {*} applicationId
 * @param {*} featureId
 * @param {*} id 
 * @returns 
 */
const getRequirement = async (projectId, applicationId, featureId, id) => API().get(`/project/${projectId}/application/${applicationId}/feature/${featureId}/requirement/${id}/`);

/**
 * Downloads the requirements for the given application
 * 
 * @param {*} projectId 
 * @param {*} id 
 * @returns 

const downloadRequirements = async (projectId, id) => {
    const resp = await getApplication(projectId, id);
    
    const lst = eval(resp.data.list_of_generated_reports);
    if(lst.length == 0) {
        return {
            data: {
                functional_requirements: [],
            }
        };
    }

    const path = lst[lst.length - 1];

    const res = await axios.create({baseURL: "https://itgeniuslab.blob.core.windows.net/subscription1deloitte"}).get(lst[lst.length - 1]);
    if(!res) {
        return null;
    }

    console.log('downloadRequirements', res.data);
    return res.data;
};
 */

/**
 * Downloads the requirement card for the given application
 * 
 * @param {*} currentProject 
 * @param {*} currentApplication 
 * @param {*} id 
 * @returns 
 */
const downloadRequirementCard = async (currentProject, currentApplication, id) => {
    const resp = await getRequirement(currentProject, currentApplication, id);
    
    const lst = eval(resp.data.list_of_generated_cards);
    if(lst.length == 0) {
        return {
            data: {
                functional_requirements: [],
            }
        };
    }

    const path = lst[lst.length - 1];

    const res = await axios.create({baseURL: "https://itgeniuslab.blob.core.windows.net/subscription1deloitte"}).get(lst[lst.length - 1]);
    if(!res) {
        return null;
    }

    console.log('downloadRequirements', res.data);
    return res.data;
};


export {
    getRequirements,
    createRequirement,
    updateRequirement,
    deleteRequirement,
    getRequirement,
    downloadRequirementCard,
};