import React from 'react';
import './GeneralInformationContent.css';

const GeneralInformationContent = ({ title, data }) => {
    return (
        <div className="general-information-container">
            <h2 className="general-information-title">{title}</h2>
            <div className="data">
                <div className="general-information-data">
                    {data.map((item, index) => (
                        <div key={index} className="information-item">

                            <h3 className="item-title">{item.label}</h3>
                            <p className="item-value">{item.value}</p>

                        </div>
                    ))}
                </div>
            </div>

        </div>
    );
};

export default GeneralInformationContent;
