import React, { FC, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import { Table } from "react-bootstrap";
import "./ApplicationRequirements.css";
import useCurrentProject from "../../components/App/useCurrentProject";
import ApplicationBuildMenu from "../../components/ApplicationBuildMenu/ApplicationBuildMenu";
import { getApplication } from "../../services/applicationService";
import {
  getDataModels,
  createDataModel,
  downloadDataModel,
} from "../../services/dataModelService";
import * as XLSX from "xlsx";
import toast, { Toaster } from "react-hot-toast";
import useCurrentApplication from "../../components/App/useCurrentApplication";
import Modal from "react-bootstrap/Modal";

const ApplicationDataModel = () => {
  const { currentProject } = useCurrentProject();
  const { currentApplication } = useCurrentApplication();

  const [application, setApplication] = useState({});
  const [functionalRequirements, setFunctionalRequirements] = useState([]);
  const [cards, setCards] = useState({ requirementsList: [] });
  const [dataModelName, setDataModelName] = useState("");

  const [modalShow, setModalShow] = useState(false);
  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);

  const init = async () => {
    try {
      console.log("currentApplication", currentApplication);

      if (!currentApplication) {
        return;
      }

      console.log("before getApplication", currentApplication);
      const application = await getApplication(
        currentProject,
        currentApplication
      );
      console.log(application.data);
      console.log("after getApplication", currentApplication);

      const requirementsList = await getDataModels(
        currentProject,
        currentApplication
      );
      console.log("dataModels 2", requirementsList.data);

      const datamodeldata = await downloadDataModel(
        currentProject,
        currentApplication,
        requirementsList.data[requirementsList.data.length - 1].id
      );
      setFunctionalRequirements(datamodeldata);

      console.log(application.data);
      setApplication(application.data);
    } catch (error) {
      //if (error.response.status === 404) navigate(`/${getSiteLanguage()}/not-found`)
      //if (error.response.status === 500) navigate(`/${getSiteLanguage()}/error`)
    }
  };

  const downloadExcel = () => {
    const wsFunctional = XLSX.utils.json_to_sheet(functionalRequirements);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, wsFunctional, "Functional");
    //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(workbook, "DataSheet.xlsx");
  };

  const generateRequirements = async () => {
    try {
      console.log("generateRequirements", application);
      let resp = null;

      const payload = { name: dataModelName, application: currentApplication };

      resp = await createDataModel(currentProject, currentApplication, payload);

      console.log(resp);
      toast.success(
        "A request to generate the datamodel has been sent to the system."
      );

      handleModalClose();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <ApplicationBuildMenu stepCode="data-model" />

      <Modal show={modalShow} onHide={handleModalClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Improve field</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formImproveField">
              <Form.Label>Data Model Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Data Model Name"
                value={dataModelName}
                onChange={(e) => setDataModelName(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="saveBtn"
            variant="primary"
            onClick={generateRequirements}
          >
            Generate!
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="container">
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
          <h1 className="h3 mb-8 text-gray-800"> Data Model</h1>

          <div>
            <Button variant="primary" onClick={init} className="addReq-btn">
              <i className="fas fa-sync fa-sm text-white-50"></i> Refresh
            </Button>
            &nbsp;&nbsp;
            <Button
              className="saveBtn"
              variant="primary"
              onClick={handleModalShow}
            >
              <i className="fas fa-plus fa-sm text-white-50"></i> Generate
              Datamodel
            </Button>
          </div>
        </div>

        <h3>Tables</h3>
        <div className="container">
          <div className="row">
            {functionalRequirements.Entities &&
              functionalRequirements.Entities.map((requirement) => {
                const req = requirement[Object.keys(requirement)[0]];
                const reqName = Object.keys(requirement)[0];
                console.log(req);
                return (
                  <>
                    <div className="col-xl-4 col-md-4 mb-4">
                      <Table striped bordered hover>
                        <tbody>
                          <tr>
                            <th
                              className="tableHeader"
                              style={{ textAlign: "center", fontSize: "22px" }}
                              colspan="3"
                            >
                              {reqName}
                            </th>
                          </tr>
                          <tr>
                            <th className="tableHeader">Field</th>
                            <th className="tableHeader">Type</th>
                            <th className="tableHeader">Description</th>
                          </tr>

                          {req.Attributes &&
                            req.Attributes.map((attribute) => {
                              console.log("attribute", attribute);
                              return (
                                <tr>
                                  <td>{Object.keys(attribute)[0]}</td>
                                  <td>
                                    {Object.values(attribute)[0].AttributeType}
                                  </td>
                                  <td>
                                    {
                                      Object.values(attribute)[0]
                                        .AttributeDescription
                                    }
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </div>
                  </>
                );
              })}
          </div>
        </div>

        <h3>Relationships</h3>
        <div className="container">
          <div className="row">
            {functionalRequirements.Relationships &&
              functionalRequirements.Relationships.map((requirement) => {
                const req = requirement[Object.keys(requirement)[0]];
                const reqName = Object.keys(requirement)[0];

                return (
                  <>
                    <div className="col-xl-3 col-md-3 mb-3">
                      <Table striped bordered hover>
                        <tbody>
                          <tr>
                            <th className="tableHeader" colspan="2">
                              {reqName}
                            </th>
                          </tr>

                          {req.Entities &&
                            req.Entities.map((attribute) => {
                              return (
                                <tr>
                                  <td>Entity</td>
                                  <td>{attribute}</td>
                                </tr>
                              );
                            })}

                          <tr>
                            <td>Explanation</td>
                            <td>{req.Explanation}</td>
                          </tr>
                          <tr>
                            <td>Cardinality</td>
                            <td>{req.Cardinality}</td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ApplicationDataModel;
