import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Form, Button } from "react-bootstrap";
import "./ChatWithRobot.css";
import { MdOutlineArrowUpward } from "react-icons/md";
import toast from "react-hot-toast";
import { useMyGlobalContext } from "../../global/globalContext";
import { useNavigate } from "react-router-dom";
import Markdown from "react-markdown";
import { MdPerson } from "react-icons/md";
import { BsRobot } from "react-icons/bs";
import { FaChartBar, FaSync, FaFile, FaRegCopy } from "react-icons/fa";
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { darcula } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { RxSpeakerLoud } from "react-icons/rx";
// import { useSpeechRecognition } from 'react-speech-kit';
import { getAudio, getChart, documentGen, getTextFromAudio } from '../../services/CloudNativeArchitectureNavigator/chatbot'
import { PiPauseCircleFill } from "react-icons/pi";
import ChartModal from "../Modals/ChartModal/ChartModal";
import  SpeechToText  from "../SpeechToText/SpeechToText"
const ChatWithRobot = ({
    fetchChatHistory,
    sendChatbotQuestion,
    redirectable = true
}) => {
    const [chatHistory, setChatHistory] = useState([]);
    const [newMessage, setNewMessage] = useState("");
    const [lastMessage, setLastMessage] = useState("");
    const [showChartModal, setShowChartModal] = useState(false)
    const [showRegenerateButton, setShowRegenerateButton] = useState(false)
    const [chartContent, setChartContent] = useState("")
    const [msgContent, setMsgContent] = useState("")
    const { globalState } = useMyGlobalContext();
    const [user, setUser] = useState("");
    const navigate = useNavigate();




    const handleCopyCode = (content) => {
        navigator.clipboard.writeText(content)
            .then(() => {
                toast.success('copied to clipboard')
            })
            .catch((error) => {
                console.error('Failed to copy code to clipboard:', error);
                toast.error('Failed to copy code to clipboard. Please try again.');
            });
    };

    const handleChartModal = async (content) => {
        setMsgContent(content);
        const loadingToast = toast.loading("Generating Chart..."); // Display loading message
        try {

            const response = await getChart(globalState.projectId, globalState.chatId, content, 0);
            setChartContent(response);
            setShowChartModal(true);
        } catch (error) {
            console.error('Error while creating Chart:', error);
            toast.error("Chart couldn't be generated"); // Display error message
        } finally {
            toast.dismiss(loadingToast);
        }


    }


    // speak message aloud
    const [text, setText] = useState('');
    const [audioUrl, setAudioUrl] = useState('');
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);
    const [key, setKey] = useState(0);

    const handleAloud = async (content) => {
        try {
            const response = await getAudio(globalState.projectId, content);
            const url = URL.createObjectURL(response);
            setAudioUrl(url);
            setIsPlaying(true);
        } catch (error) {
            console.error('Error speaking message:', error);
            toast.error("Couldn't speak the message aloud");
        }
    };

    const stopPlaying = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            setIsPlaying(false);
        }
    };

    useEffect(() => {
        setKey(key + 1);
    }, [audioUrl]);

    const parseMessageContent = (content) => {
        const parts = [];
        let currentPart = '';
        let inCodeBlock = false;
        let currentLanguage = '';

        return [{ type: '', content: content }]

        const lines = content.split('\n');
        for (let i = 0; i < lines.length; i++) {
            const line = lines[i].trim();
            if (line.startsWith('```')) {
                if (!inCodeBlock) {
                    inCodeBlock = true;
                    const languageMatch = line.match(/```(.+)/);
                    currentLanguage = languageMatch ? languageMatch[1].trim() : '';
                } else {
                    inCodeBlock = false;
                    parts.push({ type: 'code', content: currentPart.trim(), language: currentLanguage });
                    currentPart = '';
                    currentLanguage = '';
                }
            } else {
                if (inCodeBlock) {
                    currentPart += line + '\n';
                } else {
                    if (currentPart.trim().length > 0) {
                        parts.push({ type: 'text', content: currentPart });
                    }
                    currentPart = line + ' ';
                }
            }
        }
        if (currentPart.trim().length > 0) {
            parts.push({ type: inCodeBlock ? 'code' : 'text', content: currentPart.trim(), language: currentLanguage });
        }

        return parts;
    };




    useEffect(() => {
        const fetchChatHistoryData = async () => {
            try {
                const response = await fetchChatHistory(globalState.projectId, globalState.chatId);
                setChatHistory(response.data.chat.chat_history);
                setUser(response.data.chat.user);
            } catch (error) {
                console.error("Error fetching chat history:", error);
                toast.error("An error occurred while fetching the chat history.");
            }
        };
        if (!globalState.chatId && redirectable) {
            navigate("/deloitte-knowledge-navigator");
            return;
        }
        fetchChatHistoryData();
    }, [fetchChatHistory, globalState.chatId, globalState.projectId, navigate]);

    const handleSendMessage = async () => {
        if (newMessage.trim() !== "") {
            const timestamp = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            const updatedChatHistory = [...chatHistory, { role: "human", content: newMessage, timestamp: timestamp }];
            setChatHistory(updatedChatHistory);
            setLastMessage(newMessage)
            setNewMessage("");
            // While response is loading
            setChatHistory((oldChatHistory) => [
                ...oldChatHistory,
                { role: "ai", content: "..." },
            ]);
            const request = {
                chat_id: globalState.chatId,
                question: newMessage,
            };
            try {

                const response = await sendChatbotQuestion(globalState.projectId, request);
                const aiResponse = response.data.message;
                setChatHistory((oldChatHistory) => [
                    ...oldChatHistory.slice(0, oldChatHistory.length - 1),
                    { role: "ai", content: aiResponse },
                ]);
            } catch (error) {
                console.log(error);
                toast.error("An error occurred while sending question");
                setShowRegenerateButton(true)
            }
        }
    };

    const handleRegnerate = async () => {
        setShowRegenerateButton(false)
        if (lastMessage.trim() !== "") {
            setChatHistory((oldChatHistory) => [
                ...oldChatHistory,
                { role: "ai", content: "..." },
            ]);
            const request = {
                chat_id: globalState.chatId,
                question: lastMessage,
            };
            try {

                const response = await sendChatbotQuestion(globalState.projectId, request);
                const aiResponse = response.data.message;
                setChatHistory((oldChatHistory) => [
                    ...oldChatHistory.slice(0, oldChatHistory.length - 1),
                    { role: "ai", content: aiResponse },
                ]);
            } catch (error) {
                console.log(error);
                toast.error("An error occurred while sending question");
                setShowRegenerateButton(true)
            }
        }
    }

    const handleDocGen = async () => {
        const loadingToast = toast.loading("Generating Document..."); // Display loading message
        try {
            const response = await documentGen(globalState.projectId, globalState.chatId);
            // Check if the response contains the file URL
            if (response && response.fileUrl) {
                // Create a temporary link element
                const link = document.createElement('a');
                link.href = response.fileUrl;
                link.download = 'ChatTranscript.docx'; // Set the default file name for download
                document.body.appendChild(link);
                link.click(); // Simulate a click on the link to trigger download
                document.body.removeChild(link); // Remove the link from the document
            } else {
                toast.error("File URL not found in response.");
            }
        } catch (error) {
            console.log(error);
            toast.error("An error occurred. Please try again later.");
        } finally {
            toast.dismiss(loadingToast);
        }
    };const handleVoiceToText = async (audio) => {
        const loadingToast = toast.loading("Generating Text from Voice..."); // Display loading message
        try {
            const response = await getTextFromAudio(globalState.projectId, globalState.chatId, audio);
            console.log(response)
            const timestamp = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
            const updatedChatHistory = [
                ...chatHistory,
                { role: "human", content: response.question, timestamp: timestamp },
                { role: "ai", content: response.message }
            ];
            setChatHistory(updatedChatHistory);
        } catch (error) {
            console.log(error);
            toast.error("An error occurred. Please try again later.");
        } finally {
            toast.dismiss(loadingToast);
        }
    };
    

    const renderers = {
        //This custom renderer changes how images are rendered
        //we use it to constrain the max width of an image to its container
        image: ({
            alt,
            src,
            title,
        }) => (
            <img
                alt={alt}
                src={src}
                title={title}
                style={{ maxWidth: 475 }} />
        ),
    };
    const [isListening, setIsListening] = useState(false);
    const [transcript, setTranscript] = useState('');

    // const handleSpeechResult = (result) => {
    //     setTranscript(result);
    // };
    // const { listen, stop } = useSpeechRecognition({
    //     onResult: handleSpeechResult,
    // // });
    // const startListening = () => {
    //     setIsListening(true);
    //     listen();
    // };

    // const stopListening = () => {
    //     setIsListening(false);
    //     stop();
    //     setNewMessage(transcript);
    // };

    return (
        <div className="chat-container2">

            <div className="chat-messages1 pb-3">
                <Col xs={12} className="chat-messages-container">
                    {chatHistory.map((message, index) => (
                        <div key={index} className="message-wrapper">
                            {message.role === "ai" ? (
                                <div className="RbtIcnWrapper"><BsRobot size={20} style={{ marginTop: '5px', color: '#2C5234' }} /></div>
                            ) : <div className="UsrIcnWrapper"><MdPerson size={20} style={{ marginTop: '5px' }} /></div>}

                            <div className={`message1 ${message.role === "human" ? "sent1" : "received1"}`}>
                                <span>
                                    {message.role === "ai" ? <span className="message-label">DKN</span> : <span className="message-label">You</span>}
                                    {parseMessageContent(message.content).map((part, partIndex) => (
                                        part.type === 'code' ? (
                                            <div className="code-wrapper">
                                                <div className="languageHeader">{part.language}<div className="codeCopy" onClick={() => handleCopyCode(part.content)} >

                                                    <FaRegCopy size={17} />  Copy code </div></div>
                                                <div key={partIndex} className="code-block">
                                                    <pre>
                                                        <code>{part.content}</code>
                                                    </pre>
                                                </div>
                                            </div>
                                        ) : part.content === "..." ? <div className="threeDotsLoader m-2 " /> : (
                                            <Markdown remarkPlugins={[[remarkGfm, { singleTilde: false }]]}
                                                rehypePlugins={[rehypeRaw]}
                                                components={{
                                                    img(props) {
                                                        const { children, className, node, ...rest } = props
                                                        //console.log('img =======>', props)
                                                        return (
                                                            <div className="image-wrapper">
                                                                <img src={props.source} alt={props.alt} width="800px" />
                                                            </div>
                                                        );
                                                    },
                                                    code(props) {
                                                        const { children, className, node, ...rest } = props
                                                        const match = /language-(\w+)/.exec(className || '')
                                                        return match ? (
                                                            <div className="code-wrapper">

                                                                <div className="languageHeader">code<div className="codeCopy" onClick={() => handleCopyCode(part.content)} ><FaRegCopy size={17} />  Copy code </div></div>
                                                                <SyntaxHighlighter
                                                                    {...rest}
                                                                    PreTag="div"
                                                                    children={String(children).replace(/\n$/, '')}
                                                                    language={match[1]}
                                                                    style={darcula}
                                                                /></div>
                                                        ) : (
                                                            <code {...rest} className={className}>
                                                                {children}
                                                            </code>
                                                        )
                                                    }
                                                }}
                                                key={partIndex}>{part.content}</Markdown>
                                        )
                                    ))}
                                    {message.role === "ai" && message.content !== "..." && <div>{isPlaying ? (
                                        <PiPauseCircleFill className="bottomCopy" style={{ marginTop: '-30px' }} onClick={stopPlaying} />
                                    ) : (
                                        <RxSpeakerLoud className="bottomCopy" style={{ marginTop: '-30px' }} onClick={() => handleAloud(message.content)} />
                                    )} <FaRegCopy onClick={() => handleCopyCode(message.content)} className="bottomCopy" style={{ marginTop: '-30px', marginLeft: '5px' }} /> <FaChartBar onClick={() => handleChartModal(message.content)} className="bottomCopy" style={{ marginTop: '-30px', marginLeft: '5px' }} /> </div>}
                                    {/* <div className="timestamp">{message.timestamp}</div> */}

                                </span>
                            </div>
                        </div>
                    ))}
                    {showRegenerateButton && <div className="w-full d-flex justify-content-center "><button onClick={handleRegnerate} className="btn btn-secondary  d-flex m-2 px-2 p-1"><FaSync size={12} className="align-self-center me-1" />Regenerate</button></div>}
                </Col>

            </div>

            <div className="message-inp ">
                <Col style={{ display: 'flex', justifyContent: 'center' }}>
                    <div className="promptContainer">
                        <input
                            as="textarea"
                            rows={1}
                            placeholder="Type your message..."
                            value={newMessage}
                            className="promptForm flex-1 w-full border"
                            onChange={(e) => setNewMessage(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                    handleSendMessage();
                                }
                            }}


                        />
                        <Button
                            onClick={handleSendMessage}
                            className={newMessage.length > 0 ? 'sendBtn' : 'disabledSendBtn'}
                            disabled={newMessage.length === 0}
                        >
                            <MdOutlineArrowUpward style={{ fontSize: '22px', marginBottom: '10px', paddingRight: '2px' }} />
                        </Button>
                        <SpeechToText handleVoiceToText={handleVoiceToText}/>
                        {chatHistory.length > 0 && <Button className="sendBtn document-gen d-flex align-items-center" onClick={() => handleDocGen()}>
                            <FaFile />
                        </Button>}

                    </div>

                </Col>
            </div>
            {audioUrl && (
                <audio controls autoPlay style={{ display: 'none' }} key={key} ref={audioRef} onPlay={() => setIsPlaying(true)}
                    onPause={() => setIsPlaying(false)}
                    onEnded={() => setIsPlaying(false)} >
                    <source src={audioUrl} type="audio/wav" />
                    Your browser does not support the audio element.
                </audio>
            )}
            <ChartModal title="Chart" chart={chartContent} message={msgContent} show={showChartModal} setShow={setShowChartModal} />
        </div >
    );
};

export default ChatWithRobot;
