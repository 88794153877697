import { API } from './api'


/**
 * Uploads a file with the given payload and returns the chatId
 * 
 * @param {*} projectId
 * @param {*} payload 
 * @returns {Promise<AxiosResponse<any>>}
 */
const uploadFile = async (projectId, payload) => API().post(`/project/${projectId}/administrator/document/create`, payload, {headers:{'Content-Type': 'multipart/form-data',}});

/**
 * Returns the uploaded files
 * 
 * @param {*} projectId
 * @returns
 */
const getFiles = async (projectId) => API().get(`/project/${projectId}/administrator/document/list`);

/**
 * Returns a file with a given doc_id
 * 
 * @param {*} projectId
 * @param {*} doc_id
 * @param {*} chatId 
 * @returns 
 */
const getFile = async (projectId,doc_id) => API().get(`project/${projectId}/administrator/document/${doc_id}`);


export {
    uploadFile,
    getFiles,
    getFile
};