import React, { FC, useEffect, useState, useRef } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
import { Table } from 'react-bootstrap';
import { getApplication, updateApplication } from '../../../services/applicationService';
import { regenerateFeatures } from '../../../services/featuresService';
import "./ApplicationRequirements.css";
import useCurrentProject from '../../../components/App/useCurrentProject';
import useCurrentApplication from '../../../components/App/useCurrentApplication';
import { getRequirements } from '../../../services/requirementsService';
import ApplicationBuildMenu from '../../../components/ApplicationBuildMenu/ApplicationBuildMenu';
import { getFeatures, deleteFeature } from '../../../services/featuresService';
import * as XLSX from 'xlsx';
import toast, { Toaster } from "react-hot-toast";
import Modal from 'react-bootstrap/Modal';
import ModalConfirm from '../../../components/Utils/ModalConfirm';
import ChatCanvas from '../../../components/Chat/ChatCanvas';
import BaseModal from '../../../components/Utils/BaseModal';

const ApplicationRequirements = ({ feature }) => {
  const { currentProject } = useCurrentProject();
  const { currentApplication } = useCurrentApplication();

  const [application, setApplication] = useState({});

  const init = async () => {
    try {
      console.log('ApplicationRequirements', feature)


    } catch (error) {
      //if (error.response.status === 404) navigate(`/${getSiteLanguage()}/not-found`)
      //if (error.response.status === 500) navigate(`/${getSiteLanguage()}/error`)
    }
  }

  useEffect(() => {
    init();
  }, []);


  return (
    <>

      {(feature.showDetail) &&
        <tr>
          <td colSpan={7}>
            <Table bordered hover striped>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Description</th>
                  <th>Input/Output</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {feature.requirements.map((requirement, index) => {
                  return (<><tr>
                    <td>{'FR' + (index + 1).toString().padStart(2, '0')}</td>
                    <td>{requirement.name}</td>
                    <td>{requirement.description}</td>
                    <td>{requirement.input_output}</td>
                    <td><div className="d-flex">
                      <Link
                        className="nav-link inline collapsed col-md-5"
                        to={`/projects/${currentProject}/application-build/${currentApplication}/features/${feature.id}/requirements/${requirement.id}`}
                        data-toggle="collapse"
                        data-target="#collapseTwo"
                        aria-expanded="true"
                        aria-controls="collapseTwo"
                      >
                        <i className="fas fa-edit"></i>
                      </Link>

                    </div></td>
                  </tr>
                  </>)
                })}

              </tbody>
            </Table>
          </td>
        </tr>
      }

    </>
  );
};


export default ApplicationRequirements;
