import axios from "axios";
import { API } from './api'
import yaml from 'js-yaml';


/**
 * Returns the list of data models defined for the given application
 * 
 * @param {*} projectId 
 * @param {*} id 
 * @returns 
 */
const getDataModels = async (projectId, id) => API().get(`/project/${projectId}/application/${id}/datamodel/`);

/**
 * Returns the data model with the given id
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @param {*} id 
 * @returns 
 */
const getDataModel = async (projectId, applicationId, id) => API().get(`/project/${projectId}/application/${applicationId}/datamodel/${id}/`);

/**
 * Creates the data model with the given payload and returns the created data model
 * 
 * @param {*} projectId 
 * @param {*} id 
 * @param {*} payload 
 * @returns 
 */
const createDataModel = async (projectId, id, payload) => API().post(`/project/${projectId}/application/${id}/datamodel/`, payload);

/**
 * Updates the data model with the given id and returns the updated data model
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @param {*} id 
 * @param {*} payload 
 * @returns 
 */
const updateDataModel = async (projectId, applicationId, id, payload) => API().put(`/project/${projectId}/application/${applicationId}/datamodel/${id}/`, payload);

/**
 * Downloads the data model for the given application
 * 
 * @param {*} currentProject 
 * @param {*} currentApplication 
 * @param {*} id 
 * @returns 
 */
const downloadDataModel = async (currentProject, currentApplication, id) => {
    //console.log('downloadDataModel', currentProject, currentApplication, id);
    const resp = await getDataModel(currentProject, currentApplication, id);
    //console.log('downloadDataModel', resp.data);
    const lst = eval(resp.data.generated_data_model);
    if(lst.length == 0) {
        return {
            data: {
               
            }
        };
    }

    const path = lst[lst.length - 1];

    const res = await axios.create({baseURL: "https://itgeniuslab.blob.core.windows.net/subscription1deloitte"}).get(lst[lst.length - 1]);
    if(!res) {
        return null;
    }

    //console.log('downloadDataModel', res.data);

    const doc = yaml.load(res.data);

    //console.log('downloadDataModel doc', doc);
    
    return doc;
};

export {
    getDataModels,
    downloadDataModel,
    createDataModel,
    updateDataModel,
};