import { API } from '../api'

const getChats = async (projectId) => API().get(`/project/${projectId}/chatbot_list`);
const createChat = async (projectId,body) => API().post(`/project/${projectId}/chatbot`,body);
/**
 * Sends a text message to the API and retrieves an audio response.
 * 
 * @param {number} projectId 
 * @param {string} message 
 * @returns {Promise<Blob>} - A promise that resolves to a Blob containing the audio data.
 */
const getAudio = async (projectId, message) => {
    try {
      const response = await API().post(`/project/${projectId}/chatbot_message_to_speech`, { message }, {
        responseType: 'blob'
      });
      return response.data;
    } catch (error) {
      throw new Error('Failed to fetch audio');
    }
  };
const getTextFromAudio = async (projectId, chat_id, question) => {
    try { 
      const response = await API().post(`/project/${projectId}/chatbot_question_voice`, { chat_id, question });
      return response.data
    } catch (error) {
      throw new Error('Failed to fetch text from audio');
    }
  };
const getChart = async (projectId, chat_id, message, force) => {
    try {
      const response = await API().post(`/project/${projectId}/chatbot_chart`, { chat_id, message, force });
      return response.data;
    } catch (error) {
      console.log(error)
      throw new Error('Failed to fetch chart');
    }
  };
const documentGen = async (projectId, chat_id) => {
    try {
      const response = await API().get(`/project/${projectId}/chatbot_transcript/${chat_id}`);
      return response.data;
    } catch (error) {
      console.log(error)
      throw new Error('Failed to transcript document');
    }
  };

export {
    getChats,
    createChat,
    getAudio,
    getChart, documentGen, getTextFromAudio
};