import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ConfirmModal.css'

const ConfirmModal = ({
  title,
  content,
  confirmFunc,
  rejectFunc,
  show,
  setShow,
  confirmButtonLabel,
  rejectButtonLabel,
}) => {
  return (
    <Modal show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{content}</p>
      </Modal.Body>
      <Modal.Footer>
        {rejectFunc && (
          <Button className='rejectButton' variant="secondary" onClick={rejectFunc}>
            {rejectButtonLabel || 'Reject'}
          </Button>
        )}
        <Button className='confirmButton' variant="secondary" onClick={confirmFunc}>
          {confirmButtonLabel || 'Confirm'}
        </Button>
      </Modal.Footer>
    </Modal >
  );
};

export default ConfirmModal;
