import axios from "axios";
import _ from 'lodash'

// Get the backernd URL from the env file
const baseURL = process.env.REACT_APP_SERVER_URL;

/**
 * @requestInterceptor Add the Header Content-Type=application/json
 * @responseInterceptor In case of unauthorized error try to resfresh the token and redo the request, otherwise return the error after modifing the message.
 * @returns The axios instance for the API calls
 */
const API = () => {
  // create axios instance
  const options = {
    baseURL: baseURL,
    withCredentials: false,
    headers: {"Content-Type":"application/json", }
  };
  const instance = axios.create(options);
  instance.interceptors.request.use(
    (config) => {
      // fixme: use constants for default lang and localstorage item
      const token = sessionStorage.getItem('token');
      //console.log('TOKEN', token);

      if (token) {
        const token = JSON.parse(sessionStorage.getItem('token'));
        config.headers['Authorization'] = `Bearer ${token.access}`;
      }

      return config;
    },
    (error) => Promise.reject(error)
  );
    
  instance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      //Set the error message according to the response of the backend. If it doesn't exist set a default error message
      let finalMessage = 'An unexpected error occured'
      if(_.has(err, 'response.data.error'))  finalMessage = err.response.data.error
      return Promise.reject({...err,message:finalMessage});
    }
  );
  return instance;
};

export { API };

