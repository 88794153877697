import axios from "axios";
import { API } from './api'
import yaml from 'js-yaml';


/**
 * Creates the non functional requirement with the given payload and returns the created requirement
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @param {*} payload 
 * @returns 
 */
const createIntrRequirement = async (projectId, applicationId, payload) => API().post(`/project/${projectId}/application/${applicationId}/intrequirement/`, payload);

/**
 * Updates the non functional requirement with the given id and returns the updated requirement
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @param {*} featureId 
 * @param {*} payload 
 * @returns 
 */
const updateIntrRequirement = async (projectId, applicationId, featureId ,payload) => API().put(`/project/${projectId}/application/${applicationId}/intrequirement/${featureId}/`, payload);

/**
 * Deletes the non functional requirement  with the given id and returns the updated requirement
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @param {*} intrequirementId 
 * @returns 
 */
const deleteIntrRequirement = async (projectId, applicationId, intrequirementId ) => API().delete(`/project/${projectId}/application/${applicationId}/intrequirement/${intrequirementId}/`);

/**
 * Returns the list of non functional requirements defined for the given application
 * 
 * @param {*} projectId 
 * @param {*} id 
 * @returns 
 */
const getIntrRequirements = async (projectId, applicationId) => API().get(`/project/${projectId}/application/${applicationId}/intrequirement/`);

/**
 * Returns the non functional requirement with the given id
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @param {*} id 
 * @returns 
 */
const getIntrRequirement = async (projectId, applicationId, id) => API().get(`/project/${projectId}/application/${applicationId}/intrequirement/${id}/`);

/**
 * Deletes all the non functional requirements for the given application
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @returns 
 */
const deleteAllIntrRequirements = async (projectId, applicationId) => API().delete(`/project/${projectId}/application/${applicationId}/intrequirement/delete_all/`);

/**
 * Genereates the non functional requirements for the given application
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @returns 
 */
const generateIntrRequirements = async (projectId, applicationId, payload) => API().post(`/project/${projectId}/application/${applicationId}/intrequirement/generate/`, payload);

/**
 * Genereates the non functional requirements for the given application
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @returns 
 */
const regenerateIntrRequirement = async (projectId, applicationId, id, payload) => API().post(`/project/${projectId}/application/${applicationId}/intrequirement/${id}/regenerate/`, payload);

export {
    createIntrRequirement,
    updateIntrRequirement ,
    deleteIntrRequirement ,
    getIntrRequirements ,
    getIntrRequirement ,
    generateIntrRequirements ,
    deleteAllIntrRequirements ,
    regenerateIntrRequirement ,
};