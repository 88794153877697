import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { createProject } from "../../services/projectService";
import "./ApplicationCreateProject.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

const ProjectsList = () => {
  const [projectDetails, setProjectDetails] = useState({
    name: "",
    description: "",
    crated_by: "evangelos",
    is_active: true,
  });

  const handleChange = (field, e) => {
    const updatedProjectDetails = {
      ...projectDetails,
      [field]: e.target.value,
    };
    setProjectDetails(updatedProjectDetails);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log(projectDetails);
      const response = await createProject(projectDetails);
      console.log(response.data);

      toast.success("Project successfully created!", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setProjectDetails({
        name: "",
        description: "",
        crated_by: "evangelos",
        is_active: true,
      });
    } catch (error) {
      console.error("Error creating project:", error);

      toast.error("Failed to create project. Please try again.", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setProjectDetails({
        name: "",
        description: "",
        crated_by: "evangelos",
        is_active: true,
      });
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit} className="createProjectForm">
        <h3 className="newProject">New Project</h3>
        <hr className="newProjectHr"/>
        <Form.Group className="mb-3 " controlId="formProjectName">
          <Form.Label>Project Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter the project name"
            value={projectDetails.name}
            onChange={(e) => handleChange("name", e)}
            className="project-info-text"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formProjectDescription">
          <Form.Label>Project Description</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter the projects' description"
            value={projectDetails.description}
            onChange={(e) => handleChange("description", e)}
            className="project-info-text"
          />
        </Form.Group>
        <Button type="submit" className="create-btn">
          Create Project
        </Button>
        <Link to={`/`}>
          <Button className="btn-secondary cancel-btn">Cancel</Button>
        </Link>
      </Form>

      <ToastContainer />
    </>
  );
};

export default ProjectsList;
