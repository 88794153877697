import React, { useEffect, useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import useCurrentProject from "../App/useCurrentProject";
import "./ProjectModal.css";
import { Table } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { getProjects, deleteProject } from "../../services/projectService";
import { FaTrash, FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

function ProjectModal({ modalShow, handleModalClose, handleNewProjectClick }) {
  const [searchText, setSearchText] = useState("");
  const { currentProject } = useCurrentProject();
  const [selectedProject, setSelectedProject] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const navigate = useNavigate();

  const handleSearchChange = (e) => {
    setSearchText(e.target.value);
  };

  const handleEditClick = (id) => {
    navigate(`/projects/${id}/application-build/edit-project`);
    handleModalClose();
  };

  const handleDeleteClick = (projectId) => {
    console.log(showDeleteModal);
    setShowDeleteModal(true);
    const selectedProject = projects.find(
      (project) => project.id === projectId
    );
    setSelectedProject(selectedProject);
  };

  const handleDeleteConfirmed = async (project) => {
    try {
      console.log(`Deleting project: ${selectedProject.name}`);

      let resp = await deleteProject(selectedProject.id);

      setShowDeleteModal(false);
      const updatedProjects = projects.filter(
        (project) => project.id !== selectedProject.id
      );
      setProjects(updatedProjects);
      toast.success("Project has been deleted succesfully");
    } catch (error) {
      console.log(error);
      toast.error("Error occured while trying to delete project.");
    }
  };

  const [projects, setProjects] = useState([]);

  const init = async () => {
    try {
      console.log("currentProject", currentProject);
      const projectsList = await getProjects();

      setProjects(projectsList.data);
    } catch (error) {
      console.log(error);
      toast.error("An error occured while trying to load projects.");
    }
  };

  // Function to handle swapping projects
  const swapProject = async (newProjectId) => {
    try {
      window.location.href = `/projects/${newProjectId}/application-build`;
      localStorage.setItem("currentProject", newProjectId);
    } catch (error) {
      console.error("Error swapping project:", error);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      <Modal
        show={modalShow}
        onHide={handleModalClose}
        backdrop={showDeleteModal ? "static" : true}
        centered
        size="lg"
      >
        <Modal.Header>
          <Modal.Title>Select a project</Modal.Title>
          <Link
            to={`/projects/${currentProject}/application-build/create-project`}
            className="new-project-link"
          >
            {" "}
            <Button
              className="create-project-button"
              onClick={handleModalClose}
            >
              Create Project{" "}
            </Button>
          </Link>
        </Modal.Header>

        <Modal.Body>
          <Form.Group controlId="searchBar">
            <Form.Control
              type="text"
              placeholder="Search projects and folders"
              value={searchText}
              onChange={handleSearchChange}
            />
            <br></br>
          </Form.Group>

          <Navbar collapseOnSelect expand="lg" className="main-navbar">
            <Nav className="project-nav-bar justify-content-between">
              <Nav.Link className="link-style">RECENT</Nav.Link>
              <Nav.Link className="link-style">STARRED</Nav.Link>
              <Nav.Link className="link-style">ALL</Nav.Link>
            </Nav>
          </Navbar>
          <br></br>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th className="col-md-1">#</th>
                <th className="col-md-5">Project name</th>
                <th className="col-md-5">Project description</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {projects.map((project) => {
                return (
                  <tr key={project.id} className="tableRows">
                    <td>{project.id}</td>
                    <td>
                      {" "}
                      <span
                        className="tName"
                        onClick={() => swapProject(project.id)}
                      >
                        {project.name}
                      </span>
                    </td>
                    <td>{project.description}</td>
                    <td
                      onClick={() => handleEditClick(project.id)}
                      className="editButton configButtons"
                    >
                      <FaEdit />
                    </td>
                    <td
                      onClick={() => handleDeleteClick(project.id)}
                      className="deleteButton configButtons"
                    >
                      <FaTrash />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            className="cancel-btn"
            onClick={handleModalClose}
          >
            Cancel
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedProject && (
            <div className="deleteConfirm">
              <div className="confirmationContent">
                <p>Are you sure you want to delete the project:</p>
                <strong className="projectName">{selectedProject.name}</strong>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="deleteConfirmFooter">
          <Button
            className="cancel-btn"
            variant="secondary"
            onClick={() => setShowDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteConfirmed}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ProjectModal;
