import { API } from '../api'

const getApplications = (projectId,assessmentId) => API().get(`/project/${projectId}/assessment/${assessmentId}/application/`);
const getApplicationCard = (projectId,assessmentId,applicationId) => API().get(`/project/${projectId}/assessment/${assessmentId}/application/${applicationId}/applicationcard/`);

const getApplicationsFindings = (projectId,assessmentId) => API().get(`/project/${projectId}/assessment/${assessmentId}/application/criticalpoint/`);

export {
    getApplications,
    getApplicationCard,
    getApplicationsFindings
};