import React from 'react';
import './EditableTable.css';
import { Container } from 'react-bootstrap';
import { FaSave } from "react-icons/fa";
import { MdDelete } from "react-icons/md";

const EditableTable = ({
  headers,
  data,
  setData,
  selectable = false,
  disabled = false,
  addRowFlag = false,
  deleteFunction = () => { },
  deleteFlag = false,
  saveFunction = () => { },
  selectFunction = () => { },
  saveFlag = false,
  textAreaColumn = -1
}) => {
  const handleCellChange = (rowIndex, colIndex, newValue) => {
    const newData = [...data];
    newData[rowIndex][colIndex] = newValue;
    setData(newData);
  };

  return (
    <div>
      {addRowFlag && <button className='assessment-button' onClick={() => setData([...data, Array(headers.length).fill("")])}>Add Row</button>}
      <table className="editable-table">
        <thead>
          <tr>
            {headers.map((header, index) => (
              <th key={index} className="table-header">
                {header}
              </th>
            ))}
            {deleteFlag || saveFlag ?
              <th className="table-header">
                Actions
              </th>
              : ""}
          </tr>
        </thead>
        <tbody>
          {data.map((rowData, rowIndex) => (
            <tr key={rowIndex} onClick={() => selectable && selectFunction(rowData)} className={`${selectable?"selectable":""}`}>
              {rowData.map((cellData, colIndex) => (
                <td key={colIndex} className="table-cell">
                  {!disabled ?
                    textAreaColumn == colIndex ?
                    <textarea
                      className="form-control"
                      rows={5}
                      value={cellData}
                      onChange={(e) =>
                        !disabled && handleCellChange(rowIndex, colIndex, e.target.value)
                      }
                    />
                    : <input
                      className="form-control"
                      type="text"
                      value={cellData}
                      onChange={(e) =>
                        !disabled && handleCellChange(rowIndex, colIndex, e.target.value)
                      }
                    />
                  :<div>
                      {cellData}
                    </div>}
                </td>
              ))}
              {deleteFlag || saveFlag ?
                <td className="table-cell">
                  {deleteFlag &&
                    <button style={{ border: "none", padding: "0px" }} onClick={() => deleteFunction(rowIndex)}>
                      <MdDelete size={"1.5rem"} color="#2C5234" />
                    </button>
                  }
                  {
                    saveFlag &&
                    <button style={{ border: "none", padding: "0px" }} onClick={() => saveFunction(rowIndex, rowData)}>
                      <FaSave size={"1.5rem"} color="#2C5234" />
                    </button>
                  }
                </td>
                : ""}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EditableTable;