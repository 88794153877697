import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ChartModal.css'
import { FaArrowRotateLeft } from 'react-icons/fa6';
import { FaDownload } from 'react-icons/fa';
import toast from "react-hot-toast";
import { getChart } from '../../../services/CloudNativeArchitectureNavigator/chatbot'
import { useMyGlobalContext } from "../../../global/globalContext";

const ChartModal = ({ title, chart, message, show, setShow }) => {
  const [chartData, setChartData] = useState(chart);
  useEffect(() => {
    if (chart!=chartData) {
      setChartData(chart);
    }
  }, [chart])
  const { globalState } = useMyGlobalContext();
  const downloadImage = () => {
    // Check if chart is available
    if (chart && chart.image && chart.mimeType) {
      // Create a temporary anchor element
      const downloadLink = document.createElement('a');
      downloadLink.href = `data:${chart.mimeType};base64,${chart.image}`;
      downloadLink.download = 'chart_image.png'; // You can customize the file name here
      document.body.appendChild(downloadLink);

      // Trigger the click event on the anchor element
      downloadLink.click();

      // Remove the temporary anchor element from the document
      document.body.removeChild(downloadLink);
    }
  };
  const handleChartRegen = async (content) => {
    const loadingToast = toast.loading("Generating Chart..."); // Display loading message
    try {
      const response = await getChart(globalState.projectId, globalState.chatId, content, "1"); //1 is used to indicate force=1 
      setChartData(response);
      toast.dismiss(loadingToast);
    } catch (error) {
      console.error('Error while creating Chart:', error);
      toast.error("Chart couldn't be generated"); // Display error message
      toast.dismiss(loadingToast);
    }
  }

  const renderChart = () => {
    if (!chart) {
      return <p>No chart available</p>;
    }

    return <img style={{ width: '100%' }} src={`data:${chartData.mimeType};base64,${chartData.image}`} alt="Chart" />;

  };

  return (
    <Modal className='modal-lg' show={show} onHide={() => setShow(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {renderChart()}
      </Modal.Body>
      <Modal.Footer>
        <Button className='rejectButton' variant="secondary" onClick={()=>handleChartRegen(message)}>
          <FaArrowRotateLeft />
        </Button>
        <Button className='confirmButton' variant="secondary" onClick={downloadImage}>
          <FaDownload />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChartModal;
