import axios from "axios";
import { API } from './api'
import yaml from 'js-yaml';


/**
 * Creates the feature with the given payload and returns the created requirement
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @param {*} payload 
 * @returns 
 */
const createFeature = async (projectId, applicationId, payload) => API().post(`/project/${projectId}/application/${applicationId}/feature/`, payload);

/**
 * Updates the feature with the given id and returns the updated requirement
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @param {*} featureId 
 * @param {*} payload 
 * @returns 
 */
const updateFeature = async (projectId, applicationId, featureId ,payload) => API().put(`/project/${projectId}/application/${applicationId}/feature/${featureId}/`, payload);

/**
 * Deletes the feature with the given id and returns the updated requirement
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @param {*} featureId 
 * @returns 
 */
const deleteFeature = async (projectId, applicationId, featureId ) => API().delete(`/project/${projectId}/application/${applicationId}/feature/${featureId}/`);

/**
 * Deletes all the features for the given project/application
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @returns 
 */
const deleteAllFeatures = async (projectId, applicationId ) => API().delete(`/project/${projectId}/application/${applicationId}/feature/delete_all/`);

/**
 * Returns the list of features defined for the given application
 * 
 * @param {*} projectId 
 * @param {*} id 
 * @returns 
 */
const getFeatures = async (projectId, applicationId) => API().get(`/project/${projectId}/application/${applicationId}/feature/`);

/**
 * Returns the feature with the given id
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @param {*} id 
 * @returns 
 */
const getFeature = async (projectId, applicationId, id) => API().get(`/project/${projectId}/application/${applicationId}/feature/${id}/`);

/**
 * Regenerates the features for the given application
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @returns 
 */
const regenerateFeatures = async (projectId, applicationId) => API().delete(`/project/${projectId}/application/${applicationId}/feature/regenerate/`);

/**
 * Regenerates the features for the given application
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @returns 
 */
const regenerateFeature = async (projectId, applicationId, id, payload) => API().post(`/project/${projectId}/application/${applicationId}/feature/${id}/regenerate/`, payload);

/**
 * Generates new featiures for the given application:
 * - loads the application via getapplication
 * - saves the application passing the "generate_features" flag
 * 
 * @param {*} projectId 
 * @param {*} applicationId 
 * @returns 
 */
const generateNewFeatures = async (projectId, applicationId) => {
    const application = await API().get(`/project/${projectId}/application/${applicationId}/`);
    const applicationData = application.data;
    applicationData.generate_features = true;

    return API().put(`/project/${projectId}/application/${applicationId}/`, applicationData);
}

export {
    createFeature,
    updateFeature,
    deleteFeature,
    getFeatures,
    getFeature,
    regenerateFeatures,
    deleteAllFeatures,
    generateNewFeatures,
    regenerateFeature,
};