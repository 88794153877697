import "./Breadcrumbs.css"

const Breadcrumbs = ({
    body,
    leaf,
    handleBodyClick = () => { }
}) => {
    return (
        <div className="breadcrumb-wrapper">
            {body.map((bodyPart, index) => (
                <>
                    <div className="breadcrumb-body" key={index} onClick={()=>handleBodyClick(bodyPart,index)}>
                        {bodyPart}
                    </div>
                    <span>{">"}</span>
                </>
            ))}
            <div className="breadcrumb-leaf"> 
                {leaf}
            </div>
        </div>
    )
}

export default Breadcrumbs