import OpenTextBox from "../../OpenTextBox/OpenTextBox"
import "./CMASummary.css"

const CMASummary = ({
    summary,
    setSummary,
    keyFindings,
    setKeyFindings,
    update
}) => {
    return (
        <div className="information-gathering-wrapper">
            <OpenTextBox
                title="Summary of as-is assessment"
                value={summary}
                setValue={setSummary}
                updateButton={update}
            />
            {/* <OpenTextBox
                title="Key Findings"
                value={keyFindings}
                setValue={setKeyFindings}
            />
            <div className="download-resume-wrapper">
                <button className="download-resume-button">
                    Download Final Assessment Resume
                </button>
            </div> */}
        </div>
    )
}

export default CMASummary