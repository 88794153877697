import React, { useEffect, useMemo, useState } from 'react';
import { getChats, createChat } from '../services/CloudNativeArchitectureNavigator/chatbot';
import AssessmentList from '../components/Forms/AssessmentList';
import { useMyGlobalContext } from "../global/globalContext"
import EditableTable from '../components/Tables/EditableTable';
import { useNavigate } from 'react-router-dom';
import SelectForm from "../components/SelectForm/SelectForm";
import useCurrentProject from '../components/App/useCurrentProject';
import PrincipalComponent from '../components/PrincipalComponent/PrincipalComponent';
import './DeloitteKnowledgeNavigator.css'
import PrincipalData from '../components/PrincipalComponent/principalData.json'
import UploadForm from '../components/UploadForm/UploadForm';
import { FaHistory, FaPen, FaTimes } from 'react-icons/fa';
import { TbHistoryOff, TbHistory, TbPencil, TbX } from "react-icons/tb";
function DeloitteKnowledgeNavigator() {
  const { currentProject } = useCurrentProject();
  const [oldChats, setOldChats] = useState([])
  const [showCreateChatForm, setShowCreateChatForm] = useState(false)
  const { globalState, updateGlobalState } = useMyGlobalContext()
  const [knowledgeData, setKnowledgeData] = useState(PrincipalData)
  const [selectedPrincipal, setSelectedPrincipal] = useState(false)
  const [showTable, setShowTable] = useState(false);

  const [architectureTypeOptions] = useState([
    { id: "Enterprise", label: "Enterprise" },
    { id: "Software", label: "Software" },
    { id: "Infrastructure&Cloud", label: "Infrastructure & Cloud" },
    { id: "Security", label: "Security" },
    { id: "Data", label: "Data" },
    { id: "Application", label: "Application" },
  ])
  const [selectedArchitectureType, setSelectedArchitectureType] = useState({ value: "Software", label: "Software" })
  const [principalTopicOptions] = useState([
    { id: "Network and Security", label: "Network and Security" },
    { id: "Software Development", label: "Software Development" },
    { id: "Cloud Computing", label: "Cloud Computing" },
    { id: "Database Management", label: "Database Management" },
    { id: "System Administration", label: "System Administration" },
    { id: "Cybersecurity", label: "Cybersecurity" },
    { id: "Data Science and Analytics", label: "Data Science and Analytics" },
    { id: "Web and Mobile Development", label: "Web and Mobile Development" },
    { id: "IT Project Management", label: "IT Project Management" },
    { id: "Emerging Technologies", label: "Emerging Technologies" },
  ])


  const [selectedPrincipalTopic, setSelectedPrincipalTopic] = useState(null)

  const [listData, setListData] = useState([
    {
      category: "Chat context",
      elements: [
        {
          title: "General Context",
          value: ""
        },
        // {
        //   title: "Architecture Type",
        //   value: ""
        // },
        // {
        //   title: "Principal Topic",
        //   value: ""
        // },
      ]
    }
  ])
  const [chatHeaders] = useState([
    "#",
    "Date Created",
    "Context"
  ])
  const navigate = useNavigate()

  const init = async () => {
    try {
      const res = await getChats(currentProject)
      setOldChats(res.data.chat_list.map(chat => ([chat.chat_id, chat.created_at, chat.context])))
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    init()
  }, [])

  const createChatClick = async () => {
    try {
      if (!selectedArchitectureType || !selectedPrincipalTopic) return
      const body = {
        context: listData[0].elements[0].value,
        architecture_type: selectedArchitectureType.label,
        principal_topic: selectedPrincipalTopic
      }
      const response = await createChat(currentProject, body)
      const chatId = response.data.chat_id
      updateGlobalState({ ...globalState, chatId })
      navigate("/projects/cloud-native-architecture-navigator/chat")
    } catch (error) {
      console.log(error)
    }
  }

  const handlePrincipalClick = (title) => {
    setSelectedPrincipal(title);
    setSelectedPrincipalTopic(title)
  };


  return (
    <div>
      <div className='p-3'>
      {showTable && (
          <EditableTable
            headers={['#', 'Date Created', 'Context']}
            data={oldChats}
            setData={setOldChats}
            disabled={true}
            selectable={true}
            selectFunction={(row) => {
              updateGlobalState({ ...globalState, chatId: row[0] });
              navigate('/projects/cloud-native-architecture-navigator/chat');
            }}
          />
        )}
        {/* {
          oldChats.map((oldChat, index) => (
            <div>
              chat #{index + 1}
            </div>
          ))
        } */}
      </div>
      {!showCreateChatForm && <div className="w-full d-flex flex-column flex-sm-row justify-content-center ">{!showTable && <button className='assessment-button m-2 d-flex align-items-center'  onClick={() => setShowCreateChatForm(true)}>
        <TbPencil  className="me-2"  /> Create Chat
      </button>
    }
              <button className='assessment-button m-2 d-flex align-items-center ' onClick={() => setShowTable(!showTable)}>
             {showTable ?<> <TbHistoryOff  className="me-2" />  Hide Chat History</> : <><TbHistory className="me-2"  />Show Chat History</>}
            </button>
            </div>}
      {
        showCreateChatForm &&
        <div>
              <div>
      {Object.keys(knowledgeData).map((sectionTitle, index) => (
        <div key={index}>
          <span className='principalTitle'>{sectionTitle}</span>
          <div className='makeFlex'>
            {Object.keys(knowledgeData[sectionTitle]).map((topic, idx) => (
              <PrincipalComponent key={idx} title={topic} selected={selectedPrincipal === topic } // Set whether this component is selected based on state
              handleClick={() => handlePrincipalClick(topic)} />
            ))}
          </div>
        </div>
      ))}
    </div>
          <AssessmentList data={listData} setData={setListData} placeholder="Insert your answer here" />
          <div style={{ display: "flex", justifyContent: "space-evenly",marginBottom:'5px' }}>
            <button className='assessment-button m-2 d-flex align-items-center' onClick={() => setShowCreateChatForm(false)}>
             <TbX  className="me-2" /> Cancel
            </button>
            <button className='assessment-button m-2 d-flex align-items-center' onClick={() => createChatClick()}>
            <TbPencil  className="me-2"  /> Create Chat
            </button>
          </div>
        </div>
      }

    </div>
  );
}

export default DeloitteKnowledgeNavigator;
