import React,{useState} from 'react';
import { IoCloseSharp } from "react-icons/io5";
import { TbEdit } from "react-icons/tb";
import OffCanvasForm from "../TextOffCanvas/textOffCanvas"
import './AssessmentList.css'
import UploadForm from '../UploadForm/UploadForm';

const AssessmentListElement = ({
    title,
    value,
    setValue,
    placeholder,
    deletable,
    deleteFunction = () => { },
    generable,
    isUpload
}) => {
    const handleChange = (event) => {
        setValue(event.target.value);
    };
    const [showCanvas, setShowCanvas] = useState(false)

    const handleCanvasConfirm = (value) =>{
        console.log(`Generate the AI from ${value} and change the value`)
    }

    return (
        <div className='textfield-wrapper'>
            <div style={{ width: "100%" }}>
                <label className='title'>{isUpload ? 'Upload CSV' : title}</label>
                {isUpload && (<UploadForm isContext={true} />)}
                {!isUpload && <textarea className='textfield'
                    type="text"
                    value={value}
                    onChange={handleChange}
                    rows={3}
                    placeholder={placeholder || 'Insert your answer here or upload a document'}
                />}
            </div>
            {deletable || generable ?
                <div className='buttons-wrapper'>
                    {deletable &&
                        <button className='invisible-button' onClick={() => deleteFunction()}>
                            <IoCloseSharp size={"1.5rem"} />
                        </button>
                    }
                    {generable &&
                        <button className='invisible-button' onClick={() => setShowCanvas(true)}>
                            <TbEdit size={"2rem"} />
                        </button>
                    }
                </div> : ""
            }
            <OffCanvasForm
                show={showCanvas}
                setShow={setShowCanvas}
                header="Enter a comment to improve your summary via GenAI"
                initialValue="Write a comment here"
                showCancelButton={false}
                confirmFunction={(value)=>handleCanvasConfirm(value)}
            />
        </div>
    );
};

export default AssessmentListElement;
