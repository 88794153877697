import { useState } from 'react';
import { Link, useParams } from "react-router-dom";

export default function useCurrentApplication() {
  const {applicationId} = useParams();

  const getCurrentApplication = () => {

    return applicationId
  };

  const [currentApplication, setCurrentApplication] = useState(getCurrentApplication());

  return {
    setCurrentApplication,
    currentApplication
  }
}
