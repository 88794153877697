import { useState } from "react"
import OpenTextBox from "../../OpenTextBox/OpenTextBox"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import DisplayInfo from "../../Tables/DisplayInfo";
import './InfrastructureAssessment.css'
import PointsInformation from "../../PointsInformation/PointsInformation";

const InfrastructureAssessment = ({
    summary,
    setSummary,
    setPoints,
    keyFindings,
    visualClusters,
    faultToleranceAnalysis,
    faultToleranceMessage,
    setFaultToleranceMessage,
    hardwareObsolence,
    hardwareObsolenceMessage,
    setHardwareObsolenceMessage,
    softwares,
    softwareMessage,
    setSoftwareMessage,
    projectId,
    assessmentId,
    deleteContextFinding,
    update,
    updateKeyFinding,
    updateFaultMessage,
    updateHardwareMessage,
    updateSoftwareMessage
}) => {

    return (
        <div className="information-gathering-wrapper">
            <OpenTextBox
                title="Summary of Infrastructure Assessment"
                value={summary}
                setValue={setSummary}
                updateButton={update}
            />
            <PointsInformation
                title="Key Findings"
                points={keyFindings}
                setPoints={setPoints}
                deleteContextFinding={deleteContextFinding}
                updateKeyFinding={updateKeyFinding}

            />
            <div className="details-wrapper" style={{ width: "95%" }}>
                <h1>Infrastructure Details</h1>
                <Tabs
                    defaultActiveKey="server-analysis"
                    id="infrastuxture-tabs"
                    fill
                >
                    <Tab eventKey="server-analysis" title="Server Analysis">
                        <div className="tab-wrapper">
                            <DisplayInfo
                                title="Cluster Saturation Analysis"
                                content="The calculation of the vCPU/CPU andvRAM/RAMratios for each cluster allows you to have information on the saturation of theclusters and therefore on the performance of the virtual infrastructure"
                                tableHeader={["Cluster", "vCPU/CPU", "vRAM/RAM", "N of VM"]}
                                tableData={visualClusters}
                                graphUrl={`${process.env.REACT_APP_SERVER_URL}/project/${projectId}/assessment/${assessmentId}/diagram/virtualizationclustersaturation/`}
                            />

                            <hr />
                            <DisplayInfo
                                title="Fault Tolerance Analysis"
                                content="The fault tolerance analysishighlights, for each cluster under analysis, the maximum number of physicalservers that can run into a simultaneous failure without interrupting thenormal flow of flows"
                                tableHeader={["Cluster", "# of host within cluster", "Max concurrent fault"]}
                                tableData={faultToleranceAnalysis}
                                message={faultToleranceMessage}
                                setMessage={setFaultToleranceMessage}
                                update={updateFaultMessage}

                            />
                            <hr />
                            <DisplayInfo
                                title="Hardware Obsolescence Analysis"
                                content="The analysis of the obsolescence ofphysical hardware is based on the comparison of the score assigned by the SPECto the CPU model/Host Model pair with the current market benchmark score"
                                tableHeader={["Cluster", "Host Model", "CPU Model", "SPEC Speed Score", "SPEC Rate Score"]}
                                tableData={hardwareObsolence}
                                message={hardwareObsolenceMessage}
                                setMessage={setHardwareObsolenceMessage}
                                update={updateHardwareMessage}
                                pillTitle="Benchmark SPEC"
                                pillContent={200}
                            />
                        </div>
                    </Tab>
                    <Tab eventKey="software-analysis" title="Software Analysis">
                        <div className="tab-wrapper">
                            <DisplayInfo
                                title="Software Excel template"
                                content="The user is able to examine the infrastructure Excel template, previously uploaded into the system, and make changes if necessary"
                                tableHeader={["SOFTWARE", "VERSION", "DESCRIPTION", "TYPE", "# OF INSTALLATION", "End Of Life", "Migration Criticality"]}
                                tableData={softwares}
                            />
                            <hr />
                            <DisplayInfo
                                title="Software Distribution"
                                content="The software analysis shows, foreach type of software, the distribution of the use of certain software and its versions within the application landscape in question"
                                message={softwareMessage}
                                setMessage={setSoftwareMessage}
                                update={updateSoftwareMessage}
                                component={<div>
                                    <div style={{ justifyContent: "center", display: "flex", height: "400px", width: "100%" }}>
                                        <img
                                            src={`${process.env.REACT_APP_SERVER_URL}/project/${projectId}/assessment/${assessmentId}/diagram/softwareeol/`}
                                            alt="server os graph"
                                            style={{ width: "500px" }}
                                        />
                                        <img
                                            src={`${process.env.REACT_APP_SERVER_URL}/project/${projectId}/assessment/${assessmentId}/diagram/softwareeolinstallations/`}
                                            alt="server os pie"
                                            style={{ width: "500px" }}
                                        />
                                    </div>
                                    <div style={{ justifyContent: "center", display: "flex", height: "400px", width: "100%" }}>
                                        <img
                                            src={`${process.env.REACT_APP_SERVER_URL}/project/${projectId}/assessment/${assessmentId}/diagram/softwaretype/`}
                                            alt="server os graph"
                                            style={{ width: "500px" }}
                                        />
                                    </div>
                                </div>}
                            />
                        </div>
                    </Tab>
                </Tabs>
            </div>
        </div>
    )
}

export default InfrastructureAssessment