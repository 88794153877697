import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { getProjects, updateProject } from "../../services/projectService";
import "./ApplicationEditProject.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import useCurrentProject from "../../components/App/useCurrentProject";

const ProjectsList = () => {
  const [projectDetails, setProjectDetails] = useState({
    name: "",
    description: "",
    crated_by: "Creator",
    is_active: true,
  });

  const { currentProject } = useCurrentProject();
  const [projects, setProjects] = useState([]);

  const init = async () => {
    try {
      const projectsList = await getProjects();
      setProjects(projectsList.data);
    } catch (error) {
      console.log(error);
      toast.error("error retrieving project");
    }
  };

  const handleChange = (field, e) => {
    const updatedProjectDetails = {
      ...projectDetails,
      [field]: e.target.value,
    };
    setProjectDetails(updatedProjectDetails);
  };

  const handleSubmit = async (id, e) => {
    e.preventDefault();
    try {
      console.log(projectDetails);
      const response = await updateProject(id, projectDetails);
      console.log(response.data);

      toast.success("Project successfully updated!", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setProjectDetails({
        name: "",
        description: "",
        crated_by: "creator",
        is_active: true,
      });
    } catch (error) {
      console.error("Error updating project:", error);

      toast.error("Failed to update project. Please try again.", {
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setProjectDetails({
        name: "",
        description: "",
        crated_by: "creator",
        is_active: true,
      });
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <>
      {projects.map((project) => {
        if (project.id == currentProject) {
          return (
            <>
              <Form
                onSubmit={(e) => handleSubmit(project.id, e)}
                className="createProjectForm"
              >
                <h3 className="newProject">Edit Project</h3>
                <hr />
                <Form.Group className="mb-3" controlId="formProjectName">
                  <Form.Label>Edit Project Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={project.name}
                    value={projectDetails.name}
                    onChange={(e) => handleChange("name", e)}
                    className="project-info-text"
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formProjectDescription">
                  <Form.Label>Edit Project Description</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder={project.description}
                    value={projectDetails.description}
                    onChange={(e) => handleChange("description", e)}
                    className="project-info-text"
                  />
                </Form.Group>
                <Button type="submit" className="create-btn">
                  Edit Project
                </Button>

                <Link to={`/`}>
                  <Button className="btn-secondary cancel-btn">Cancel</Button>
                </Link>
              </Form>

              <ToastContainer />
            </>
          );
        }
      })}
    </>
  );
};

export default ProjectsList;
